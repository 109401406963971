import {
  Box,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAdminId, getAffilateUserId } from "../../../../utills";
import axios from "axios";
import { _getProductStateURL } from "../../../../url";
import toast from "react-hot-toast";

function Ladger({role = "vendor"}) {
  const [page, setPage] = useState(10);
  const [isFetching, setIsFetching] =  useState(false);
  const [history, setHistory] = useState([])
  
   // pagination state
   const [selectedOption, setSelectedOption] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetLadger = async () => {
      setIsFetching(true);
      try {
        const response = await axios({
           method : 'post',
           url : _getProductStateURL,
           data : {
            id: role === 'affilate' ? getAffilateUserId() :getAdminId(),
            page : currentPage,
            pagesize : selectedOption
           }
        })// Replace with actual URL
        // console.log(response.data);
        const {success , data} = response.data;
        if(success && data) {
          setHistory(data);
          setTotalItems(data?.length)
          setIsFetching(false); // Assuming response.data contains the category list
        }
      } catch (error) {
        toast.error("Failed to fetch users");
        console.error("Error fetching categories:", error);
        setIsFetching(false);
      }
    };
    fetLadger();
  },[currentPage, selectedOption]);

 
//   const label = { inputProps: { "aria-label": "Size switch demo" } };

  return (
    <>
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">History</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    // value={selectedOption}
                    // onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                <button className="btn btn-theam mx-2" type="button">
                  <span>Export CSV</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="mlr-10 mb-4">
          <table className="tableGap">
            <thead>
              <tr>
                <th>sr</th>
                <th>Category</th>
                <th>Affiliate User</th>
                <th>Commission</th>
                <th>Product Name</th>
                <th>Purchase User</th>
                <th>Product Amount</th>
                <th>Date Time</th>
              </tr>
            </thead>
            <tbody>
              {
                history?.map((data, idx) => (
                  <tr>
                  <td>1.</td>
                  <td>Mobile</td>
                  <td>Rahul</td>
                  <td>$20</td>
                  <td>Testing</td>
                  <td>Rishabh</td>
                  <td>$150</td>
                  <td>20-09-2024 / 14:11:00</td>
                 </tr>
                ))
              }
              
            </tbody>
          </table>
        </div>
        {/* <Pagination/> */}
      </section>
    </>
  );
}

export default Ladger;
