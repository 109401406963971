import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { REACT_BASE_PATH } from "../../../api";
import SnackBar from '../helper/SnackBar';
import { addAttributeMessage, deleteAttributeMessage } from "./ResponseMessage";
import {
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const ProductTaxes = () => {
    const [data, setData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const tableRef = useRef(null);
    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState(false);
    const [countryError, setcountryError] = useState(false);
    const [stateError, setstateError] = useState(false);
    const [validate, setvalidate] = useState(false);
    const [taxerror, settaxerror] = useState(false);
    const [categoryId, setcategoryId] = useState('');
    const [openTost, setopenTost] = useState(false);
    const [tostMessage, setostMessage] = useState('');
    const [type, setType] = useState('');
    const [editValue, seteditValue] = useState('');
    const [taxType, settaxType] = useState('Add')
    const [taxRate, settaxRate] = useState('')
    const [countryid, setCountryid] = useState('');
    const [stateid, setstateid] = useState('');

    const handleNameChange = (event) => {
        if (event.target.value) {
            setPinError(false);
        }
        setPin(event.target.value);
    };

    const handleChange = (event) => {
        const value = parseInt(event.target.value);
        setSelectedOption(value);
        setCurrentPage(1);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        const params = {
            "page": currentPage,
            "pagesize": selectedOption
        }
        try {
            const response = await axios.post(
                `${REACT_BASE_PATH}/tax/getTaxes`, params
            );
            
            if (response.data.success) {
                setData(response?.data?.data);
            }
            setTotalItems(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, selectedOption]);

    const addattribute = async () => {
        let payload = {
            country: countryid?.name,
            state: stateid?.name,
            // pincode: pin,
            taxRate: taxRate
        }
        if (taxType == 'Edit') {
            payload = { ...payload, id: editValue._id }
        }

        try {
            let path = taxType == 'Add' ? '/tax/addTax' : '/tax/updateTax';
            const response = await axios.post(`${REACT_BASE_PATH}` + path, payload);
            if (response.data.success) {
                setopenTost(true);
                setostMessage('Tax Added Successfully...');
                setType('success');
                fetchData()
            } else {
                setopenTost(true);
                setostMessage(response?.data.message);
                setType('error');
            }
        } catch (error) {
            setopenTost(true);
            setostMessage('Something went wrong...');
            setType('error');
            // console.error("Error fetching category:", error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const totalPages = Math.ceil(totalItems / selectedOption);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const addAttribute = () => {
        if (!countryid?.name) {
            setcountryError(true);
            return;
        } else if (!stateid?.name) {
            setstateError(true);
            return;
        }else if (!taxRate) {
            settaxerror(true);
            return;
        }
        addattribute(categoryId);
    };

    useEffect(() => {
        if (!countryid?.name) {
            setvalidate(false);
        } else if (!stateid?.name) {
            setvalidate(false);
        } else if (!taxRate) {
            setvalidate(false);
        } else {
            setvalidate(true)
        }
    }, [countryid, stateid, taxRate])

    const openSideBar = (type) => {
        if (type === 'add') {
            // setPin('');
            seteditValue('');
            settaxRate('')
            settaxType('Add');
            setCountryid('')
            setstateid('')
        }
        // fetchCategory();
    }

    const onEditAttribute = (item) => {
        seteditValue(item);
        // setPin(item.pincode)
        settaxRate(item.taxRate)
        setCountryid(item.country)
        setstateid(item.state)
        settaxType('Edit')
        openSideBar('edit');
    }

    const deleteAttribute = async (id) => {
        try {
            let payload = {
                id: id,
            }
            const response = await axios.post(`${REACT_BASE_PATH}/tax/deleteTax`, payload);
            if (response.data.success) {
                setopenTost(true);
                setostMessage('Tax Deleted Successfully');
                setType('success');
                fetchData()
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }


    return (
        <section id="admin-main-content">
            <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header border-bottom mb-4">
                    <h5 id="offcanvasRightLabel" className="text-capatlise">{taxType} Tax</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="mb-3">
                        <label for="attributeExample" class="form-label">Country<span className="required">*</span></label>
                        <CountrySelect
                            value={countryid}
                            onChange={(e) => {
                                setcountryError(false)
                                setCountryid(e);
                            }}
                            placeHolder="Select Country"
                        />
                        <span className="error">{countryError && 'Country field is required'}</span>
                    </div>
                    <div className="mb-3">
                        <label for="forCategory" class="form-label">State<span className="required">*</span></label>
                        <StateSelect
                            countryid={countryid.id}
                            onChange={(e) => {
                                setstateError(false)
                                setstateid(e);
                            }}
                            placeHolder="Select State"
                        />
                        <span className="error">{stateError && 'State field is required'}</span>
                    </div>
                    {/* <div className="mb-3">
                        <label for="forCategory" class="form-label">Pin Code<span className="required">*</span></label>
                        <input type="text" class="form-control" onChange={handleNameChange} id="attributeExample" placeholder="pin code" value={pin} />
                        <span className="error">{pinError && 'Pin Code feild is required'}</span>
                    </div> */}
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Tax Rate <span className="required">*</span></label>
                        <input type="text" class="form-control" onChange={(e) => { settaxerror(false); settaxRate(e.target.value) }} id="attributeExample" placeholder="tax rate" value={taxRate} />
                        <span className="error">{taxerror && 'Tax field is required'}</span>
                    </div>
                </div>
                <div class="offcanvas-footer border-top pt-4">
                    <div className=" mb-4 d-flex justify-content-end mx-4">
                        <button className="btn btn-secondary px-5 mx-2" onClick={addAttribute}
                            data-bs-dismiss={validate && "offcanvas"} type="button">{taxType == 'Add' ? 'Add' : 'Update'} Tax</button>
                        <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="vendor-top">
                        <h5 className="title-v">All Tax</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
                        <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <label>
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search Tax"
                                        aria-controls="DataTables_Table_0"
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
                            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                                <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                                    <label>
                                        <select
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select"
                                            id="entries"
                                            value={selectedOption}
                                            onChange={handleChange}
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="70">70</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="mb-6">
                                    <button className="btn btn-theam" onClick={() => openSideBar('add')} type="button"
                                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                        <span>
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                            <span className="mx-2">Add Tax</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="mlr-10 table-responsive mb-4">
                            <table ref={tableRef} className="table">
                                <thead>
                                    <tr>
                                        <th>Sr.</th>
                                        <th>Country</th>
                                        <th>State</th>
                                        <th>Tax Rate</th>
                                        {/* <th>Pin Code</th> */}
                                        <th style={{ minWidth: 85 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item?.country}</td>
                                            <td>{item?.state}</td>
                                            <td>{item?.taxRate}</td>
                                            {/* <td>
                                                {item?.pincode}
                                            </td> */}
                                            <td>
                                                <button className="edit-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                                                    aria-controls="offcanvasRight" onClick={() => onEditAttribute(item)}>
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                <button className="trash-icon" onClick={() => deleteAttribute(item._id)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className="row mb-4">
                    <div className="col-sm-12 col-md-6 mt-2">
                        <div className="dataTables_info">
                            Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="dataTables_paginate">
                            <ul className="pagination">
                                <li
                                    className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : ""}`}
                                >
                                    <span
                                        className="page-link"
                                        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                    >
                                        <i className="fa fa-chevron-left bx-18px"></i>
                                    </span>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                    <li
                                        key={index}
                                        className={`paginate_button page-item ${currentPage === index + 1 ? "active" : ""}`}
                                    >
                                        <span
                                            className="page-link"
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </span>
                                    </li>
                                ))}
                                <li
                                    className={`paginate_button page-item next ${currentPage === totalPages ? "disabled" : ""}`}
                                >
                                    <span
                                        className="page-link"
                                        onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                    >
                                        <i className="fa fa-chevron-right bx-18px"></i>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductTaxes;
