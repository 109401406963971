import React, { useEffect, useRef, useState } from "react";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import SnackBar from '../../helper/SnackBar';
import { addCategoryMessage, deleteCategoryMessage } from "../ResponseMessage";
import AllBanners from "./allBanners";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const AddBanners = () => {
    const [name, setName] = useState('');
    const inputRef = useRef()
    const [image, setImage] = useState(null);
    const [nameError, setNameError] = useState(false);
    const [linkError, setlinkError] = useState(false);
    const [imgError, setImgError] = useState(false);
    const [openTost, setopenTost] = useState(false);
    const [tostMessage, setostMessage] = useState('');
    const [type, setType] = useState('');
    const [categoryType, setCategotyType] = useState('Add')
    const [cate, setCat] = useState(false)
    const [category, setCategory] = useState('');
    const [validate, setvalidate] = useState(false);
    const [categoryIdApi, setcategoryIdApi] = useState('');
    const [categoryValue, setcategoryValue] = useState([]);
    const [selectedId, setselectedId] = useState('')
    const [categoryid, setcategoryid] = useState('')
    const [bannerLink, setBannerLink] = useState('')
    const [bannerId, setbannerId] = useState('')

    const handleNameChange = (event) => {
        if (event.target.value) {
            setNameError(false);
        }
        setName(event.target.value);
    };

    const categoryChange = (event) => {
        setCategory(event.target.value);
        setcategoryIdApi(event.target.value);
        setselectedId('')
    };

    const handleImageChange = (event) => {
        if (event.target.files[0]) {
            setImgError(false);
        }
        setImage(event.target.files[0]);
    };

    const addCategoryClick = async (type) => {
        if (type == 'Add') {
            setCategotyType(type)
        }
        if (!name) {
            setNameError(true);
            return;
        }
        if (!image) {
            setImgError(true);
            return;
        }
        if (!bannerLink) {
            setlinkError(true)
            return;
        }
        const formData = new FormData();
        formData.append('bannerTitle', name);
        formData.append('bannerUrl', bannerLink);
        formData.append('file', image);
        formData.append("categoryId", categoryIdApi == 1 || categoryIdApi == '' ? 0 : categoryIdApi);
        formData.append("isSponsored", categoryIdApi == 1 ? true : false);
        if (categoryType == 'Edit') {
            formData.append('bannerId', bannerId)
        }
        await AddCategory(formData);
    };

    const fetchCategory = async () => {
        let payload = {
            parent_id: 0
        }
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
            if (response.data.status === 200) {
                let bannerdata = [{ category_name: 'Banner for home', category_id: '0', _id: 0 }, { category_name: 'Banner for Sponsered', category_id: '0', _id: 1 }]
                bannerdata = [...bannerdata, ...response.data.data]
                setcategoryid(bannerdata[0]._id)
                setcategoryValue(bannerdata);
                setcategoryIdApi(bannerdata[0]._id)
                setCategory(bannerdata[0]);
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    const AddCategory = async (formData) => {
        let endpoint = categoryType == 'Add' ? '/product/addBanner' : '/product/updateBanner'
        try {
            const response = await axios.post(`${REACT_BASE_PATH}` + endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                inputRef.current.value = ''
                setopenTost(true);
                setostMessage(addCategoryMessage);
                setType('success');
                setCat(true)
                setName('');
                setImage(null)
            } else {
                setopenTost(true);
                setostMessage(response?.message);
                setType('warning');
                console.error('Failed to add brand:', response.data);
            }
        } catch (error) {
            setopenTost(true);
            setostMessage(error.message);
            setType('error');
            console.error("Error adding brand:", error);
        }
    };

    const editCategoryClick = (item) => {
        setName(item.banner_title)
        setImage(item.banner_image)
        setbannerId(item._id)
        setBannerLink(item.banner_url)
        setCategotyType('Edit')
        setTimeout(() => {
            setcategoryIdApi(item.isSponsored ? '1' : item.category_id)
            setcategoryid(item.isSponsored ? '1' : item.category_id)
            setselectedId(item.isSponsored ? '1' : item.category_id)
        }, 500);
    }

    const deleteCategory = async (id) => {
        const payload = {
            bannerId: id
        }
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/deleteBanner`, payload);
            if (response.data.status === 200) {
                setopenTost(true);
                setostMessage(deleteCategoryMessage);
                setType('success');
                setCat(!cate)
                setTimeout(() => {
                    setopenTost(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }

    const addBannerCLick = () => {
        setName('')
        setImage('')
        setbannerId('')
        setBannerLink('')
        setCategotyType('Add')
        setTimeout(() => {
            setcategoryIdApi('')
            setcategoryid('')
            setselectedId('')
        }, 500);
    }

    useEffect(() => {
        if (!name) {
            setvalidate(false)
        } else if (!image) {
            setvalidate(false)
        } else if (!bannerLink) {
            setvalidate(false)
        } else {
            setvalidate(true)
        }
    }, [name, image])

    const vendor = JSON.parse(localStorage.getItem('vendor')) || '';
    const { role } = vendor;

    return (
        <>
            <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
            <div className="m-2 mt-24 md:m-10 p-2 md:p-10 comp-bg">
                <section className="main-content-body bg-white" id="main_admin_content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="vendor-top col-md-6">
                                <h5 className="title-v">Add Banners</h5>
                            </div>
                            <div className="mb-6 col-md-6 text-end">
                                {role == 'admin' && <button className="btn btn-theam" type="button" data-bs-toggle="offcanvas" 
                                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={addBannerCLick}>
                                    <span>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        <span className="mx-2">Add Banners</span>
                                    </span>
                                </button>}
                            </div>
                        </div>

                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                            <div class="offcanvas-header border-bottom mb-4">
                                <h5 id="offcanvasRightLabel" className="text-capatlise">{categoryType == 'Add' ? 'Add' : 'Edit'} Banners</h5>
                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Banners Text<span className="required">*</span></label>
                                    <input type="text" class="form-control" onChange={handleNameChange} id="categoryNameId" placeholder="Enter banner text" value={name} />
                                    <span className="error">{nameError && 'Banner  field is required'}</span>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Banners Redirect Url<span className="required">*</span></label>
                                    <input type="text" class="form-control" onChange={(text) => setBannerLink(text.target.value)} id="categoryNameId" placeholder="Enter redirect Name" value={bannerLink} />
                                    <span className="error">{linkError && 'Banner url field is required'}</span>
                                </div>
                                <div>
                                    <label for="exampleFormControlInput1" class="form-label">Select Category Name<span className="required">*</span></label>
                                    {/* <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={category}
                                        label="Category"
                                        className="w-100"
                                        onChange={categoryChange}
                                    >
                                        {categoryValue?.map(category => (
                                            <MenuItem key={category?._id} value={category} selected={selectedId == category._id ? true : false}>
                                                {selectedId +', '+ category._id +', '+ category?.category_name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}

                                    <select onChange={categoryChange} className="form-control">
                                    {categoryValue?.map(category => (
                                        <option value={category?._id} selected={selectedId == category._id ? true : false}>{ category?.category_name}</option>
                                    ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Upload Banner Image<span className="required">*</span></label>
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        accept="image/*"
                                        id="contained-button-file"
                                        className="form-control"
                                        onChange={handleImageChange}
                                    />
                                    <span className="validation-mesgges">{imgError ? 'Banner image is required' : ''}</span>
                                </div>
                            </div>
                            <div class="offcanvas-footer border-top pt-4">
                                <div className=" mb-4 d-flex justify-content-end mx-4">
                                    <button className="btn btn-secondary px-3 mx-2" data-bs-dismiss={validate && "offcanvas"} onClick={() => addCategoryClick('Add')} type="button">
                                        {categoryType == 'Add' ? 'Add' : 'Update'} Banners</button>
                                    <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                                </div>
                            </div>
                        </div>
                        <hr className="mb-4"></hr>
                        <AllBanners editCategoryClick={editCategoryClick} deleteCategory={deleteCategory} cat={cate} />
                    </div>
                </section>
            </div>
        </>
    );
}
export default AddBanners;