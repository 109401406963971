import React from 'react';
import { FaStar, FaHeart, FaExchangeAlt, FaShoppingCart } from 'react-icons/fa';
import './category.css';
import { NavLink } from 'react-router-dom';
import { REACT_BASE_PATH, Asset_url } from "../../../api";

const Card = ({ product, handleAddToCart, handleAddToWishlist }) => {
  return (

    // <div className="">
    <NavLink to={`/singleproduct/${product._id}`} className='pro-card col-lg-3 col-md-4 col-sm-6 col-12' >
      <div className='card-imagee'>
        <img src={product?.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`} alt={product.product_name} className="img-fluid" />
      </div>
      <div className="card-content">
        <h3 className="card-title">{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</h3>
        <div className="card-rating">
          {Array(product.rating).fill().map((_, i) => (
            <FaStar key={i} />
          ))}
        </div>
        {product.offer_price ?
          <div className="card-prices">
            <span className="card-offer-price">${product.price}</span>
            <span className="card-price">${product.offer_price}</span>
          </div> :
          <div className="card-prices">
            <span className="card-price">${product.price}</span>
          </div>
        }
        <div className="card-buttons">
          <button className="card-button heart" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAddToWishlist(product);
          }}><FaHeart /></button>
          <button className="card-button compare" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}><FaExchangeAlt /></button>
          <button className="card-button add-to-cart" ><FaShoppingCart /> ADD TO CART</button>
        </div>
      </div>
    </NavLink>
  );
};

export default Card;
