import React, { useState, useEffect, useRef } from "react";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import { Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SnackBar from '../../helper/SnackBar';
import AllCategories from './AllSubCategories';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { addCategoryMessage, deleteCategoryMessage } from "../ResponseMessage";

const Input = styled('input')({
  display: 'none',
});


const AddSubSubCategories = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [categoryValue, setcategoryValue] = useState([]);
  const [categoryId, setcategoryId] = useState('');
  const [categoryType, setcategoryType] = useState('Add')
  const [subcategory, setSubCategory] = useState('');
  const [subcategoryValue, setSubcategoryValue] = useState([]);
  const [subcategoryId, setSubcategoryId] = useState('');
  const [isEdit, setisEdit] = useState(false)
  const [validate, setvalidate] = useState(false)
  const [SelectedCat, setSelectedCat] = useState(null)
  const [SelectedSubCat, setSelectedSubCat] = useState(null)
  const [childcategoryId, setchildcategoryId] = useState(null)
  const inputRef = useRef()

  const categoryChange = (event) => {
    console.log(event.target.value)
    setCategory(event.target.value);
    setcategoryId(event.target.value._id);
    fetchSubCategory(event.target.value._id)
  };

  const subcategoryChange = (event) => {
    console.log(event.target.value)
    setSubCategory(event.target.value);
    setSubcategoryId(event.target.value._id);
  };

  const handleNameChange = (event) => {
    if (event.target.value) {
      setNameError(false);
    }
    setName(event.target.value);
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setImgError(false);
    }
    setImage(event.target.files[0]);
  };

  const addChildCategory = async () => {
    if (!name) {
      setNameError(true);
      return;
    }
    if (!image) {
      setImgError(true);
      return;
    }
    let userData = localStorage.getItem("vendor");
    userData = JSON.parse(userData);
    const formData = new FormData();
    formData.append('category_name', name);
    formData.append('category_image', image);
    formData.append('level', 2);
    if(categoryType == 'Add'){
      formData.append('parent_id', subcategoryId);
      formData.append("vendor_id", userData?.data?.vendorid);
    }else{
      formData.append('category_id', childcategoryId);
    }
    
    await addSubCategory(formData);

  };

  const addSubCategory = async (formData) => {
    try {
      let path = categoryType == 'Add'?'/product/add/productsubcategory':'/product/update/productcategory'
      const response = await axios.post(`${REACT_BASE_PATH}`+path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        inputRef.current.value = ''
        setName('');
        setImage(null);
        setisEdit(!isEdit)
        setopenTost(true);
        setostMessage('Child ' + addCategoryMessage);
        setType('success');
      } else {
        setopenTost(true);
        setostMessage(response?.message);
        setType('warning');
        console.error('Failed to add brand:', response.data);
      }
    } catch (error) {
      setopenTost(true);
      setostMessage(error.message);
      setType('error');
      console.error("Error adding brand:", error);
    }
  };

  const fetchCategory = async () => {
    let payload = {
      parent_id: 0
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        setcategoryId(response.data.data[0]._id)
        setcategoryValue(response.data.data);
        setCategory(response.data.data[0]);
        if (response.data.data[0]) {
          fetchSubCategory(response.data.data[0]._id);
        }
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };


  const fetchSubCategory = async (catId) => {
    let payload = {
      parent_id: catId
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        setSubcategoryValue(response.data.data);
        setSubcategoryId(response.data.data[0]._id);
        setSubCategory(response.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const deleteCategory = async(id) => {
    const payload = {
      category_id:id
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/delete/productcategory`, payload);
      if (response.data.status === 200) {
        setopenTost(true);
        setostMessage('Child '+deleteCategoryMessage);
        setType('success');
        setisEdit(!isEdit)
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }

  useEffect(() => {
    if (!name) {
      setvalidate(false)
    } else if (!image) {
      setvalidate(false)
    } else {
      setvalidate(true)
    }
  }, [name, image])

  const editChildCategory = (item) => {
    console.log('-=-=', item);
    setSelectedCat(item.parent_id.parent_id._id)
    setSelectedSubCat(item.parent_id._id)
    setName(item.category_name)
    setchildcategoryId(item._id)
    setcategoryType('Edit')
  }

  useEffect(() => {
    fetchCategory();
  }, [setostMessage]);

  return (
    <>
      <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
      <div className="m-2 mt-24 md:m-10 p-2 md:p-10  rounded-3xl comp-bg">
        <section className="main-content-body bg-white" id="main_admin_content">
          <div className="container-fluid">
            <div className="row">
              <div className="vendor-top col-md-6">
                <h5 className="title-v">Add Child Category (Level 3)</h5>
              </div>
              <div className="mb-6 col-md-6 text-end">
                <button className="btn btn-theam" type="button" data-bs-toggle="offcanvas" data-bs-target="#childCategory" aria-controls="childCategory">
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <span className="mx-2">Add Child Category</span>
                  </span>
                </button>
              </div>
            </div>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="childCategory" aria-labelledby="childCategoryLabel">
              <div class="offcanvas-header border-bottom mb-4">
                <h5 id="childCategoryLabel" className="text-capatlise">{categoryType == 'Add'?'Add':'Edit'} Child Category (Level 3)</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Child Category Name<span className="required">*</span></label>
                  <input type="text" class="form-control" onChange={handleNameChange} id="categoryNameId" placeholder="Enter sub category Name" value={name} />
                  <span className="error">{nameError && 'Name field is required'}</span>
                </div>
                <div>
                  <label for="exampleFormControlInput1" class="form-label">Select Category Name<span className="required">*</span></label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    className="w-100"
                    onChange={categoryChange}
                  >
                    {categoryValue?.map(category => (
                      <MenuItem key={category?._id} value={category} selected={SelectedCat == category._id ? true :false}>
                        {category?.category_name}
                      </MenuItem>
                    ))}

                  </Select>
                </div>
                <div>
                  <label for="exampleFormControlInput1" class="form-label">Select Sub Category Name<span className="required">*</span></label>
                  <Select
                    labelId="demo-simple-select-label"
                    value={subcategory}
                    label="Sub category"
                    className="w-100"
                    onChange={subcategoryChange}
                  >
                    {subcategoryValue?.map(subcategory => (
                      <MenuItem key={subcategory?._id} value={subcategory} selected={SelectedSubCat == category._id ? true :false}>
                        {subcategory?.category_name}
                      </MenuItem>
                    ))}

                  </Select>
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Upload Image<span className="required">*</span></label>
                  <input
                    type="file"
                    ref={inputRef}
                    accept="image/*"
                    id="contained-button-file"
                    className="form-control"
                    onChange={handleImageChange}
                  />
                  <span className="validation-mesgges">{imgError ? 'image is required' : ''}</span>
                </div>
              </div>
              <div class="offcanvas-footer border-top pt-4">
                <div className=" mb-4 d-flex justify-content-end mx-4">
                  <button className="btn btn-secondary px-3 mx-2" data-bs-dismiss={validate && "offcanvas"} onClick={()=>addChildCategory('Add')} type="button">{categoryType == 'Add'?'Add':'Update'} Child Category</button>
                  <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
              </div>
            </div>
            <hr className="mt-4"></hr>
            <AllCategories editChildCategory={editChildCategory} deleteCategory={deleteCategory} isEdit={isEdit} />
          </div>
        </section>
      </div>
    </>
  );
}
export default AddSubSubCategories;