import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from '../../componenth/header/Header';
import Footer from '../../componenth/header/Footer';
import { FaStar } from "react-icons/fa";
import { LiaShareSolid } from "react-icons/lia";
import { LuMessagesSquare } from "react-icons/lu";
import axios from 'axios';
import CancelReturnModal from './CancelReturnModal';
import { REACT_BASE_PATH, Asset_url } from "../../api";
import { toast } from 'react-toastify';

const OrderDetails = () => {
    const { orderId } = useParams();
    const [orderItems, setOrderItems] = useState([]);
    const [address, setAddress] = useState({});
    const [otherOrderDetails, setOtherOrderDetails] = useState({
        orderStatus: '',
        totalPrice: 0,
        userId: '',
        returnRequest: '',
        paymentMethod: '',
        paymentStatus: ''
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [showReturnCheckboxes, setShowReturnCheckboxes] = useState(false);
    const [showCancelCheckboxes, setShowCancelCheckboxes] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState(null);

    const openModal = (action, isAllItems) => {
        if (isAllItems) {
            let itemsToSelect = orderItems.map((order) => ({
                id: order._id,
                productId: order.productId._id,
                name: order.productId.product_name,
                image: order.productId.product_banner_image
            }));
            setSelectedItems(itemsToSelect);
        }

        if (!isAllItems && selectedItems.length === 0) {
            toast.warning(`Please select items to ${action}`)
            return;
        }
        setModalAction({ action, isAllItems });
        setIsModalOpen(true);
    };

    useEffect(() => {
        const getOrderDetails = async () => {
            try {
                const response = await axios.post(`${REACT_BASE_PATH}/product/GetOrderByOrderId`, {
                    orderId: orderId,
                });
                console.log(response.data);
                setOrderItems(response.data.data.items);
                setAddress(response.data.data.shippingAddress);
                setOtherOrderDetails({
                    orderStatus: response.data.data.status,
                    // orderStatus: 'dispatched',
                    totalPrice: response.data.data.totalPrice,
                    userId: response.data.data.userId,
                    returnRequest: response.data.data.returnRequest,
                    paymentMethod: response.data.data.paymentMethod,
                    paymentStatus: response.data.data.paymentStatus,
                });
            } catch (err) {
                // console.error('Error fetching order details:', err);
                toast.error('Failed to load order details. Please try again later.');
                toast.error(err.response?.data?.message || "Some error occured, please try again");
            }
        };
        getOrderDetails();
    }, [orderId, isModalOpen]);

    // Function to handle item selection
    const handleSelectItem = (item) => {
        setSelectedItems((prevSelected) => {
            const itemExists = prevSelected.some(selectedItem => selectedItem.id === item.id);

            if (itemExists) {
                return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    return (
        <>
            <div className='container mt-5'>
                <div className="order-details">
                    <div className="breadcrumb">
                        Home &gt; My Account &gt; My Orders &gt; {orderId}
                    </div>
                    <div className="order-container">
                        <div className="order-header">
                            <div className="delivery-address">
                                <h2>Delivery Address</h2>
                                <p>{address?.full_name}</p>
                                <p>
                                    {address?.address_detail}, {address?.locality}, {address?.city}, {address?.state}, {address?.country} - {address?.pin_code}
                                </p>
                                <p>Phone number: 99999999999</p>
                            </div>
                            <div className="rewards">
                                <h2>Your Rewards</h2>
                                <p><strong>4</strong> SuperCoins Cashback</p>
                                <p><strong>$17</strong> Saved Using SuperCoins</p>
                            </div>
                            {otherOrderDetails.orderStatus === 'delivered' && (
                                <div className="actions">
                                    <button>Download Invoice</button>
                                </div>
                            )}
                        </div>

                        <div className="order-item">
                            <div className="product-details">
                                <div className='product-image d-flex flex-column justify-content-center align-items-center' style={{ position: 'relative' }}>
                                    <img
                                        src={orderItems[0]?.productId.product_banner_image?.startsWith('http') ? orderItems[0]?.productId.product_banner_image : `${Asset_url}${orderItems[0]?.productId.product_banner_image}`}
                                        alt='order-item-image'
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="product-info">
                                    {orderItems.length > 1 ? (
                                        <p className="product-title">
                                            {orderItems[0].productId.product_name.length > 60 ? orderItems[0].productId.product_name.slice(0, 60) + "..." : orderItems[0].productId.product_name} <span className='linear-gradient-text'> and {orderItems.length - 1} more</span>
                                        </p>
                                    ) : (
                                        <p className="product-title">{orderItems[0]?.productId.product_name}</p>
                                    )}
                                    <p className="product-price">${otherOrderDetails.totalPrice}</p>
                                </div>
                            </div>
                            <div className="order-timeline">
                                {otherOrderDetails.orderStatus === 'canceled' ? (
                                    <div className="timeline">
                                        <div className="event active">
                                            <div className="text">
                                                <p style={{ color: '#4CAF50' }}>Order Confirmed</p>
                                                <div className="circle" style={{ background: '#4CAF50' }}></div>
                                                <span>Wed, 21st Aug</span>
                                            </div>
                                            <div className="line active" style={{ width: '16rem' }}></div>
                                        </div>
                                        <div className="event active">
                                            <div className="text">
                                                <p style={{ color: 'rgb(220 53 69)' }}>Cancelled</p>
                                                <div className="circle" style={{ background: 'rgb(220 53 69)', border: 'rgb(220 53 69)' }}></div>
                                                <span>Sun, 25th Aug</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="timeline">
                                        <div className="event active">
                                            <div className="text">
                                                <p style={{ color: ['confirmed', 'dispatched', 'out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus) ? '#4CAF50' : '#878787' }}>
                                                    Order Confirmed
                                                </p>
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background: ['confirmed', 'dispatched', 'out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus)
                                                            ? '#4CAF50'
                                                            : '#ffffff'
                                                    }}
                                                ></div>
                                                <span>Wed, 21st Aug</span>
                                            </div>
                                            <div
                                                className="line active"
                                                style={{ width: ['dispatched', 'out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus) ? '8rem' : '0rem' }}
                                            ></div>
                                        </div>
                                        <div className="event active">
                                            <div className="text">
                                                <p style={{ color: ['dispatched', 'out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus) ? '#4CAF50' : '#878787' }}>
                                                    Dispatched
                                                </p>
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background: ['dispatched', 'out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus)
                                                            ? '#4CAF50'
                                                            : '#ffffff'
                                                    }}
                                                ></div>
                                                <span>Fri, 23rd Aug</span>
                                            </div>
                                            <div
                                                className="line active"
                                                style={{ width: ['out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus) ? '8rem' : '0rem' }}
                                            ></div>
                                        </div>
                                        <div className="event active">
                                            <div className="text">
                                                <p style={{ color: ['out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus) ? '#4CAF50' : '#878787' }}>
                                                    Out for delivery
                                                </p>
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background: ['out for delivery', 'delivered'].includes(otherOrderDetails.orderStatus)
                                                            ? '#4CAF50'
                                                            : '#ffffff'
                                                    }}
                                                ></div>
                                                <span>Sun, 25th Aug</span>
                                            </div>
                                            <div
                                                className="line active"
                                                style={{ width: otherOrderDetails.orderStatus === 'delivered' ? '8rem' : '0rem' }}
                                            ></div>
                                        </div>
                                        <div className="event active">
                                            <div className="text">
                                                <p style={{ color: otherOrderDetails.orderStatus === 'delivered' ? '#4CAF50' : '#878787' }}>
                                                    Delivered
                                                </p>
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background: otherOrderDetails.orderStatus === 'delivered' ? '#4CAF50' : '#ffffff'
                                                    }}
                                                ></div>
                                                <span>Sun, 25th Aug</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="order-actions">
                                {otherOrderDetails.orderStatus === 'delivered' ? (
                                    <div className=' d-flex flex-column gap-3'>
                                        <button className='cancel-order-button' onClick={() => orderItems.length > 1 ? setShowReturnCheckboxes(true) : openModal('return', true)}>Return Order</button>
                                        <a href="#rate-review"><FaStar /> Rate & Review Product</a>
                                    </div>
                                ) : otherOrderDetails.orderStatus !== 'canceled' && otherOrderDetails.orderStatus!=='dispatched' && otherOrderDetails.orderStatus!=='out for delivery' ? (
                                    <button className='cancel-order-button' onClick={() => orderItems.length > 1 ? setShowCancelCheckboxes(true) : openModal('cancel', true)}>Cancel Order</button>
                                ) : null}

                            </div>
                        </div>
                        {(otherOrderDetails.orderStatus === 'delivered' && showReturnCheckboxes) ? <div className=" d-flex justify-content-between my-5 mx-5">
                            <h5 className=' text-danger'>Please select the items to return</h5>
                            <div className=' d-flex gap-5'>
                                <button
                                    className='cancel-order-button'
                                    onClick={() => openModal('return', true)}
                                >
                                    Return All Items
                                </button>
                                <button
                                    className='cancel-order-button'
                                    onClick={() => openModal('return', false)}
                                >
                                    Return Selected Items
                                </button>
                            </div>
                        </div> : (otherOrderDetails.orderStatus !== 'canceled' && otherOrderDetails.orderStatus !== 'delivered' && otherOrderDetails.orderStatus!=='dispatched' && otherOrderDetails.orderStatus!=='out for delivery' && showCancelCheckboxes) ? <div className=" d-flex justify-content-between my-5 mx-5">
                            <h5 className=' text-danger'>Please select the items to cancel</h5>
                            <div className=' d-flex gap-5'>
                                <button
                                    className='cancel-order-button'
                                    onClick={() => openModal('cancel', true)}
                                >
                                    Cancel All Items
                                </button>
                                <button
                                    className='cancel-order-button'
                                    onClick={() => openModal('cancel', false)}
                                >
                                    Cancel Selected Items
                                </button>
                            </div>
                        </div> : null}

                        {orderItems.length > 1 && (
                            <div className='d-flex flex-column'>
                                {orderItems.map((order) => (
                                    <div
                                        key={order._id}
                                        className="cart-item d-flex align-items-center justify-content-between"
                                        style={{
                                            position: 'relative',
                                            boxShadow: 'none',
                                            borderBottom: '1px solid #e0e0e0',
                                            borderRadius: '0',
                                            backgroundColor: selectedItems.some(selectedItem => selectedItem.id === order._id) ? '#f9f9f9' : 'transparent',
                                            padding: '10px',
                                            transition: 'background-color 0.3s ease'
                                        }}
                                    >
                                        <div className='product-image d-flex flex-column justify-content-center align-items-center'>
                                            <img
                                                src={order?.productId?.product_banner_image?.startsWith('http') ? order?.productId?.product_banner_image : `${Asset_url}${order.productId.product_banner_image}`}
                                                alt='order-item-image'
                                                className="img-fluid"
                                                style={{ width: '100px', height: '100px' }}
                                            />
                                        </div>
                                        <div className="cart-product-details d-flex justify-content-between align-items-center pe-5 flex-grow-1 ms-3">
                                            <div>
                                                <h4>{order.productId.product_name.length > 60 ? order.productId.product_name.slice(0, 60) + "..." : order.productId.product_name}</h4>
                                                <div className="product-price">
                                                    <span className="cart-current-price mb-0">
                                                        Price: ${order.price} ({order.quantity} {order.quantity === 1 ? 'quantity' : 'quantities'})
                                                    </span>
                                                </div>
                                            </div>

                                            {otherOrderDetails.orderStatus === 'delivered' ? (
                                                <div className='rate-and-review-product text-primary'>
                                                    <FaStar /> Rate & Review Product
                                                </div>
                                            ) : (otherOrderDetails.orderStatus === 'canceled' ||
                                                order.cancellation.status === 'canceled') ? (
                                                <span className='order-cancelled-text'>Cancelled</span>
                                            ) : (showCancelCheckboxes || showReturnCheckboxes) ? (
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedItems.some(selectedItem => selectedItem.id === order._id)}
                                                        onChange={() => handleSelectItem({
                                                            id: order._id,
                                                            productId: order.productId._id,
                                                            name: order.productId.product_name,
                                                            image: order.productId.product_banner_image,
                                                            quantity: order.quantity
                                                        })}
                                                        className="custom-checkbox"
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {isModalOpen && (
                    <CancelReturnModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        items={selectedItems}
                        modalAction={modalAction}
                        setSelectedItems={setSelectedItems}
                        orderId={orderId}
                    />
                )}
            </div>
        </>
    );
};

export default OrderDetails;
