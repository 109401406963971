/* eslint-disable jsx-a11y/heading-has-content */
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  _generateLinkUrl,
  _get_image_path,
  _getActiveCategory,
  _getProductByCategory,
  _getSpecificUsesr,
  IMAGE_BASE,
} from "../../url";
import { getAffilateAccessToken, getAffilateUserId } from "../../utills";
import LinkModal from "./LinkModal";
import toast from "react-hot-toast";
import { Box, FormControl, InputLabel, LinearProgress, MenuItem, Select } from "@mui/material";
import Pagination from "../AdminPanel/component/affilateDashboard/Pagination";

function Products() {
  const [userInfo, setUserInfo] = useState(null);
  const [products, setProducts] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [linkData, setData] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [commission, setcommission] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // loading states
  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

   // pagination state
   const [selectedOption, setSelectedOption] = useState(10);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    import("../AdminPanel/AdminPanelRoutes.css");
  }, []);

  useEffect(() => {
    const fectchUserInfo = async () => {
      try {
        let res = await axios.post(_getSpecificUsesr, {
          userId: getAffilateUserId(),
        });
        const data = res.data.data;
        setUserInfo(data?.userInfo);
        console.log("res", res.data);
      } catch (error) {
        console.log("error in fetching user info", error);
        // alert()
      }
    };
    fectchUserInfo();
  }, []);

  useEffect(() => {
    const fetchActiveCategory = async () => {
      setLoading(true);
      try {
        let res = await axios.post(
          _getActiveCategory,
          {
            id: getAffilateUserId(),
          },
          {
            headers: {
              Authorization: `Bearer ${getAffilateAccessToken()}`,
            },
          }
        );
        let data = res.data.data;
        data = data?.filter((d) => d.status);
        setcategoryData(data);
        setSelectedCategory(data[0]?.categoryId?._id);
        setcommission(data[0]?.affiliatePercentage);
        console.log("fetchActiveCategory", res.data);
      } catch (error) {
        console.log("error in fetching user info", error);
      } finally {
        setLoading(false);
      }
    };
    fetchActiveCategory();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const fetchProducts = async () => {
        setLoading(true);
        try {
          let res = await axios.post(_getProductByCategory, {
            categoryId: selectedCategory,
            page : currentPage,
            limit:selectedOption
          });
          const data = res.data.data;
          console.log("fetchProducts", data);
          let productData = [];
          if (data?.level0?.length) {
            productData = data?.level0;
          } else if (data?.level1?.length) {
            productData = data?.level1;
          } else if (data?.level2?.length) {
            productData = data?.level2;
          }

          console.log("_getProductByCategoryId", productData);
          setProducts(productData);
          setTotalItems(productData?.length)
        } catch (error) {
          console.log("error", error);
          // alert()
        } finally {
          setLoading(false);
        }
      };
      fetchProducts();
    }
  }, [currentPage,selectedCategory,selectedOption]);

  const getPersantage = useCallback((price) => {
    if(!commission) return null; 
    return (parseInt(price?.toFixed(2)) * +commission) / 100;
  },[commission]);

  console.log("selectedCategory>>>", selectedCategory);

  const generateLink = async (product_id) => {
    const payload = { id: getAffilateUserId(), product_id: product_id,categoryId: selectedCategory };
    setLinkLoading(true);
    try {
      const res = await axios({
        method: "post",
        url: _generateLinkUrl,
        headers: {
          Authorization: `Bearer ${getAffilateAccessToken()}`,
        },
        data: payload,
      });
      const { message } = res.data;
      if (message === "Share link is created successfully") {
        // console.log("res", res.data);
        setData(res.data);
      }
      // setData(data?.)
      handleOpen();
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
      // alert()
    } finally {
      setLinkLoading(false);
    }
  };

  return (
    <div className="">
      {
        linkLoading && <LinearProgress color={"secondary"} />
      }
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Products</h5>
              <div className="button-right-side">
                {/* <div className="addBrands btn-theam p-2">
                  wallet - {userInfo?.walletAmount}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <Box className="d-flex flex-column align-items-start gap-2" sx={{width : 350}}>
                <label>Categories</label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    label="Category"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    {/* Add your options here */}
                      {categoryData?.map((data) => {
                          return (
                            <MenuItem key={`catName-${data?._id}`} value={data?.categoryId?._id}>
                              {data?.categoryId?.category_name}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </FormControl>
              </Box>
              <p>{}</p>
            </div>
          </div>
        </div>
      </section>

   
      {loading && (
        <div className="products">
          {
             Array(8).fill(1).map(() => (
                <div className="product-card">
                <div className="p-3 text-white placeholder-glow">
                </div>
                <div className="p-2 d-flex justify-content-center align-items-center proudct-image-container">
                  <div>
                    <div
                      className="proudct-image rounded-start placeholder-glow"
                      alt="..."
                      width="80%"
                    />
                  </div>
                </div>
    
                <div className="card-body">
                  <h6 className="card-title text-dark placeholder-glow"></h6>
                  <div className="card-title w-25 d-flex gap-2 mt-2 placeholder-glow"></div>
                  <h5 className="text-lg font-semibold placeholder-glow"></h5>
                </div>
                <div className="card-footer placeholder-glow"></div>
              </div>
             ))
          }
        </div>
      )}

      <div class="products w-100">
        {!loading &&
          products?.map((product) => (
            <div className="product-card" key={product?._id}>
              <div className="card-header-text p-3 text-white">
                Commission - {commission}%
              </div>
              <div className="p-2 d-flex justify-content-center align-items-center proudct-image-container">
                <div>
                  <img
                    src={`${product?.product_banner_image?.includes("https") ? product?.product_banner_image : 'https://krizaar.com/api/uploads/'+product?.product_banner_image}`}
                    className="proudct-image rounded-start"
                    alt="..."
                    width="80%"
                  />
                </div>
              </div>

              <div className="card-body">
                <h6 className="card-title text-dark">
                  {product?.product_name}
                </h6>
                <div className="card-title w-25 d-flex gap-2 mt-2">
                  {/* <h5 style={{ textDecoration: "line-through", color: "gray" }}>
                    ${product?.price?.toFixed(2)}
                  </h5> */}
                  <h5> ${product?.price?.toFixed(2)}</h5>
                </div>
                <h5 className="text-lg font-semibold">
                  Get Price - ${getPersantage(product?.price)}
                </h5>
              </div>
              <div className="card-footer">
                <button
                  onClick={() => generateLink(product?._id)}
                  type="button"
                  className="btn card-header-text text-white w-100 p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-link"
                  >
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
                  </svg>
                  <span>Get Link</span>
                </button>
              </div>
            </div>
         ))}
      </div>
      {loading === false && products?.length === 0 && (
        <div className="bg-light p-5 d-flex justify-content-center align-items-center">
          <p>Product Not Found</p>
        </div>
      )}
      
      <div className="d-flex justify-content-end">
         <div></div>
         <Pagination
         selectedOption={selectedOption}
         currentPage={currentPage}
         setCurrentPage={setCurrentPage}
         totalItems={totalItems}
         setTotalItems={setTotalItems}
        />
      </div>
      <LinkModal linkData={linkData} open={open} handleClose={handleClose} />
    </div>
  );
}

export default Products;
