import React, { useState, useCallback, useEffect } from 'react';
import Pagination from "../Pagination";
import productImage from '../../img/firstlbg.jpg'
import { useNavigate } from "react-router-dom";
import { REACT_BASE_PATH, Asset_url } from '../../../../api';
import SnackBar from "../../helper/SnackBar";
import { deleteProductMessage } from '../ResponseMessage'
import { formatDate, formatDateTime } from "../../helper/DateTimeFormat";
import axios from 'axios';

const VendorProducts = ({ id }) => {
    const [selectedOption, setSelectedOption] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false);
    const [openTost, setopenTost] = useState(false);
    const [tostMessage, setostMessage] = useState('');
    const navigate = useNavigate();


    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${REACT_BASE_PATH}/product/getallproductsdetails`,
                {
                    page: currentPage,
                    vendorId: id,
                    pagesize: selectedOption, // no of item
                }
            );
            setData(response.data.data);
            setTotalItems(response.data.totalItems); // Update total items
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, selectedOption]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const totalPages = Math.ceil(totalItems / selectedOption);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const editClick = (editData) => {
        navigate('../editproduct/', { state: editData })
    }

    const deleteProductList = async (id) => {
        try {
            const response = await axios.post(
                `${REACT_BASE_PATH}/product/delete/productDelete`,
                {
                    product_id: id,
                }
            );
            setopenTost(true);
            setType('success');
            setostMessage(deleteProductMessage);
            fetchData()
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Product List</h5>
                </div>
            </div>
            <div className='tableGap'>
                <table>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>SKU</th>
                            <th>Price</th>
                            <th>Brand Name</th>
                            <th>Category</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((item, index) => {
                            return (
                                <tr key={item._id + index}>
                                    <td>
                                        <img
                                            src={item?.product_banner_image?.startsWith('http')
                                                ? item.product_banner_image
                                                : `${Asset_url}${item?.product_banner_image}`}
                                            alt="product"
                                            width="50"
                                            height="50"
                                        />
                                    </td>
                                    <td>{item.product_name}</td>
                                    <td>{item.product_squ_id}</td>
                                    <td>
                                        {item.price}
                                    </td>
                                    <td>{item.brand_details?.brand_name}</td>
                                    <td>{item.category_details_level0?.category_name}</td>
                                    <td>{formatDate(item.createdAt)}</td>
                                    <td>
                                        <div className="btn-group border-0">
                                            <button className="edit-btn" onClick={() => editClick(item)}>
                                                <i className="fa fa-edit"></i>
                                            </button>
                                            <button className="trash-icon" onClick={() => deleteProductList(item._id)}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='row'>
                <div className="col-sm-12 col-md-6">
                    {data.length > 0 && <div className="dataTables_paginate">
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>}
                </div>
            </div>
        </>
    )
}

export default VendorProducts;