import React, { useCallback, useEffect, useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import UserAccount from './userAccounts';
import UserProfile from './userProfile';
import UserOrders from './userOrders';
import profileIcon from '../../img/woman.png'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Asset_url, REACT_BASE_PATH } from '../../../../api';

const UserrDetails = () => {
    const location = useLocation();
    const vendor = JSON.parse(localStorage.getItem('vendor'))
    const { role } = vendor;
    const { tab, id } = location.state || {};
    const [value, setValue] = useState(!tab ? 1 : tab.toString());
    const [data, setData] = useState([])
    const [Userdata, setUserdata] = useState([]);
    const [AddressData, setAddressData] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchData = useCallback(async () => {
        // setLoading(true);
        const vendor = JSON.parse(localStorage.getItem('vendor'))
        const { vendorid, role } = vendor;
        const params = {
            "userId": id,
            "vendorId": role == 'admin' ? "0" : vendorid,
            // "pagesize":10,
            // "page":page
        }
        try {
            const response = await axios.post(
                `${REACT_BASE_PATH}/product/listOrdersByUserId`, params
            );

            if (response.data.status === 200) {
                setData(response?.data?.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            // setLoading(false);
        }
    }, []);

    const getAddress = async () => {
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/auth/user/addresses`, {
                "address": {
                    "user_id": id
                }
            });
            if (response.data.status === 200) {
                setAddressData(response.data.userAddress)
            } else {
                console.log('error');

            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }

    const getUserDetail = async () => {
        try {
            const { accessToken } = JSON.parse(localStorage.getItem('vendor'))
            const response = await axios.post(`${REACT_BASE_PATH}/auth/user/profile/detail`, {
                "id": id
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken
                },
            });
            if (response.data.status === 200) {
                setUserdata(response.data.userdata)
            } else {
                console.log('error');

            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }

    useEffect(() => {
        fetchData();
        getUserDetail()
        getAddress()
    }, []);


    return (
        <>
            <section className="custom-cart-panel mb-3">
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">User Details</h5>
                        </div>
                    </div>
                </div>
                <div className="card-header border-top px-0 pt-4 py-0">
                    <div className="vendorProfile">
                        <div className=''>
                            <img src={Userdata.profile_image ? `${Asset_url}${Userdata.profile_image}` : profileIcon} alt='' />
                        </div>
                        <div className='flex-grow-1'>
                            <h5 className='mb-3'>{Userdata?.first_name} {Userdata?.last_name}<span className='badge'>Active</span> </h5>
                            <h5><span className='spanSpace'>ID:-</span>{Userdata?._id}</h5>
                            <h6><span className='spanSpace'>Email ID:- </span>{Userdata?.email}</h6>
                            <h6><span className='spanSpace'>Mobile No:-</span>{Userdata?.phone_number}</h6>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Profile" value="1" />
                            {role === 'admin' && <Tab label="Accounts" value="2" />}
                            {/* <Tab label="Products" value="3" /> */}
                            <Tab label="Orders" value="3" />
                        </Tabs>
                    </Box>
                </TabContext>
            </div>
            <TabContext value={value}>
                <TabPanel value="1">
                    <UserProfile AddressData={AddressData} />
                </TabPanel>
                <TabPanel value="2">
                    <UserAccount />
                </TabPanel>
                <TabPanel value="3">
                    <UserOrders data={data} />
                </TabPanel>
            </TabContext>
        </>
    )
}

export default UserrDetails