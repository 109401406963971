import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { useState } from "react";
import { REACT_BASE_PATH } from "../../../api";
import axios from "axios";
import { addAttributeMessage } from "./ResponseMessage";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "40%",
    height: "auto",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
};

const ParameterPopup = ({ setAdded }) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [categoryId, setcategoryId] = useState('');
  const [parameterName, setParameterName] = useState("");
  const [categoryValue, setcategoryValue] = useState([]);
  const [selectedUnit, setselectedUnit] = useState('')
  const inpurref = useRef()

  useEffect(() => {
    fetchCategory()
  }, [])
  function openModal() {
    setIsOpen(true);
    setSuccessMessage("");
  }

  const categoryChange = (event) => {
    setcategoryId(event.target.value);
  };

  function afterOpenModal() {
    subtitle.style.color = "#C22D57";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const fetchCategory = async () => {
    let payload = {
      parent_id: 0
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        setcategoryId(response.data.data[0]._id)
        setcategoryValue(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const addattribute = async () => {
    let payload = {
      categoryId: categoryId,
      attributeName: parameterName,
      units: selectedUnit
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/admin/attribute/addAttribute`, payload);
      if (response.data.status === 200) {
        setParameterName('')
        inpurref.current.value = ''
        setSuccessMessage("Attribute added successfully!");
        setTimeout(() => {
          setIsOpen(false);
          setAdded(true)
        }, 2000);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  return (
    <>
      <div>
        <button onClick={openModal} className="add-brand-btn">
          Add Attribute
        </button>
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Add Parameter Modal"
        >
          <div className="modal-close">
            <h2
              ref={(_subtitle) => (subtitle = _subtitle)}
              className="popup-head"
              style={{ color: "#C22D57" }}
            >
              Add Attribute
            </h2>
            <button onClick={closeModal} className="close-popup">
              <i className="fa fa-close"></i>
            </button>
          </div>

          <div>
            <div>
              <label>Attribute Name:</label>
              <input
                type="text"
                value={parameterName}
                className="prod-input form-control"
                onChange={(e) => setParameterName(e.target.value)}
                required
              />
            </div>
            <div>
              <select class="form-select mb-3" aria-label="Default select example" onChange={categoryChange}>
                {categoryValue?.map(category => (
                  <>
                    <option value={category?._id} selected={categoryId === category?._id}>{category?.category_name}</option>
                  </>
                ))}
              </select>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Value <span className="notes">(value should be commom seprated.)</span></label>
              <textarea class="form-control" value={selectedUnit} ref={inpurref} onChange={(e) => setselectedUnit(e.target.value)} placeholder="example value1, value2,..etc." id="exampleFormControlTextarea1" rows="6"></textarea>            </div>

            {successMessage && <p>{successMessage}</p>}
          </div>
          <div className="text-center">
            <button type="submit" onClick={addattribute} className="popup-btn">
              Add Attribute
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ParameterPopup;
