import { Parser } from '@json2csv/plainjs';
export const exportCSV = (data) => {
    // const rows = Array.from(tableRef.current.querySelectorAll("tr"));
    // const csvData = rows
    //   .map((row) => {
    //     const cells = Array.from(row.querySelectorAll("th, td"));
    //     return cells.map((cell) => cell.textContent).join(",");
    //   })
    //   .join("\n");

    const parser = new Parser();
    const csv = parser.parse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "products.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};