import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { REACT_BASE_PATH, Asset_url } from "../../api";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import userImage from "../img/userImage.png";
import arrowDown from "../img/caret-down.png";
import Cookies from 'js-cookie'
import { CartContext } from "../../components/WebPanel/cart/CartContext";

const Header = ({ setIsDropdownOpen }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [overflowCategories, setOverflowCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredSubcategory, setHoveredSubcategory] = useState(null);
  const loginUserToken = Cookies.get('krizaar-user');
  const userId = localStorage.getItem('userId');
  const { pathname } = useLocation();

  const navigate = useNavigate()

  const handleMouseEnter = (index) => {
    setDropdownOpen(index);
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
    setIsDropdownOpen(false);
  };
  const dropdownRef = useRef(null);


  useEffect(() => {
    const isUserLoggedIn = async () => {
      if (loginUserToken && userId) {
        try {
          const response = await axios.post(
            `${REACT_BASE_PATH}/auth/user/profile/detail`,
            { id: userId },
            {
              headers: {
                Authorization: `Bearer ${loginUserToken}`,
                "Content-Type": "application/json",
              }
            }
          );
          if (response.data && response.data.userdata) {
            if (response.data.userdata.first_name === null && pathname !== '/userprofile') {
              navigate('/userprofile')
              return;
            }
            setUser({
              name: response.data.userdata.first_name ? response.data.userdata.first_name : ''
                + ' ' +
                response.data.userdata.last_name ? response.data.userdata.last_name : '',
              image: response.data.userdata.image ? response.data.userdata.image : userImage
            })
            setIsLoggedIn(true);
          }
        } catch (e) {
          console.log(e, "Failed to fetch user details due to error: ", e.message)
        }
      }
    }
    const fetchAllCategories = async () => {
      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/category-subcategory`
        );
        if (response.data.success) {
          const maxVisible = 8; // Set the max number of categories visible before 'More'
          const fetchedCategories = response.data.data;
          setAllCategories(fetchedCategories)
          setVisibleCategories(fetchedCategories.slice(0, maxVisible));
          setOverflowCategories(fetchedCategories.slice(maxVisible));
        } else {
          // console.error("Error fetching categories:", response.data.message);
          toast.error("An error occurred while fetching categories. Please try again.");
        }
      } catch (error) {
        // console.error("Error fetching categories:", error);
        toast.error(error.response?.data?.message || "Some error occured, please try again");
      }
    };

    fetchAllCategories();
    isUserLoggedIn();
  }, [loginUserToken, userId]);

  const renderCategoryDropdown = (category, index) => (
    <li
      className={`nav-item header-category-item ${dropdownOpen === index ? "active-background" : ""}`}
      key={category._id}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={`/category/${category._id}`} className="nav-link header-category-link">
        {category.category_name}
      </Link>

      {dropdownOpen === index && (
        <div className="header-dropdown-menu header-dropdown-full-width">
          {/* Category Image */}
          <div className="category-information">
            <h2 className="category-title">{category.category_name}</h2>
            <img
              src={`${Asset_url}${category.category_image}`}
              className="img-fluid"
              alt="category-image"
            />
          </div>

          {/* Subcategories */}
          <div className="subcategories-dropdown">
            <div className="header-dropdown-row">
              {category.children.map((subcategory) => (
                <div
                  className="subcategory-item"
                  key={subcategory._id}
                  onMouseEnter={() => setHoveredSubcategory(subcategory._id)}
                >
                  <NavLink to={`/subcategory/${subcategory._id}`} className="header-dropdown-column-header text-dark">
                    {subcategory.category_name}
                  </NavLink>

                  {/* Child Categories */}
                  {hoveredSubcategory === subcategory._id && subcategory.children.length > 0 && (
                    <div className="child-category-dropdown">
                      {subcategory.children.map((child) => (
                        <NavLink
                          to={`/childcategory/${child._id}`}
                          className="child-category-link"
                          key={child._id}
                        >
                          {child.category_name}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </li>
  );


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSearchChange = async (e) => {
    if (searchQuery?.length > 2) {
      try {
        const response = await fetch(
          `${REACT_BASE_PATH}/product/searchProductsByKeyword`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ keyword: searchQuery }),
          }
        );
        const data = await response.json();
        if (data.success) {
          setSearchResults(data.data);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }
  };

  const toggleDropdownAll = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);


  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [user, setUser] = useState({
    name: localStorage.getItem("username"),
    image: userImage,
  });

  const handleLogout = () => {
    Cookies.remove('krizaar-user');
    localStorage.removeItem('userId');
    localStorage.removeItem('checkoutState');
    localStorage.removeItem('cartState');
    localStorage.removeItem('userId');
    localStorage.removeItem('forgotPasswordTimestamp');
    setIsLoggedIn(false);
    setUser({ name: null, image: null });
    navigate('/')
  }

  const { state, dispatch } = useContext(CartContext);

  if (
    pathname === '/about-us' ||
    pathname === "/contactus" ||
    pathname === "/faqpage" ||
    pathname === "/privacy-policy" ||
    pathname === "/cookies-policy" ||
    pathname === "/sanctions-policy" ||
    pathname === "/interest-based-ads" ||
    pathname === "/electronic-communication-policy" ||
    pathname === "/terms-of-service" ||
    pathname === "/return-refund" ||
    pathname === "/vendorlogin" ||
    pathname === "/vendorsignup" ||
    pathname === "/signup" ||
    pathname === "/signin" ||
    pathname === "/affilate_register" ||
    pathname === "/affiliate_login" ||
    pathname === "/vendor-registration" ||
    pathname === "/vsignup" ||
    pathname === "*"
  ) {
    return null
  }

  return (
    <nav className="header navbar navbar-expand-lg navbar-light p-0">
      <div className="container-fluid navbar-background">
        <div className="mobile-logo">
          <NavLink to="/">
            <video autoPlay loop muted className="logo">
              <source src="/logo3.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </NavLink>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="mobile-nav-items">
            <div className="mobile-nav-links">
              <span className="email">
                <NavLink to="mailto:info@krizaar.com" className="header1-icon">
                  <img src='/images/icons/email.png' alt="info" className="icon" /> info@krizaar.com
                </NavLink>
              </span>
              <span className="phone">
                <NavLink to="tel:+18885749227" className="header1-icon">
                  <img src='/images/icons/phone.png' alt="info" className="icon" /> 1888 KRIZAAR (1 888-574-9227)
                </NavLink>
              </span>
            </div>
          </div>
          <div className="mobile-nav-links mobile-nav-links-part2">
            <NavLink to="/whishlist" className="nav-link header1-icon">
              <img src='/images/icons/wishlist.png' alt="info" className="icon" /> <span>Wishlist</span>
            </NavLink>
            <NavLink to="" className="nav-link header1-icon">
              <img src='/images/icons/download app.png' alt="info" className="icon" /> <span>Download App</span>
            </NavLink>
            <NavLink to="" className="nav-link header1-icon">
              <img src='/images/icons/support and service.png' alt="info" className="icon" /> <span>Customer Services</span>
            </NavLink>
            <NavLink to="/about-us" className="nav-link last header1-icon">
              <img src='/images/icons/about us.png' alt="info" className="icon" /> <span>About Us</span>
            </NavLink>
          </div>
          <div className="mobile-nav-links">
            <div className="nav-actions mobile-nav-login-links">
              {isLoggedIn ? (
                <div className="user-info" onClick={toggleDropdown}>
                  <img src={user.image} alt="User" className="user-image" />
                  <span>{user.name}</span>
                  <img src={arrowDown} alt="Dropdown Arrow" />
                  {showDropdown && (
                    <div className="dropdown-menu show">
                      <NavLink to="/userprofile" className="dropdown-item">
                        <img src='/images/icons/profile.png' alt="" className="icon" />
                        My Profile
                      </NavLink>
                      <NavLink to="/payment" className="dropdown-item">
                        <img src='/images/icons/payment.png' alt="" className="icon" />
                        Payment
                      </NavLink>
                      <NavLink to="/allorders" className="dropdown-item">
                        <img src='/images/icons/my order.png' alt="" className="icon" />
                        Orders
                      </NavLink>
                      <NavLink to="/whishlist" className="dropdown-item">
                        <img src='/images/icons/wishlist.png' alt="" className="icon" />
                        Wishlist
                      </NavLink>
                      <NavLink to="/faqpage" className="dropdown-item">
                        <img src='/images/icons/faq.png' alt="" className="icon" />
                        FAQs
                      </NavLink>
                      <NavLink to="/notification" className="dropdown-item">
                        <img src='/images/icons/notification.png' alt="" className="icon" />
                        Notifications
                      </NavLink>
                      <button onClick={handleLogout} className="dropdown-item">
                        <img src='/images/icons/logout.png' alt="" className="icon" />
                        Logout
                      </button>
                      <hr />
                      <button to="" className="dropdown-item">
                        <img src='/images/icons/support and service.png' alt="" className="icon" />
                        24/7 Customer Service
                      </button>
                      <button to="" className="dropdown-item">
                        <img src='/images/icons/download app.png' alt="" className="icon" />
                        Download App
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <NavLink to="/signin" className="login-link">
                    <button
                      className="btn btn-outline-primary login-button"
                    >
                      <img src='/images/icons/login and logout.png' alt="login" className="icon" />
                      Log in / Sign up
                    </button>
                  </NavLink>
                  <NavLink to="/signin" className="nav-link header1-icon login-link-mobile">
                    <img src='/images/icons/login and logout.png' alt="info" className="icon" /> <span>Log in / Sign up</span>
                  </NavLink>
                </>
              )}
              <NavLink to='/cart' className='cart-icon-main-div d-flex ' >
                <img src='/images/icons/cart.png' alt="cart" className="icon cart-icon1" />
                <div className="cart-quantity-div">{state.totalQuantity}</div>
              </NavLink>
              <NavLink to="/cart" className="nav-link header1-icon login-link-mobile">
                <img src='/images/icons/cart.png' alt="info" className="icon" /> <span>Cart</span>
              </NavLink>
              <NavLink to='/vendorlogin' className="btn btn-primary seller-button d-flex align-items-center">
                Become a Seller
              </NavLink>
              <NavLink to="/vendorlogin" className="nav-link header1-icon login-link-mobile seller-mobile-button">
                <span>Become a Seller</span>
              </NavLink>
              <NavLink to='/affiliate_login' className="btn btn-primary seller-button d-flex align-items-center">
                Become an Affiliate
              </NavLink>
              <NavLink to="/affiliate_login" className="nav-link header1-icon login-link-mobile seller-mobile-button">
                <span>Become an Affiliate</span>
              </NavLink>
            </div>
          </div>
          <div className="mobile-nav-links">
            <div className="search-bar-wrapper">
              <div className="search-bar d-flex align-items-center justify-content-center">
                <li className="nav-item dropdown me-0">
                  <button
                    className="btn btn-category dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    aria-haspopup="true"
                    aria-expanded={dropdownOpen}
                    onClick={toggleDropdownAll}
                  >
                    All
                  </button>
                  <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                    <div className="dropdown-section">
                      <h6 className="ps-3 text-white">Shop by Category</h6>
                      <ul>
                        {allCategories.map((category) => (
                          <li className="dropdown-item text-white" key={category._id}>
                            <NavLink to={`/category/${category._id}`}>{category.category_name}</NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ul>
                </li>

                <div className="form-control d-flex align-items-center justify-content-between">
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search krizaar.com"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value)
                      handleSearchChange()
                    }}
                    style={{ border: 'none', background: 'transparent', outline: 'none' }}
                  />
                  <FaSearch onClick={handleSearchChange} />
                </div>
              </div>
              {searchResults?.length > 0 && (
                <div className="search-results">
                  <ul>
                    {searchResults.map((result) => (
                      <li key={result._id}>
                        <Link to={`/singleproduct/${result._id}`}>
                          <div className=" d-flex align-items-center gap-3">
                            <div className="image-box" style={{ width: '10%' }}>
                              <img
                                src={result?.product_banner_image?.startsWith('http') ? result.product_banner_image : `${REACT_BASE_PATH}/uploads/${result.product_banner_image}`}
                                alt={result.product_name.slice(0, 10)}
                                className="img-fluid" style={{ width: "100%", height: "2.5rem" }} />
                            </div>
                            {result.product_name.length > 40
                              ? `${result.product_name.slice(0, 40)}...`
                              : result.product_name}
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <ul className="navbar-nav">
          {visibleCategories.map((category, index) =>
            renderCategoryDropdown(category, index)
          )}

          {overflowCategories.length > 0 && (
            <li
              className={`nav-item header-category-item dropdown ${dropdownOpen === "more" ? "active-background" : ""
                }`}
              onMouseEnter={() => handleMouseEnter("more")}
              onMouseLeave={handleMouseLeave}
            >
              <span className="nav-link header-category-link dropdown-toggle" role="button">
                More
              </span>

              {dropdownOpen === "more" && (
                <ul className="more-dropdown-menu">
                  {overflowCategories.map((category) => (
                    <li key={category._id}>
                      <NavLink to={`/category/${category._id}`} className="dropdown-item">
                        {category.category_name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
