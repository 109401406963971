import React, { useState,useEffect } from "react";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import { Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SnackBar from '../../helper/SnackBar';

const Input = styled('input')({
  display: 'none',
});


const AddBrands = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');
  const [type, setType] = useState('');

  const handleNameChange = (event) => {
    if(event.target.value){
      setNameError(false);
    }
    setName(event.target.value);
  };

  const handleImageChange = (event) => {
    if(event.target.files[0]){
      setImgError(false);
    }
    setImage(event.target.files[0]);
  };

  const addBand = async () => {
    if (!name) {
      setNameError(true);
      return;
    }
    if (!image) {
      setImgError(true);
      return;
    }
    let userData = localStorage.getItem("vendor");
    userData = JSON.parse(userData);
    const formData = new FormData();
    formData.append('brand_name', name);
    formData.append('file', image);
    formData.append("vendor_id", userData?.data?.vendorid);
    await fetchBrands(formData);
  };

  const fetchBrands = async (formData) => {
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/addbrand`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
          setopenTost(true);
          setostMessage(response?.message);
          setType('success');
      } else {
          setopenTost(true);
          setostMessage(response?.message);
          setType('warning');
          console.error('Failed to add brand:', response.data);
      }
    } catch (error) {
          setopenTost(true);
          setostMessage(error.message);
          setType('error');
          console.error("Error adding brand:", error);
    }
  };

  
  return (
    <>
      <SnackBar openTost={openTost} tostMessage={tostMessage}  type={type} />
        <section id="admin-main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="vendor-top">
                <h5 className="title-v">Add Brand</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <TextField
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                  variant="outlined"
                  placeholder="name"
                  fullWidth
                  required
                  error={nameError}
                  helperText={nameError ? 'Name is required' : ''}
                />
              </div>
              <div className="col-6">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={handleImageChange}
                    error={imgError}
                    helperText={imgError ? 'Name is required' : ''}
                  />
                   <Button variant="contained" component="span">
                    Upload Image
                  </Button>
                   <span className="validation-mesgges">{imgError ? 'image is required' : ''}</span>
                </label>
                {image && <Typography variant="body2">{image.name}</Typography>}
              </div>
            </div>
            <div className="row mt-4 pb-4">
              <div className="col-12 offset-10">
                <div className="pull-right">
                <button onClick={addBand} type="submit" className="btn btn-success" variant="contained" color="primary">
                  Create
                </button>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}
export default AddBrands;