import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { REACT_BASE_PATH, Asset_url } from "../../../../api";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { formatDateTime } from '../../helper/DateTimeFormat';
import { addBrandMessage } from "../ResponseMessage";
import SnackBar from "../../helper/SnackBar";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'SR',
        label: 'Sr. No.',
    },
    {
        id: 'country',
        label: 'Country',
    },
    {
        id: 'shipping_charge',
        label: 'Shipping Charge',
    },
    {
        id: 'action',
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    useEffect(() => {
        import('../../AdminPanelRoutes.css');
    }, [])
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    All Shipping Charge List
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const Shipping = () => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setData] = useState([]);
    const [BrandType, setBrandType] = useState('Add')
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const location = useLocation();
    const [nameError, setNameError] = useState(false);
    const [imgError, setImgError] = useState(false);
    const [openTost, setopenTost] = useState(false);
    const [tostMessage, setostMessage] = useState('');
    const [validate, setvalidate] = useState(false)
    const [type, setType] = useState('');
    const [added, setAdded] = useState(false)
    const inputRef = useRef()

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows], // Including rows in the dependency array
    );

    const addBand = async (type) => {
        setBrandType(type)
        if (!name) {
            setNameError(true);
            return;
        }
        if (!image) {
            setImgError(true);
            return;
        }
        let userData = localStorage.getItem("vendor");
        userData = JSON.parse(userData);
        const formData = new FormData();
        formData.append('brand_name', name);
        formData.append('file', image);
        formData.append("vendor_id", userData?.data?.vendorid);
        await AddBrand(formData);
    };

    useEffect(() => {
        if (!name) {
            setvalidate(false)
        } else
            if (!image) {
                setvalidate(false)
            } else {
                setvalidate(true)
            }
    }, [name, image])

    const handleImageChange = (event) => {
        if (event.target.files[0]) {
            setImgError(false);
        }
        setImage(event.target.files[0]);
    };

    const handleNameChange = (event) => {
        if (event.target.value) {
            setNameError(false);
        }
        setName(event.target.value);
    };

    const AddBrand = async (formData) => {
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/addbrand`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                setopenTost(true);
                inputRef.current.value = ''
                setostMessage(addBrandMessage);
                setAdded(true)
                setType('success');
                setName('')
                setImage(null)
            } else {
                setopenTost(true);
                setostMessage(response?.message);
                setType('warning');
                console.error('Failed to add brand:', response.data);
            }
        } catch (error) {
            setopenTost(true);
            setostMessage(error.message);
            setType('error');
            console.error("Error adding brand:", error);
        }
    };

    const fetchBrands = async () => {
        try {
            const response = await axios.get(`${REACT_BASE_PATH}/product/brands`);
            if (response.data.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching brands:", error);
        }
    };

    useEffect(() => {
        fetchBrands();
    }, [location, added]);

    return (
        <>
            <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
            <section className="custom-cart-panel mb-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">Shipping Charge list</h5>
                            <div className="button-right-side">
                                <div className="addBrands">
                                    {/* <BrandPopup /> */}
                                </div>
                                <button className="btn btn-theam" type="button">
                                    <span>
                                        <span className="mx-2">Export CSV</span>
                                    </span>
                                </button>
                                <button className="btn btn-theam" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" type="button">
                                    <span>
                                        <span className="mx-2">Add Shipping Charge</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className="custom-cart-panel mb-3 p-0">
                <div className="row">
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <EnhancedTableToolbar numSelected={selected.length} />
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {visibleRows.map((row, index) => {
                                            const isItemSelected = isSelected(row._id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover

                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row._id}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox" onClick={(event) => handleClick(event, row._id)}>
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        <div className="table-image-preview">
                                                            USA
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">{'Rs.' + 40}</TableCell>
                                                    <TableCell align="left">
                                                        <button
                                                            className="edit-btn"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button className="trash-icon">
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
            </section>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header border-bottom mb-4">
                    <h5 id="offcanvasRightLabel" className="text-capatlise">{BrandType == 'Add' ? 'Add' : 'Edit'} Shipping Charges </h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="mb-3">
                        <label class="form-label">Select Country<span className="required">*</span></label>
                        <select className="form-control">
                            <option value={'usa'}>USA</option>
                            <option value={'india'}>India</option>
                            <option value={'uae'}>USE</option>
                        </select>
                        <span className="error">{nameError && 'shipping field is required'}</span>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Shipping Charges<span className="required">*</span></label>
                        <input type="text" class="form-control" onChange={handleNameChange} id="shippingCharge" placeholder="Enter shipping charge" value={name} />
                        <span className="error">{nameError && 'shipping field is required'}</span>
                    </div>
                </div>
                <div class="offcanvas-footer border-top pt-4">
                    <div className=" mb-4 d-flex justify-content-end mx-4">
                        <button className="btn btn-secondary px-5 mx-2" data-bs-dismiss={validate && "offcanvas"} onClick={() => addBand('Add')}
                            type="button">{BrandType == 'Add' ? 'Add' : 'Update'} Charges</button>
                        <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shipping;
