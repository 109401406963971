import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../header/Footer";
import axios from "axios";
import { REACT_BASE_PATH } from "../../api";
import { useNavigate } from "react-router-dom";
// import('../../components/WebPanel/WebPanelRoutes.css');
import Cookies from 'js-cookie'
import { toast } from "react-toastify";

const Forgotpassword = () => {

  const [email, setEmail] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    const userToken = Cookies.get('krizaar-user');
    const userId = localStorage.getItem('userId');
    if (userToken && userId) {
      navigate("/");
      return;
    }
  }, [navigate])

  const submitData = (e) => {
    e.preventDefault()
    if (!email) {
      toast.warning('Email is required')
      return;
    }
    axios
      .post(`${REACT_BASE_PATH}/auth/user/forget-password`, {
        email: email,
      })
      .then((resp) => {
        // console.log(resp);
        const currentTime = new Date().getTime();
        localStorage.setItem("forgotPasswordTimestamp", currentTime);
        toast.success('Please check your email for further instructions')
        navigate('/signin')
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response?.data?.msg)
      });
  };

  return (
    <>
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-5">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Forgot Password</h5>
                  <form onSubmit={submitData}>
                    <label htmlFor="email">Enter your Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="email-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder=""
                      required
                    />
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button
                        type="submit"
                        className="sub-btn"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">or</span>
            </div>
            <div className="create-acc-box">
              <button className="create-acc">Get an OTP on your Phone</button>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgotpassword;
