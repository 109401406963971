import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const TermsOfService = () => {

  const termsOfService = [
    {
      section: "Acceptance of Terms",
      content: "These Terms of Service form a legally binding agreement between you and Krizaar. If you reside in the United States, the contract is with Krizaar LLC; if you live in India, it's with Krizaar Hub India Private Limited, a subsidiary of Krizaar LLC. The Terms apply to all services offered by Krizaar, including the website, mobile app, and other services. By using any of these services, you agree to the Terms. If you disagree, you are not permitted to use Krizaar’s services."
    },
    {
      section: "Additional Documents",
      content: "Krizaar’s services help connect users worldwide to make, buy, and sell unique products. Along with the Terms, you must also adhere to Krizaar's Privacy Policy and Anti-Discrimination Policy. These policies are considered part of the Terms and must be read by users to understand the rules applicable to them."
    },
    {
      section: "Privacy",
      content: "Krizaar is committed to protecting your personal information. The Privacy Policy outlines how your data is collected, used, and shared when using the services. Krizaar and sellers act as separate data controllers, each responsible for the personal data they process. If Krizaar is found to be jointly responsible for data processing, you agree to defend and indemnify Krizaar for any legal consequences arising from your actions."
    },
    {
      section: "Your Account",
      content: "To use certain services, you need to create an account. Users must be at least 18 years old, provide accurate information, and are responsible for all activities on their account. You are also responsible for safeguarding your password. The Terms do not create any partnership or employment relationship between you and Krizaar."
    },
    {
      section: "Your Content",
      content: "Content you upload on Krizaar remains yours, but you grant Krizaar a license to use it to operate and promote its services. You are responsible for ensuring that your content does not violate any third-party rights or Krizaar's policies. Krizaar may use, modify, and distribute your content as necessary to run its platform."
    },
    {
      section: "Usage of Services",
      content: "You are granted a limited license to use Krizaar’s services, provided you comply with all applicable laws. You are responsible for paying fees, taxes, and ensuring that your activities on the platform are lawful."
    },
    {
      section: "Termination",
      content: "You can terminate your account at any time, but you remain responsible for any unpaid fees. Krizaar also reserves the right to suspend or terminate your account if you violate the Terms. In such cases, you may lose access to your content and account information."
    },
    {
      section: "Warranties and Liability Limitations",
      content: "Krizaar does not guarantee error-free services or uninterrupted access. It is not liable for any damages, losses, or errors arising from your use of its services."
    },
    {
      section: "Indemnification",
      content: "If Krizaar faces legal issues due to your actions, you agree to defend, indemnify, and hold the company harmless from any claims or expenses arising from your use of the platform."
    },
    {
      section: "Disputes Between Users",
      content: "Krizaar encourages users to resolve disputes amicably but offers a case system to help with transaction-related conflicts. However, Krizaar will not make legal judgments in these disputes."
    },
    {
      section: "Arbitration for North and South America",
      content: "If disputes cannot be resolved, you agree to individual arbitration instead of suing in court. Arbitration is the mandatory route for all claims, except for small claims or intellectual property disputes."
    },
    {
      section: "Dispute Resolution for the Rest of the World",
      content: "If you are based outside of the Americas, disputes will be governed by Irish law and decided in Irish courts. The United Nations Convention on Contracts for the International Sale of Goods (CISG) does not apply."
    },
    {
      section: "Changes to Terms",
      content: "Krizaar may modify the Terms over time, and your continued use of the services constitutes acceptance of those changes. If you disagree with the changes, you may close your account."
    },
    {
      section: "Additional Legal Notes",
      content: "The Terms supersede any prior agreements, and if any part is deemed invalid, the rest remains in effect. Krizaar may transfer its rights and obligations under the Terms."
    },
    {
      section: "Contact Information",
      content: "For any questions regarding the Terms, you can reach Krizaar at legal@krizaar.com."
    }
  ];

  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
          <h1 className="my-3">Terms of Service</h1>
            {
              termsOfService.map((term, index) => (
                <div className='checkout__step-header' style={{cursor: 'auto'}} key={index}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">
                      <span>{index + 1}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>{term.section}</h5></div>
                      <div className=' d-flex gap-3 mt-1'>
                        <p style={{textAlign: 'justify'}}>{term.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfService;
