import React, { useEffect } from "react";
import Header2 from "./Header2";
import Header1 from "./Header1";
import Header3 from "./Header3";
import { useLocation } from "react-router-dom";

const Header = ({ setIsDropdownOpen }) => {
  useEffect(() => {
    import('../../components/WebPanel/WebPanelRoutes.css')
  }, []);

  const { pathname } = useLocation()
  if (
    pathname === '/affliateDashboard' ||
    pathname === "/pay" ||
    pathname === "/sellersclub" ||
    pathname === "/krizadrops" ||
    pathname === "/adjunction" ||
    pathname === "/affluence" ||
    pathname === "/compliance" ||
    pathname === "/logistics" ||
    pathname === "/swap" ||
    pathname === "/stake" ||
    pathname === "*"
  ) {
    return null
  }
  return (
    <>
      <Header1 />
      <Header2 />
      <Header3 setIsDropdownOpen={setIsDropdownOpen} />
    </>
  );
};

export default Header;
