import React from "react";
import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const Profile = () => {

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path || location.pathname.includes(path);
  };
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <NavLink
              to='/userprofile'
              style={{
                display: "flex",
                background: isActive("/userprofile") ? "linear-gradient(90deg,#de5073 ,#51168c)" : "#D9D9D9",
                color: isActive("/userprofile") ? "#fff" : "#000",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div style={{
                fontSize: '20px'
              }}  className=" d-flex align-items-center justify-content-center">
                <FaUser/>
              </div>
              <span className="ps-3">
                Profile Information
              </span>
            </NavLink>
            <NavLink
              to='/manageaddress'
              style={{
                display: "flex",
                background: (isActive("/manageaddress") || isActive('/add-edit-address')) ? "linear-gradient(90deg,#de5073 ,#51168c)" : "#D9D9D9",
                color: (isActive("/manageaddress") || isActive('/add-edit-address')) ? "#fff" : "#000",
                padding: "10px",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <div style={{
                fontSize: '20px'
              }} className=" d-flex align-items-center justify-content-center">
                <FaLocationDot />
              </div>
              <span className="ps-3">
              Manage Addresses
              </span>
            </NavLink>

            {/* <div
              style={{
                display: "flex",
                background: "#D9D9D9",
                color: "#000",
                padding: "5px",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <i className="fas fa-user-alt prof-icon"></i>
              <div className="dropdown">
                <NavLink
                  className="prof-info"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Account Details
                </NavLink>

                <ul class="dropdown-menu">
                  <li>
                    <NavLink class="dropdown-item" href="#">
                      Profile information
                    </NavLink>
                  </li>
                  <li>
                    <NavLink class="dropdown-item" href="#">
                      Manage Address
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
