/* eslint-disable no-undef */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Header from '../../../componenth/header/Header'
import Footer from '../../../componenth/header/Footer'
import { CheckoutContext } from './CheckoutContext';
import Cookies from 'js-cookie'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { REACT_BASE_PATH, Asset_url } from "../../../api";
import StripePaymentGateway from './StripePaymentGateway';
import PaymentModal from './PaymentModal';
import CryptoJS from 'crypto-js';
import { useCookies } from 'react-cookie';
import { _save_affilate_user_state_url } from '../../../url';
import { toast } from 'react-toastify';

const Checkout = () => {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [currentStep, setCurrentStep] = useState(2);
  const [selectedAddressOption, setSelectedAddressOption] = useState('new');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
    name: '',
    phone: '',
    zip: '',
    locality: '',
    address: '',
    city: '',
    state: '',
    landmark: '',
    phoneNo: '',
    addressType: '',
    country: ''
  });

  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobno: '',
    image: '',
    isNewUser: ''
  })
  const [existingAddresses, setExistingAddresses] = useState([]);
  const { state, dispatch } = useContext(CheckoutContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allAvailableTaxes, setAllAvailableTaxes] = useState([]);
  const [TAX_RATE, setTAX_RATE] = useState(0)
  const [totalPriceBeforeTax, setTotalPriceBeforeTax] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [randomNumber, setRandomNumber] = useState(null);
  const [randomDiscountAmount, setRandomDiscountAmount] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [roundedGrandTotal, setRoundedGrandTotal] = useState(null);
  const [roundToNearest, setRoundToNearest] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('notinitiated');
  const [orderStatus, setOrderStatus] = useState('');
  const [paymentGateway, setPaymentGateway] = useState('');
  const [placedOrderDetails, setPlacedOrderDetails] = useState('');
  const [shippingPrice, setShippingPrice] = useState(0)
  const [serviceFee, setServiceFee] = useState(0)
  const [newUserDiscount, setNewUserDiscount] = useState(0);
  const [orderTotalAfterNewUsDiscount, setOrderTotalAfterNewUserDiscount] = useState(0);
  const [randomNumberAlreadyGenerated, setRandomNumberAlreadyGenerated] = useState(0);

  const navigate = useNavigate();
  const [cookies] = useCookies(['affilate_code']);

  let decryptedData = null;
  if (cookies.affilate_code) {
    // debugger;
    const data = CryptoJS.AES.decrypt(cookies.affilate_code, 'secret key 123');
    decryptedData = data.toString(CryptoJS.enc.Utf8);
    if (decryptedData === "") {
      decryptedData = null;
    } else {
      decryptedData = JSON?.parse(decryptedData)
    }
    console.log("dcrypted cookies", decryptedData);
  }

  useEffect(() => {
    if (state.totalPrice < 35) {
      toast.error("The minimum amount should be $35");
      navigate(-1);
    }
  }, [])

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmLogout = () => {
    Cookies.remove('krizaar-user');
    localStorage.removeItem('userId');
    localStorage.removeItem('checkoutState');
    localStorage.removeItem('cartState');
    localStorage.removeItem('userId');
    localStorage.removeItem('forgotPasswordTimestamp');
    closeModal();
  };


  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get('krizaar-user');

  if (!accessToken || !userId) {
    navigate("/signin");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAffilateUserStatus = async (decryptedData) => {
    //  const url = _save_affilate_user_state_url;
    try {
      const response = await axios.post(_save_affilate_user_state_url, {
        id: userId,
        affiliateId: decryptedData,
        amount: +grandTotal
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      console.log("response", response.data);
    } catch (err) {
      // console.log("Error in saveAffilateUserStatus",err);
      toast.error(err?.response?.data?.message || "Something went wrong")
    }
  }

  useEffect(() => {
    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }

    if (state.checkoutItems.length === 0) {
      navigate("/");
      return;
    }


    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/auth/user/profile/detail`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            }
          }
        );

        if (response.data && response.data.userdata) {
          // console.log(response.data.userdata)
          setUserDetails({
            first_name: response.data.userdata.first_name,
            last_name: response.data.userdata.last_name,
            email: response.data.userdata.email,
            mobno: response.data.userdata.mobno,
            image: response.data.userdata.image ? response.data.userdata.image : null,
            isNewUser: response.data.userdata.isNewUser
            // isNewUser: true
          })
        }
      } catch (e) {
        console.log(e, "Failed to fetch user details due to error: ", e.message)
      }
    }

    const fetchExistingAddresses = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/auth/user/addresses`, {
          address: {
            user_id: userId
          }
        }, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        // console.log(response.data.userAddress)
        if (response.data.userAddress.length > 0) {
          setSelectedAddress(response.data.userAddress[0])
          setSelectedAddressOption('existing');
        }
        setExistingAddresses(response.data.userAddress);
      } catch (err) {
        console.log("Error in fetching address of the user");
      }
    };

    const fetchAllAvailableTaxes = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/tax/getTaxes`);
        setAllAvailableTaxes(response.data.data)
      } catch (err) {
        console.log("Error in fetching all available taxes");
      }
    }
    fetchAllAvailableTaxes()
    fetchUserDetails();
    fetchExistingAddresses();
  }, [accessToken, navigate, state.checkoutItems, userId]);

  // console.log(selectedAddress)

  const addNewAddress = async () => {
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/auth/user/create/address`, {
        address: {
          user_id: userId,
          full_name: newAddress.name,
          pin_code: newAddress.zip,
          locality: newAddress.locality,
          address_detail: newAddress.address,
          country: newAddress.country,
          state: newAddress.state,
          city: newAddress.city,
          phone_no: newAddress.phoneNo,
          address_type: newAddress.addressType
        }
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      // console.log(response)
      toast.success("Thank you for adding your address successfully")
      setExistingAddresses([...existingAddresses, response.data.addressdata]);
      setSelectedAddress(response.data.addressdata);
    } catch (err) {
      console.log("Error in adding new address");
    }
  };

  const handleNextStep = async () => {
    if (currentStep === 2) {
      if (selectedAddressOption === 'new') {
        const requiredFields = ['name', 'phoneNo', 'zip', 'locality', 'address', 'city', 'state', 'addressType'];
        const allFieldsFilled = requiredFields.every(field => newAddress[field].trim() !== '');

        if (!allFieldsFilled) {
          toast.warning('Please fill out all required fields in the new address form.');
          return;
        }

        await addNewAddress();

        setSelectedAddressOption('existing');
        // setCurrentStep(prevStep => prevStep + 1);
      } else if (selectedAddress) {
        if (TAX_RATE === 0) {
          toast.error('Delivery is not possible at your location, please select another location')
          return;
        }
        setCurrentStep(prevStep => prevStep + 1);
      } else {
        toast.warning('Please select an address to proceed.');
      }
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      const matchedEntry = allAvailableTaxes.find(
        (entry) =>
          entry.country === selectedAddress.country &&
          entry.state === selectedAddress.state
      );

      if (matchedEntry) {
        setTAX_RATE(matchedEntry.taxRate);
      } else {
        setTAX_RATE(0);
      }
    }
  }, [selectedAddress, allAvailableTaxes]);


  const handleAddressOptionChange = (event) => {
    if (!selectedAddress) {
      toast.warning('There is not any existing address, Please add new address')
      return;
    }
    setSelectedAddressOption(event.target.value);
    // setSelectedAddress(null);
  };

  const handleNewAddressChange = (event) => {
    const { name, value } = event.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleIncrease = (id) => {
    dispatch({ type: 'INCREASE_QUANTITY', payload: { id } });
  };

  const handleDecrease = (id) => {
    const itemToDecrease = state.checkoutItems.find(item => item.id === id);

    if (itemToDecrease && itemToDecrease.quantity > 1) {
      const itemPrice = itemToDecrease.offer_price || itemToDecrease.price;
      const newTotalPrice = state.totalPrice - itemPrice;

      if (newTotalPrice < 35) {
        toast.error('Minimum order amount should be $35. You cannot decrease the quantity further.');
      } else {
        dispatch({ type: 'DECREASE_QUANTITY', payload: { id } });
      }
    }
  };

  const handleRemoveItem = async (productId) => {
    dispatch({ type: 'REMOVE_CHECKOUT_ITEM', payload: { id: productId } });
  }

  useEffect(() => {
    let calculatedShippingPrice
    if (state.totalPrice < 35) {
      toast.warning("The minimum cart value should be $35, Please add more items");
      setShippingPrice('N/A')
    } else if (state.totalPrice >= 35 && state.totalPrice <= 99.99) {
      setShippingPrice(state.totalPrice * 0.25);
      calculatedShippingPrice = state.totalPrice * 0.25;
    } else if (state.totalPrice >= 100) {
      setShippingPrice(state.totalPrice * 0.20);
      calculatedShippingPrice = state.totalPrice * 0.20;
    }
    // } else if (state.totalPrice >= 35 && state.totalPrice <= 49.99) {
    //   setShippingPrice(state.totalPrice * 0.22);
    //   calculatedShippingPrice = state.totalPrice * 0.22;
    // } else if (state.totalPrice >= 50 && state.totalPrice <= 99.99) {
    //   setShippingPrice(state.totalPrice * 0.19);
    //   calculatedShippingPrice = state.totalPrice * 0.19;
    // } else if (state.totalPrice >= 100) {
    //   setShippingPrice(0);
    //   calculatedShippingPrice = 0;
    // }

    const calculatedTotalPriceBeforeTax = state.totalPrice + calculatedShippingPrice;
    const calculatedTaxAmount = calculatedTotalPriceBeforeTax * (TAX_RATE / 100);
    const calculatedServiceFee = (calculatedTotalPriceBeforeTax + calculatedTaxAmount) * 0.035
    const calculatedOrderTotal = calculatedTotalPriceBeforeTax + calculatedTaxAmount + calculatedServiceFee;
    const calculatedNewUserDiscount = calculatedOrderTotal * 0.1
    const calculatedOrderTotalAfterNewUserDiscount = calculatedOrderTotal - calculatedNewUserDiscount;

    setTotalPriceBeforeTax(calculatedTotalPriceBeforeTax);
    setTaxAmount(calculatedTaxAmount);
    setOrderTotal(calculatedOrderTotal);
    setNewUserDiscount(calculatedNewUserDiscount)
    setServiceFee(calculatedServiceFee)

    let newTotal = userDetails.isNewUser ? calculatedOrderTotalAfterNewUserDiscount : calculatedOrderTotal;
    setGrandTotal(newTotal);
    setRoundedGrandTotal(Math.ceil(newTotal).toFixed(2));

  }, [state.totalPrice, TAX_RATE, userDetails.isNewUser]);

  const handlePlaceOrder = async () => {
    // const items = state.checkoutItems.map(item => ({
    //   productId: item.id,
    //   quantity: item.quantity.toString(),
    //   price: item.offer_price ? item.offer_price.toString() : item.price.toString(),
    // }));
    if (!randomNumber) {
      toast.warning('Please open your surprise gift')
      return;
    }
    setOrderStatus('processing')
    setPaymentStatus('notinitiated')
    setIsPaymentModalOpen(true);
    const items = state.checkoutItems.map(item => ({
      productId: item.id,
      image: item.image,
      measurements: item.measurements,
      name: item.name,
      price: item.offer_price !== null ? item.offer_price : item.price,
      productMaxStockQuantity: item.productMaxStockQuantity,
      quantity: item.quantity,
    }));
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/placeOrder`, {
        userId,
        items,
        coupon: 'ABC200',
        offer: 'offer',
        paymentMethod: 'Card',
        totalPrice: roundToNearest ? roundedGrandTotal : grandTotal,
        status: 'pending',
        paymentStatus: 'pending',
        shippingAddress: selectedAddress._id,
        shippingPrice: '0',
        randomizer: randomNumber
      });

      if (decryptedData) {
        saveAffilateUserStatus(decryptedData)
      }

      // console.log(response)
      setPlacedOrderDetails(response.data.data)
      setOrderStatus('success');
      // dispatch({ type: 'CLEAR_CHECKOUT_ITEMS' });
      // setTimeout(() => {
      //   navigate(`/order-details/${response.data.data._id}`);
      // }, 6000);
    } catch (error) {
      setOrderStatus('failed');
      setTimeout(() => {
        setIsPaymentModalOpen(false)
      }, 4000);
      console.log(error.response?.data?.message)
      toast.error(error.response?.data?.message || "Some error occured while placing order, please try again");
    }
  }


  const generateRandomNumber = async () => {
    try {
      // const getRandomizer = await axios.get('https://krizaar.com/api/randomizer/getRandomizer')
      // if(getRandomizer){
      //   const min = getRandomizer.data.data.minValue;
      //   const max = getRandomizer.data.data.minValue;
      // }
      const min = 1;
      const max = 10;
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomNumber(randomNum);
    } catch (error) {
      console.error(error)
      toast.error(error.response?.data?.message || 'Failed to generate random number, please try again later')
    }
  }

  return (
    <>
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
      <div className='checkout-section'>
        <div className='container'>
          <div className="checkout-section-inseide row m-0">
            <div className="checkout col-lg-8 col-12">
              {/* Step 1: Confirm Your Login */}
              <div className="checkout__step">
                <div className={`checkout__step-header`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">1</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Confirm Your Login</h5></div>
                      {currentStep > 1 && (
                        <div className=' d-flex gap-3 mt-1'>
                          <span>{userDetails.first_name ? userDetails.first_name : ''} {userDetails.last_name ? userDetails.last_name : ''}</span>
                          <span>{userDetails.mobno}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 1 && <button className="checkout__btn" onClick={() => {
                    setCurrentStep(1)
                    // setRandomNumber(null)
                    // setGrandTotal(null)
                  }}>CHANGE</button>}
                </div>
                {currentStep === 1 && (
                  <div className="checkout__login-info">
                    <div className='checkout-user-info'>
                      <img className='img-fluid' src={userDetails.image ? `${Asset_url}${userDetails.image}` : '/user.png'} alt='user-image' />
                      <div className='checkout-user-details'>
                        <p className='m-1 checkout-user-name'>Hello, {userDetails.first_name ? userDetails.first_name : ''} {userDetails.last_name ? userDetails.last_name : ''}!</p>
                        <p className='m-1'>{userDetails.mobno}</p>
                      </div>
                    </div>
                    <div className='buttons d-flex gap-3'>
                      <button className="checkout__btn" onClick={openModal}>
                        Change
                      </button>
                      <button className="checkout__btn" onClick={handleNextStep}>
                        Next
                      </button>
                    </div>
                  </div>

                )}
                <div className={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Confirm Logout</h5>
                        <button type="button" className="close-button" onClick={closeModal} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>Do you want to log out and change the user?</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="checkout__btn checkout__save-btn" onClick={handleConfirmLogout}>Yes, Logout</button>
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 2: Delivery Address */}
              <div className="checkout__step">
                <div className={`checkout__step-header`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">2</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Delivery Address</h5></div>
                      {currentStep > 2 && (
                        <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                          <div className="user-details d-flex gap-3">
                            <p className="m-0">{selectedAddress?.full_name}</p>
                            <p className="m-0">{selectedAddress?.phone_no || selectedAddress?.alternate_phone_no}</p>
                          </div>
                          <div className="address">
                            {selectedAddress?.locality},{selectedAddress?.address_detail}, {selectedAddress?.city}, {selectedAddress?.state}, {selectedAddress?.zip}, {selectedAddress?.country}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 2 && <button className="checkout__btn" onClick={() => {
                    setCurrentStep(2)
                    // setRandomNumber(null)
                    // setGrandTotal(null)
                  }}>CHANGE</button>}
                </div>
                {currentStep === 2 && (
                  <div className="checkout__address-section">
                    <div className="checkout__address-selection">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="addressOption"
                          value="new"
                          checked={selectedAddressOption === 'new'}
                          onChange={handleAddressOptionChange}
                        />
                        <span className="radio-button">Add New Address</span>
                      </label>
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="addressOption"
                          value="existing"
                          checked={selectedAddressOption === 'existing'}
                          onChange={handleAddressOptionChange}
                        />
                        <span className="radio-button">Select Existing Address</span>
                      </label>
                    </div>
                    {selectedAddressOption === 'existing' ? (
                      <div className="checkout__existing-address">
                        {existingAddresses.length > 0 ? (
                          existingAddresses.map((address) => (
                            <label
                              key={address._id}
                              className={`checkout__address-item ${selectedAddress._id === address._id ? 'selected' : ''}`}
                              onClick={() => setSelectedAddress(address)}
                            >
                              <input
                                type="radio"
                                name="address"
                                value={address._id}
                                checked={selectedAddress._id === address._id}
                                onChange={() => setSelectedAddress(address)}
                              />
                              <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                                <div className="user-details d-flex gap-3">
                                  <p className="m-0">{address.full_name}</p>
                                  <span>{address.address_type}</span>
                                  <p className="m-0">{address.phone_no || address.alternate_phone_no}</p>
                                </div>
                                <div className="address">
                                  {address.locality}, {address.address_detail}, {address.city}, {address.state}, {address.zip}, {address.country}
                                </div>
                                {selectedAddress._id === address._id && (
                                  <button
                                    type="button"
                                    className="checkout__btn checkout__save-btn"
                                    onClick={handleNextStep}
                                  >
                                    Deliver to this Address
                                  </button>
                                )}
                              </div>
                            </label>
                          ))
                        ) : (
                          <p>No existing addresses found.</p>
                        )}
                      </div>
                    ) : (
                      <form className='new-address-form row m-0'>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Your Name</label>
                          <input type="text" name="name" placeholder="Enter your name" value={newAddress.name} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Phone No</label>
                          <input type="tel" name="phoneNo" placeholder="Enter phone number" value={newAddress.phoneNo} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-12 col-sm-12 col-12">
                          <label>Address Line 1</label>
                          <input type="text" name="locality" placeholder="Enter your address line 1" value={newAddress.locality} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-12 col-sm-12 col-12">
                          <label>Address Line 2</label>
                          <input type="text" name="address" placeholder="Enter your address line 2" value={newAddress.address} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Country</label>
                          <CountrySelect
                            onChange={(e) => {
                              setNewAddress({ ...newAddress, country: e.name });
                              setCountryid(e.id);
                            }}
                            placeHolder="Select Country"
                          />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>State</label>
                          <StateSelect
                            countryid={countryid}
                            onChange={(e) => {
                              setNewAddress({ ...newAddress, state: e.name });
                              setstateid(e.id);
                            }}
                            placeHolder="Select State"
                          />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>City</label>
                          <CitySelect
                            countryid={countryid}
                            stateid={stateid}
                            onChange={(e) => {
                              setNewAddress({ ...newAddress, city: e.name });
                            }}
                            placeHolder="Select City"
                          />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Zip Code</label>
                          <input type="text" name="zip" placeholder="Enter your zip code" value={newAddress.zip} onChange={handleNewAddressChange} />
                        </div>

                        <div className="checkout__form-group col-12">
                          <label>Address Type</label>
                          <div className="checkout__radio-group">
                            <label>
                              <input
                                type="radio"
                                name="addressType"
                                value="home"
                                checked={newAddress.addressType === 'home'}
                                onChange={handleNewAddressChange}
                              />
                              Home (All day delivery)
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="addressType"
                                value="work"
                                checked={newAddress.addressType === 'work'}
                                onChange={handleNewAddressChange}
                              />
                              Office (delivery between 10AM to 5PM)
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <button
                            type="button"
                            className="checkout__btn checkout__save-btn"
                            onClick={handleNextStep}
                          >
                            Save Delivery Address
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>

              {/* Step 3: Order Summary */}
              <div className="checkout__step">
                <div className={`checkout__step-header`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">3</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Order Summary</h5></div>
                      {currentStep > 3 && (
                        <div className=' d-flex gap-3 mt-1'>
                          {state.totalQuantity} items
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 3 && <button className="checkout__btn" onClick={() => {
                    setCurrentStep(3)
                    // setRandomNumber(null)
                    // setGrandTotal(null)
                  }}>CHANGE</button>}
                </div>
                {currentStep === 3 && (
                  <div className="checkout__order-summary">
                    {
                      state.checkoutItems.map((item, index) => (
                        <CheckoutProductItem
                          key={index}
                          product={item}
                          onRemove={handleRemoveItem}
                          onIncrease={handleIncrease}
                          onDecrease={handleDecrease}
                        />
                      ))
                    }
                    <div className=" text-center">
                      <button
                        type="button"
                        className="checkout__btn"
                        onClick={handleNextStep}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* Step 4: Payment Methods */}
              <div className="checkout__step">
                <div className={`checkout__step-header `}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">4</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Payment Options</h5></div>
                    </div>
                  </div>
                </div>
                {currentStep === 4 && (
                  <div className="checkout__payment-methods">
                    {/* <h5 className='mb-4 text-center'>Choose payment gateway:</h5>
                    <div className='d-flex justify-content-around'>
                      <div className={`payment-gateway-select ${paymentGateway === 'stripe' ? 'selected' : ''}`} onClick={() => setPaymentGateway('stripe')}>
                        <img src='/images/stripe.webp' alt='Stripe' className='img-fluid' />
                        <h3>Stripe</h3>
                      </div>
                      <div className={`payment-gateway-select ${paymentGateway === 'paypal' ? 'selected' : ''}`} onClick={() => setPaymentGateway('paypal')}>
                        <img src='/images/paypal.webp' alt='PayPal' className='img-fluid' />
                        <h3>PayPal</h3>
                      </div>
                      <div className={`payment-gateway-select ${paymentGateway === 'nmi' ? 'selected' : ''}`} onClick={() => setPaymentGateway('nmi')}>
                        <img src='/images/nmi.png' alt='NMI' className='img-fluid' />
                        <h3>NMI</h3>
                      </div>
                    </div> */}
                    <div className='d-flex flex-column align-items-center mt-4 mb-3'>
                      <button
                        type="button"
                        className="checkout__btn checkout__save-btn"
                        onClick={handlePlaceOrder}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {isPaymentModalOpen && (
                <PaymentModal
                  paymentStatus={paymentStatus}
                  orderStatus={orderStatus}
                  setIsPaymentModalOpen={setIsPaymentModalOpen}
                  paymentGateway={paymentGateway}
                  grandTotal={roundToNearest ? roundedGrandTotal : grandTotal}
                  setPaymentStatus={setPaymentStatus}
                  shippingAddress={selectedAddress}
                  email={userDetails.email}
                  placedOrderDetails={placedOrderDetails}
                />
              )}

            </div>
            <div className="cart-summary col-lg-4 col-12">
              <h3>PRICE DETAILS</h3>
              <div className="summary-detail">
                <span>Price ({state.totalQuantity} items) :</span>
                <span>$ {state.totalPrice.toFixed(2)}</span>
              </div>
              <div className="summary-detail">
                <span>Shipping & Handling:</span>
                {shippingPrice === 0 ?
                  <span className="free">Free</span> :
                  shippingPrice === 'N/A' ?
                    <span>$ {shippingPrice}</span> :
                    <span>$ {shippingPrice.toFixed(2)}</span>
                }
              </div>
              {/* <div className="summary-detail">
                <span>Promotion Applied: </span>
                <span className="discount">-$ {state.totalDiscount}</span>
              </div> */}
              <hr />
              <div className="summary-detail">
                <span>Total: </span>
                <span>$ {totalPriceBeforeTax.toFixed(2)}</span>
              </div>
              {TAX_RATE !== 0 && <div className="summary-detail">
                <span>Tax</span>
                <span>$ {taxAmount.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && orderTotal && serviceFee && <div className="summary-detail">
                <span>Service Fee</span>
                <span>$ {serviceFee.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && <hr />}
              {TAX_RATE !== 0 && orderTotal && userDetails.isNewUser && <div className="summary-detail">
                <span>Order Total</span>
                <span>$ {orderTotal.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && orderTotal && userDetails.isNewUser && <div className="summary-detail">
                <span>New User Discount</span>
                <span>- $ {newUserDiscount.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && userDetails.isNewUser && <hr />}
              {TAX_RATE !== 0 && grandTotal && <div className="summary-detail">
                <span>Grand Total</span>
                <span>$ {roundToNearest ? roundedGrandTotal : grandTotal.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && randomNumber && <div className="summary-detail">
                <span className='free mt-3'>{randomNumber} % off towards your next purchase</span>
              </div>}
              {grandTotal && <div className=" d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={roundToNearest}
                  onChange={(e) => setRoundToNearest(e.target.checked)}
                />
                <label className='m-0'>Round up your cart to donate to our partner non-profit. <a href='http://aibcfoundation.org/donationterms'>Learn More</a></label>
              </div>}
              {TAX_RATE !== 0 && randomNumber === null && currentStep === 4 && <div className=' text-center my-3'>
                <button
                  type="button"
                  className="checkout__btn"
                  onClick={generateRandomNumber}
                >
                  Surprise Gift
                </button>
              </div>}
              {/* <div className="summary-detail savings mt-3">
                <span>You will save $ {(newUserDiscount + randomDiscountAmount).toFixed(2)} on this order</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CheckoutProductItem = ({ product, onRemove, onIncrease, onDecrease }) => {
  return (
    <div className="cart-item">
      <img src={product?.image?.startsWith('http') ? product.image : `${Asset_url}${product.image}`} alt={product.name} className="product-image" />
      <div className="cart-product-details">
        <h4>{product.name}</h4>
        {product.seller && product.seller.first_name && (
          <span>
            Seller: {product.seller.first_name || ''} {product.seller.last_name || ''}
          </span>
        )}
        {product.offer_price ?
          <div className="product-price">
            <span className="cart-current-price">MRP:</span>
            <span className="cart-mrp">$ {product.price}</span>
            <span className="cart-current-price">$ {product.offer_price}</span>
            {/* <span className="offers-applied">2 offers applied</span> */}
          </div> :
          <div className="product-price">
            <span className="cart-current-price">MRP: ${product.price}</span>
            {/* <span className="offers-applied">2 offers applied</span> */}
          </div>
        }
        {/* <div className="delivery-info d-flex gap-3">
          Delivery by Thu Jun 27 
        </div> */}
        <div className="product-actions">
          <div className="quantity-control">
            <button className="quantity-btn" onClick={() => onDecrease(product.id)}>-</button>
            <span className="quantity m-0">{product.quantity}</span>
            <button className="quantity-btn" onClick={() => onIncrease(product.id)}>+</button>
          </div>
          <button className="remove" onClick={() => onRemove(product.id)}>Remove</button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
