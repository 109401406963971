import axios from "axios"

export const ApiCall = async (url, payload,method = 'post') => {
    try {
        return await axios({
            method: method,
            url: url,
            data: payload
        });
    } catch (error) {
        return error;
    }
}

export const getProducts = async (url, payload = {}) => {
    try {
        return await axios({
            method: 'get',
            url: url,
            data: payload
        });
    } catch (error) {
        return error;
    }
}