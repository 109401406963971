import React from 'react';
import PDF from '../../../../img/pdfIcon.png'
import { REACT_BASE_PATH, Asset_url } from "../../../../api";


const VendorKYB = ({Vendordata, _handleKYBUpdate, handleModal}) => {
    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor KYB</h5>
                </div>
            </div>
            <div className='tableGap'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='custom-cart-panel mb-3'>
                            <div className="vendorProfile justify-content-between">
                                <h5 className='mb-3'>KYB Details {Vendordata?.kyb_status == 'approved' && <span className='badge'>Approved</span>}</h5>
                                {Vendordata?.kyb_status == 'pending' && <div className="vendorProfile">
                                    <button className="btn btn-theam mx-2" onClick={()=>_handleKYBUpdate('kyb', 'approved')}>
                                        <span>Approve</span>
                                    </button>
                                    <button className="btn btn-theam mx-2" onClick={()=>handleModal('kyc')}>
                                        <span>Reject</span>
                                    </button>
                                </div>}
                            </div>
                            <h6><span className='spanSpace'>Company Name:-</span>{Vendordata?.company_name}</h6>
                            <h6><span className='spanSpace'>Company Address:-</span>{Vendordata?.company_address}</h6>
                            <h6><span className='spanSpace'>Company TAX Id:- </span>{Vendordata?.company_ein_txid}</h6>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <h6>Incorporation Article</h6>
                                    <div className="vendorProfile">
                                        <div className=''>
                                        {Vendordata?.article_of_incorporation ? <a href={`${Asset_url}`+Vendordata?.article_of_incorporation} target='_blank'>
                                            <img src={PDF} alt='' style={{width:60, height:60, objectFit:'contain'}} /> </a> :'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <h6>Company Address Proof</h6>
                                    <div className="vendorProfile">
                                        <div className=''>
                                        {Vendordata?.company_address_proof ? <a href={`${Asset_url}`+Vendordata?.company_address_proof} target='_blank'>
                                            <img src={`${Asset_url}`+Vendordata?.company_address_proof} alt='' style={{width:60, height:60, objectFit:'contain'}} /></a> :'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <h6>Bank Statement</h6>
                                    <div className="vendorProfile">
                                        <div className=''>
                                        {Vendordata?.bank_statement ? <a href={`${Asset_url}`+Vendordata?.bank_statement} target='_blank'>
                                            <img src={PDF} alt='' style={{width:60, height:60, objectFit:'contain'}} /></a> :'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorKYB;