import React, {useEffect} from "react";
import FAQ from "./FAQ";
import "./faq.css";
import Header from "../../../componenth/header/Header";
import Footer from "../../../componenth/header/Footer";
// import('../WebPanelRoutes.css');

const FAQPage = () => {
  useEffect(()=>{
    import('../WebPanelRoutes.css');
  },[])
  return (
    <>
      <FAQ />
    </>
  );
};

export default FAQPage;
