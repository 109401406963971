import {useEffect, useState} from "react";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { ApiCall, getProducts } from "../../services";
import { _fetchProductData, _saveProductData } from "../../../../url";
import toast from "react-hot-toast";
function ProductCard({product,cateSecond,setError,existingProduct,setProducts}) {
//    console.log(data);
   
   const [inventory, setInventory] = useState(null);
  
   const handleSubmitForm = (e) => {
     e.preventDefault();
     
      if(!cateSecond) {
        toast.error("Please select category given above");
        setError(true);
        return;
      }

     const categoryName = cateSecond.split("#")[0];
     const categoryLevel = cateSecond.split("#")[1];
     const categoryObjectId = cateSecond.split("#")[2];

     const payload = {
      product_name : product?.product_title,
      brand_id : product?.product_id,
      price : product?.original_price,
      offer_price : product?.sale_price,
      vendor_id : product?.seller_id,
      product_total_stock : 60,
      product_banner_image: product?.product_main_image_url,
      product_gallery_image : product?.product_small_image_urls?.productSmallImageUrl,
      vendor_name: product?.seller_id,
      second_category_name: product?.second_level_category_name,
      first_category_name:product?.first_level_category_name,
      target_sale : product?.target_sale_price,
      discount : product?.discount,
      aliEx_product_id: product?.product_id,
      original_price:product?.original_price,
      currency : product?.original_price_currency,
      inventoryPrice : inventory,
      selectedCategory : categoryName,
     };

     if(categoryLevel === "0") {
        payload["category_id_level0"] = categoryObjectId
     } else if(categoryLevel === "1") {
        payload["category_id_level1"] = categoryObjectId
     } else {
        payload["category_id_level2"] = categoryObjectId
     }

     console.log("payload", payload);

      ApiCall(_saveProductData, payload)
      .then((res) => {
        const result = res.data;
        console.log("result", result);
        setInventory(null);
        setProducts(prev => [...prev.filter(d  => d.product_id !== payload?.brand_id)]);
        toast.success("Product imported successfylly");
        return true;
      })
      .catch(err => {
         console.error("api call error", err);
      })
   }

   return (
    <>
      <div class="card mb-3" style={{height : 'auto'}}>
        <div class="row g-0">
          <div class="col-md-4 p-2 d-flex justify-content-center align-items-center h-100">
             <div className="m-auto" style={{width : '250px', height : 'auto'}}>
               <img src={product?.product_main_image_url} className=" rounded-start" alt="..." width="80%"/>
             </div>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title text-primary">{product?.product_title}</h5>
              <h4 class="card-title">{product?.original_price} {product?.target_original_price_currency}</h4>
              <p class="card-text">
                <span class="text-muted">sellter-ID : {product?.seller_id}</span>
              </p>
             
             <form className="p-2 w-75 d-flex gap-2 justify-content-around align-items-center" onSubmit={handleSubmitForm}>
                <input value={inventory} onChange={e => setInventory(e.target.value)} type="number" class="form-control" id="exampleFormControlInput1" placeholder="Inventory Percentage %" />
                <Button type="submit" variant="contained" startIcon={<AddIcon />} disabled={!inventory ? true : false}>
                    Import
                </Button>
             </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
