import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
  } from "@mui/material";
  import React, { useState } from "react";
  import axios from "axios";
  import { toast } from "react-hot-toast";// Adjust the import based on your structure
import { getAffilateAccessToken, getAffilateUserId } from "../../../utills";
import { _complete_task } from "../../../url";
  
  function FileUploadModal({ open, onClose, editUser, setEditUser }) {
    const [isPending, setIsPending] = useState(false);
    const [file, setFile] = useState(null);
  
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
      }
    };
  
    const handleUpload = async () => {
      if (!file) {
        toast.error("Please select a file to upload");
        return;
      }
  
      const formData = new FormData();
      formData.append("taskId", editUser?._id);
      formData.append("document", file);
      formData.append("id", getAffilateUserId()); // Add other necessary data as needed
  
      setIsPending(true);
  
      try {
        const response = await axios.post(_complete_task, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${getAffilateAccessToken()}`
          },
        });
        console.log('File uploaded successfully:', response.data);
        toast.success("File uploaded successfully!");
        setFile(null); // Reset the file input
        setEditUser(null); // Reset the file input
        onClose(); // Close the dialog after successful upload
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error("Failed to upload the file. Please try again.");
      } finally {
        setIsPending(false);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        {/* Uncomment if you want to show a loading indicator */}
        {isPending && <LinearProgress color="secondary" />}
        <DialogTitle>Upload Image</DialogTitle>
        <DialogContent>
          <label htmlFor="images" className="drop-container" id="dropcontainer">
            <span className="drop-title">Click to upload</span>
            or
            <input
              type="file"
              id="images"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="secondary" disabled={isPending}>
            {isPending ? "Uploading..." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default FileUploadModal;
  