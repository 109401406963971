import React from 'react';
import { NavLink } from 'react-router-dom';

const HeroSection = ({heroSectionItems, loginPage}) => {
  return (
    <section className="hero-section">
      <div className='container'>
      <div className='hero-section-items'>
        <div className="hero-text">
          <h5>{heroSectionItems.title}</h5>
          <h1>{heroSectionItems.subtitle}</h1>
          <p>{heroSectionItems.content}</p>
          <span>{heroSectionItems.content2}</span><br/>
          {loginPage && <NavLink to={loginPage} className="btn btn-primary seller-button mt-3" style={{width: 'fit-content', border: 'none'}}>Login / Signup</NavLink>}
        </div>
        <div className="hero-image">
          <img src={heroSectionItems.image} className='img-fluid' alt="hero-image" />
        </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
