import React, { createContext, useReducer } from 'react';

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('checkoutState');
        return serializedState ? JSON.parse(serializedState) : undefined;
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
};

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('checkoutState', serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
};

const initialState = loadState() || {
    checkoutItems: [],
    totalPrice: 0,
    totalQuantity: 0,
    totalDiscount: 0,
};

const CheckoutContext = createContext();

const calculateTotals = (checkoutItems) => {
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalQuantity = 0;

    checkoutItems.forEach(item => {
        if (item.offer_price) {
            totalPrice += item.offer_price * item.quantity;
            totalDiscount += (item.price - item.offer_price) * item.quantity;
            totalQuantity += item.quantity;
        } else {
            totalPrice += item.price * item.quantity;
            totalQuantity += item.quantity;
        }
    });

    return { totalPrice, totalDiscount, totalQuantity };
};

const checkoutReducer = (state, action) => {
    let newCheckoutItems = [];

    switch (action.type) {
        case 'ADD_TO_CHECKOUT':
            newCheckoutItems = [...state.checkoutItems, ...action.payload];
            break;
        case 'REMOVE_CHECKOUT_ITEM':
            newCheckoutItems = state.checkoutItems.filter(item => item.id !== action.payload.id);
            break;
        case 'CLEAR_CHECKOUT_ITEMS':
            newCheckoutItems = [];
            localStorage.removeItem('checkoutState');
            break;
        case 'INCREASE_QUANTITY':
            newCheckoutItems = state.checkoutItems.map(item =>
                item.id === action.payload.id && item.quantity < item.productMaxStockQuantity
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            );
            break;

        case 'DECREASE_QUANTITY':
            newCheckoutItems = state.checkoutItems.map(item =>
                item.id === action.payload.id && item.quantity > 1
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            );
            break;

        default:
            return state;
    }

    const { totalPrice, totalDiscount, totalQuantity } = calculateTotals(newCheckoutItems);

    const newState = {
        ...state,
        checkoutItems: newCheckoutItems,
        totalPrice,
        totalDiscount,
        totalQuantity,
    };

    if (action.type !== 'CLEAR_CHECKOUT_ITEMS') {
        saveState(newState);
    }

    return newState;
};



const CheckoutProvider = ({ children }) => {
    const [state, dispatch] = useReducer(checkoutReducer, initialState);

    return (
        <CheckoutContext.Provider value={{ state, dispatch }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export { CheckoutContext, CheckoutProvider };
