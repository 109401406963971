/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "./Pagination";
import UpdateProductModal from "./UpdateProductModal";
import { getAdminId } from "../../../../utills";
import { _fetchUsers } from "../../../../url";
import axios from "axios";
import toast from "react-hot-toast";
import DeleteConfirmationDialog from "./DeleteUserAskModal";
import { useNavigate } from "react-router-dom";

function AffilateUser() {
  const [page, setPage] = useState(10);

  // modal state
  const [open, setOpen] = React.useState(false);
  const [openConfirmModal, setopenConfirmModal] = React.useState(false);
  
  const [users, setUser] = useState([]);

  // loading states
  const [isPending, setisPending] =  useState(false);
  const [isFetching, setIsFetching] =  useState(false);

 

  const [reMount, setRemount] = useState(false);
  const  [editUser, setEditUser] = useState(null);

  // pagination state
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
   
  const dropdownListVendor = ['Profile', 'Accounts', 'Products']

  const navigate = useNavigate();

  useEffect(() => {
    const fetUsers = async () => {
      setIsFetching(true);
      try {
        const response = await axios({
           method : 'post',
           url : _fetchUsers,
           data : {
            id: getAdminId(),
            page : currentPage,
            pagesize : selectedOption
           }
        })// Replace with actual URL
        // console.log(response.data);
        const {success , data} = response.data;
        if(success && data) {
          setUser(data);
          setTotalItems(data?.length)
          setIsFetching(false); // Assuming response.data contains the category list
        }
      } catch (error) {
        toast.error("Failed to fetch users");
        console.error("Error fetching categories:", error);
        setIsFetching(false);
      }
    };
    fetUsers();
  },[currentPage, selectedOption, reMount]);

  // handler for delte user 
  const handleDeleteUser = async (user) => {
    setopenConfirmModal(true);
    setEditUser(user)
  }
  

  const handleClickOpen = (data) => {
    setOpen(true);
    setEditUser(data)
  };

  const handleClose = () => {
    setOpen(false);
  }; 

  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    // setCurrentPage(1);
  };

  const navigateVendorDetails = (data, id) => {
    navigate('../affilateUserDetails', {state: { activeTab: data, id: id } })
  }

  return (
    <> 
      {isPending && <LinearProgress color="secondary" /> }
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Affiliate Details</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                <button className="btn btn-theam mx-2" type="button">
                  <span>Export CSV</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="mlr-10 mb-4">
          {isFetching ? (
            "Fetching users..."
          ) : (
            <table className="tableGap">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>User Name</th>
                  <th>Email Id</th>
                  <th>Phone Number</th>
                  <th>Earnings</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((data, index) => {
                   return data?.status
                    && (
                    <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>{data?.name}</td>
                    <td>{data?.email}</td>
                    <td>{data?.phone_number}</td>
                    <td>${data?.walletAmount}</td>
                    <td className="d-flex items-center">
                        <div class="btn-group dropleft">
                              <button className="edit-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-eye"></i>
                              </button>
                              <ul class="dropdown-menu">
                                {dropdownListVendor.map((option, index) => (
                                  <li className="text-capitalize" key={option} onClick={() => navigateVendorDetails(index + 1, data._id)}>
                                    <a className="dropdown-link" href='javascript:void(0)'>{option}</a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                      <button
                        className="edit-btn"
                        onClick={() => handleClickOpen(data)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                     
                      <button className="trash-icon disabledBtn" onClick={() => handleDeleteUser(data)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                   )
                })}
              </tbody>
            </table>
          )}
        </div>

        <Pagination
         selectedOption={selectedOption}
         currentPage={currentPage}
         setCurrentPage={setCurrentPage}
         totalItems={totalItems}
         setTotalItems={setTotalItems}
        />


        <UpdateProductModal 
          open={open}
          handleClose={handleClose}
          title='Edit User'
          isPending={isPending}
          setisPending={setisPending}
          editUser={editUser}
          setRemount={setRemount}
        />
         <DeleteConfirmationDialog 
          open={openConfirmModal}
          onClose={() => setopenConfirmModal(false)}
          editUser={editUser}
          setRemount={setRemount}
          title="Affiliate User"
        />
      </section>
    </>
  );
}

export default AffilateUser;
