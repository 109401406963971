import React, { useState, useCallback, useEffect } from 'react';
import Pagination from "../Pagination";
import productImage from '../../img/firstlbg.jpg'
import { useNavigate } from "react-router-dom";
import { REACT_BASE_PATH, Asset_url } from '../../../../api';
import SnackBar from "../../helper/SnackBar";
import { deleteProductMessage } from '../ResponseMessage'
import { formatDate, formatDateTime } from "../../helper/DateTimeFormat";
import axios from 'axios';
import { _getAllShareLink } from '../../../../url';
import { getAffialteToken, getAffilateAccessToken } from '../../../../utills';

const VendorProducts = ({ Vendordata }) => {
    const [selectedOption, setSelectedOption] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false);
    const [openTost, setopenTost] = useState(false);
    const [tostMessage, setostMessage] = useState('');
    const navigate = useNavigate();


    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                _getAllShareLink(Vendordata?._id),
                {
                  headers : {
                     'Authorization' : `Bearer ${getAffialteToken()}`
                  }
                }
            );
            setData(response.data.data);
            setTotalItems(response.data.totalItems); // Update total items
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [Vendordata?._id]);

    useEffect(() => {
        if(Vendordata?._id) fetchData();
    }, [Vendordata,fetchData]);

    const totalPages = Math.ceil(totalItems / selectedOption);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const editClick = (editData) => {
        navigate('../editproduct/', { state: editData })
    }

    const deleteProductList = async (id) => {
        try {
            const response = await axios.post(
                `${REACT_BASE_PATH}/product/delete/productDelete`,
                {
                    product_id: id,
                }
            );
            setopenTost(true);
            setType('success');
            setostMessage(deleteProductMessage);
            fetchData()
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Product List</h5>
                </div>
            </div>
            <div className='tableGap'>
                <table>
                    <thead>
                        <tr>
                            <th>Sr.</th>
                            <th>Generated Link</th>
                            <th>Shared With</th>
                            <th>Product</th>
                            <th>Category</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                             data.length === 0 && <td className="p-3">No Products shared data found..</td>
                        }
                        {data.length > 0 && data.map((item, index) => {
                            return (
                                <tr key={item._id + index}>
                                       <td>
                                         {index+1}
                                       </td>
                                    <td>
                                      {item?.affliliateLink}
                                    </td>
                                    <td>{item?.shared_by_user?.name}</td>
                                    <td>{item?.product_id?.product_name}</td>
                                    <td>
                                        {item?.categoryId?.category_name}
                                    </td>
                                    <td>{new Date(item?.createdAt)?.toLocaleDateString()}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='row'>
                <div className="col-sm-12 col-md-6">
                    {data.length > 0 && <div className="dataTables_paginate">
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>}
                </div>
            </div>
        </>
    )
}

export default VendorProducts;