import React, { useEffect } from "react";
import { Menu } from "@mui/icons-material";
import Icon from "../icon/notif.png";
import profile from "../icon/pro-pc.png";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const Header = ({ collapsed, onToggle }) => {
  useEffect(() => {
    import('../AdminPanelRoutes.css');
  }, [])
  let navigate = useNavigate()

  const logOut = () => {
    // localStorage.removeItem('user')
    localStorage.removeItem('vendor')
    localStorage.removeItem('step_completed')
    navigate('/vendorlogin')
  }

  // const _handleStripe = () => {
  //   const stripeConnectUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id={YOUR_CLIENT_ID}&scope=read_write`;
  //    window.location.href = stripeConnectUrl;

  // }

  return (
    <header className="header">
      <div className="row">
        <div className="col-lg-2 box-h1">
          <button className="header-toggle-button" onClick={onToggle}>
            <Menu />
          </button>
          <span className="dash-title">Krizaar</span>
        </div>
        <div className="col-lg-4 mt-2 box-h2 ps-5">
          <form>
            <input
              type="email"
              id="emailInput"
              placeholder="Search.."
              required
              className="dash-input"
            />
            <button type="submit" className="sdash-icon">
              <SearchIcon />
            </button>
          </form>
        </div>
        <div className="col-lg-6 pro-box" >
          {/* <button className="pro-pic" onClick={_handleStripe}>
            <span>Stripe Account</span>
          </button> */}
          <div className="pro-pic">
            <i className="fa fa-bell"></i>
          </div>
          <div class="btn-group dropleft">
            <button className="pro-pic" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-user-alt"></i>
            </button>
            <ul class="dropdown-menu" data-proper-placement={'right-start'} style={{ minWidth: 150 }}>
              <li>
                <a className="dropdown-link" href="/admin/profile">My Profile</a>
              </li>
              {/* <li>
                <a className="dropdown-link" href="/admin/profile">Edit Profile</a>
              </li> */}
              <li>
                <a className="dropdown-link" href="/admin/changePassword">Change Password</a>
              </li>
              <li>
                <a onClick={logOut} className="dropdown-link" href="javascript:void(0)">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
