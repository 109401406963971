import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Krizaar Privacy Policy</h1>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div style={{ textAlign: 'justify' }}>At Krizaar, we value your privacy and are committed to using and sharing your personal information responsibly. This Privacy Notice explains how Krizaar and its affiliates gather and manage your data when you use Krizaar services, including websites, apps, and stores. By using our services, you agree to the practices outlined here.</div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>What Personal Information Does Krizaar Collect?</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>We collect information to improve our services. The data we gather includes:
                      <ol>
                        <li><b>•	Information You Provide:</b> We collect any information you give us, such as your name, address, and payment details, to offer our services. Without some of this information, you may not be able to fully use our services.</li>
                        <li><b>•	Automatically Collected Information:</b> Krizaar automatically gathers details about your interactions with our services, such as your browsing habits and device details. We use cookies and similar technologies to collect this data. Our physical stores may also use technology like cameras and sensors to monitor customer activity.</li>
                        <li><b>•	Information from Other Sources:</b> We may receive updated details from third parties, like carriers providing address updates, to improve deliveries.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How Does Krizaar Use Your Personal Information?</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>We use your data to run and enhance our services, including:
                      <ol>
                        <li><b>•	Product and Service Delivery:</b> To process orders, deliver items, and manage payments.</li>
                        <li><b>•	Service Improvement:</b> We analyze your data to fix issues, enhance performance, and make the platform easier to use.</li>
                        <li><b>•	Personalization:</b> Your information helps us suggest relevant features, products, and services.</li>
                        <li><b>•	Communication:</b> We use your data to stay in touch, including updates on orders and promotions.</li>
                        <li><b>•	Legal Compliance:</b> In some cases, we collect data to comply with legal obligations.</li>
                        <li><b>•	Advertising:</b> We may show you interest-based ads, but we do not use personally identifiable information for this purpose.</li>
                        <li><b>•	Fraud Prevention and Credit Risk Management:</b> Your data helps us detect fraud and manage credit risks.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Cookies and Other Identifiers</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>We use cookies and other identifiers to recognize your browser and improve your experience on Krizaar. For more information, you can review our Cookies Notice.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Does Krizaar Share Your Information?</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>We do not sell your personal information. However, we share it with trusted partners in certain situations, including:
                      <ol>
                        <li><b>•	Third-Party Transactions:</b> If you buy products from third parties via Krizaar, your information may be shared with them.</li>
                        <li><b>•	Service Providers:</b> We work with companies to fulfill orders, deliver products, and provide customer support. These third parties only access information necessary to perform their tasks.</li>
                        <li><b>•	Business Transfers:</b> If Krizaar is involved in a merger or sale, your information may be part of the transferred assets.</li>
                        <li><b>•	Legal Protection:</b> We may share data to comply with laws, protect Krizaar, and prevent fraud.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Data Security</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>We prioritize the security of your data by using encryption and secure handling procedures. We also encourage you to use strong passwords and secure devices to protect your account.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Advertising</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Krizaar includes third-party advertisements and may provide advertisers with non-identifiable data to show relevant ads. You can adjust your ad preferences in your account settings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Your Data Access and Choices</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>You can access and update your personal information, manage your communication preferences, and control advertising settings through your account. If you prefer not to share certain information, you may limit your use of some Krizaar services.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Children’s Privacy</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>Krizaar does not sell products for children to purchase directly. If you are under 18, you may only use Krizaar with a parent or guardian’s involvement. We do not knowingly collect data from children under 13 without parental consent.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Conditions of Use and Revisions</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}><p>
                    By using Krizaar, you agree to this Privacy Notice and our Conditions of Use. We may update our privacy practices as our business evolves, but we will never reduce the protection of your data without your consent.
                    </p>
                    <p>
                    For more information about your privacy rights or to make requests about your data, you can contact Krizaar’s customer service or access your account settings.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
