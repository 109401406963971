import React from 'react';
import { FaUser, FaShoppingCart, FaSearch, FaUserAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-black custom-navbar">
      <div className="container-fluid">
        <NavLink to="/">
          <video autoPlay loop muted className="logo">
            <source src="/logo3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-1 ">
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/affiliate_login">Become an Affiliate</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/vendorlogin">Become a Seller</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/krizadrops">Become a Dropshipper</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/contactus">Contact Us</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
