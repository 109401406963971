import React from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { REACT_BASE_PATH } from "../../api";
import { toast } from 'react-toastify';

const DeleteYourAccount = () => {

  const loginUserToken = Cookies.get('krizaar-user');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate()

  // if(!userId || !loginUserToken) {
  //   toast.error('You are not logged in. Please log in.')
  //   navigate('/signin')
  //   return;
  // }
  
  
  const deleteAccount = () => {
    if(!userId || !loginUserToken) {
      toast.error('You are not logged in. Please log in.')
      return;
    }
    try{
      const res = axios.post(`${REACT_BASE_PATH}/auth/user/registration`,{
        user: {
          id: userId
        }
      })
      if(res){
        Cookies.remove('krizaar-user');
        localStorage.removeItem('userId');
        navigate('/signin')
        toast.success('Account deleted successfully')
        return;
      }
    }catch(e){
      console.error(e)
      toast.error('Failed to delete account')
      return;
    }
  }

  return (
    <>
      <div className="container">
        <div className=' text-center'>
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Delete Your Account</h1>
            <div className='checkout__step-header flex-column align-items-center justify-content-center gap-4' style={{ cursor: 'auto' }} >
              <h5>Are you sure want to delete your account?</h5>
              <div className=' d-flex gap-5'>
                <button className='checkout__btn checkout__save-btn' onClick={deleteAccount}>Confirm</button>
                <button className='checkout__btn checkout__save-btn' onClick={() => {
                  navigate(-1)
                }}>Go Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteYourAccount
