import React, { useContext, useEffect, useMemo, useState } from 'react';
import Header from '../../../componenth/header/Header';
import Footer from '../../../componenth/header/Footer';
import { CartContext } from './CartContext';
import Cookies from 'js-cookie';
import axios from 'axios';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { useNavigate } from 'react-router-dom';
import { REACT_BASE_PATH, Asset_url } from "../../../api";
import { toast } from 'react-toastify';

const Cart = () => {
    const { state: cartState, dispatch: cartDispatch } = useContext(CartContext);
    const { state: checkoutState, dispatch: checkoutDispatch } = useContext(CheckoutContext);
    const [shippingPrice, setShippingPrice] = useState(0)

    const navigate = useNavigate();


    const handleIncrease = (id) => {
        cartDispatch({ type: 'INCREASE_QUANTITY', payload: { id } });
    };

    const handleDecrease = (id) => {
        const itemToDecrease = cartState.cart.find(item => item.id === id);

        if (itemToDecrease && itemToDecrease.quantity > 1) {
            const itemPrice = itemToDecrease.offer_price || itemToDecrease.price;
            const newTotalPrice = cartState.totalPrice - itemPrice;

            if (newTotalPrice < 35) {
                toast.error('Minimum cart amount should be $35. You cannot decrease the quantity further.');
            } else {
                cartDispatch({ type: 'DECREASE_QUANTITY', payload: { id } });
            }
        }
    };

    useEffect(() => {
        if (cartState.totalPrice < 35) {
            toast.warning("The minimum cart value should be $35, Please add more items");
            setShippingPrice('N/A')
        } else if (cartState.totalPrice >= 35 && cartState.totalPrice <= 99.99) {
            setShippingPrice(cartState.totalPrice * 0.25);
        } else if (cartState.totalPrice >= 100) {
            setShippingPrice(cartState.totalPrice * 0.20);
        }
    }, [cartState.totalPrice])

    const removeFromCart = async (productId) => {
        const userId = localStorage.getItem("userId");
        const accessToken = Cookies.get('krizaar-user');

        if (!accessToken || !userId) {
            cartDispatch({ type: 'REMOVE_FROM_CART', payload: { id: productId } });
            toast.success("Product removed from the cart!");
        } else {
            try {
                const response = await axios.post(
                    `${REACT_BASE_PATH}/cart/user/remove/product/cart`,
                    {
                        id: userId,
                        product_id: productId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    cartDispatch({ type: 'REMOVE_FROM_CART', payload: { id: productId } });
                    toast.success("Product removed from the cart!");
                } else {
                    toast.error("Failed to add product to the cart");
                }
            } catch (error) {
                // console.error("Error adding product to the cart:", error);
                toast.error(error.response?.data?.message || "Some error occured, please try again");
            }
        }
    }


    // console.log(cartState.cart)
    // Calculate totals and tax
    const totalPriceBeforeTax = useMemo(() => cartState.totalPrice + (shippingPrice === 'N/A' ? 0 : shippingPrice), [cartState.totalPrice, shippingPrice]);
    // const taxAmount = useMemo(() => totalPriceBeforeTax * TAX_RATE, [totalPriceBeforeTax]);
    // const orderTotal = useMemo(() => totalPriceBeforeTax + taxAmount, [totalPriceBeforeTax, taxAmount]);

    const handleCheckout = async () => {

        if (totalPriceBeforeTax < 35) {
            toast.error("The minimum cart amount should be $35");
            return;
        }

        const userId = localStorage.getItem("userId");
        const accessToken = Cookies.get('krizaar-user');

        if (!accessToken || !userId) {
            toast.warning("You need to be logged in to proceed with the purchase.");
            navigate("/signin");
            return;
        }

        // console.log(cartState.cart)
        checkoutDispatch({ type: 'CLEAR_CHECKOUT_ITEMS' });
        checkoutDispatch({ type: 'ADD_TO_CHECKOUT', payload: cartState.cart });

        navigate("/checkout");
    };


    return (
        <>
            <div className='container'>
                <div className="cart">
                    <div className="cart-header">
                        <span>Home &gt; Cart</span>
                        <input type="text" placeholder="Search Orders" className="cart-search" />
                    </div>
                    <div className='cart-body row m-0'>
                        <div className="cart-items col-lg-8 col-12">
                            {
                                cartState.cart.length > 0 ? cartState.cart.map((product) => (
                                    <CartItem
                                        key={product.id}
                                        product={product}
                                        onRemove={removeFromCart}
                                        onIncrease={handleIncrease}
                                        onDecrease={handleDecrease}
                                    />
                                )) : <h2>No items in your cart</h2>
                            }
                        </div>
                        <div className="cart-summary col-lg-4 col-12">
                            <h3>PRICE DETAILS</h3>
                            <div className="summary-detail">
                                <span>Price ({cartState.totalQuantity} items) :</span>
                                <span>$ {cartState.totalPrice.toFixed(2)}</span>
                            </div>
                            <div className="summary-detail">
                                <span>Shipping & Handling:</span>
                                {shippingPrice === 0 ?
                                    <span className="free">Free</span> :
                                    shippingPrice === 'N/A' ?
                                        <span>$ {shippingPrice}</span> :
                                        <span>$ {shippingPrice.toFixed(2)}</span>
                                }
                            </div>
                            {/* <div className="summary-detail">
                                <span>Promotion Applied: </span>
                                <span className="discount">-$ {cartState.totalDiscount}</span>
                            </div> */}
                            <hr />
                            <div className="summary-detail">
                                <span>Cart Total: </span>
                                {shippingPrice === 'N/A' ?
                                    <span>N/A</span> :
                                    <span>$ {totalPriceBeforeTax.toFixed(2)}</span>
                                }
                            </div>
                            <div className="summary-detail savings">
                                <span>You will save $ {cartState.totalDiscount} on this order</span>
                            </div>
                            <div className="checkout-button">
                                <button className="btn" onClick={handleCheckout}>Proceed to Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const CartItem = ({ product, onRemove, onIncrease, onDecrease }) => {
    const navigate = useNavigate()
    return (
        <div className="cart-item">
            <img onClick={() => navigate(`/singleproduct/${product.id}`)} src={product?.image?.startsWith('http') ? product.image : `${Asset_url}${product.image}`} alt={product.name} className="product-image" />
            <div className="cart-product-details">
                <h4 onClick={() => navigate(`/singleproduct/${product.id}`)}>{product.name}</h4>
                {product.seller && product.seller.first_name && (
                    <span>
                        Seller: {product.seller.first_name || ''} {product.seller.last_name || ''}
                    </span>
                )}
                {product.offer_price ?
                    <div className="product-price">
                        <span className="cart-current-price">MRP:</span>
                        <span className="cart-mrp">$ {product.price}</span>
                        <span className="cart-current-price">$ {product.offer_price}</span>
                        {/* <span className="offers-applied">2 offers applied</span> */}
                    </div> :
                    <div className="product-price">
                        <span className="cart-current-price">MRP: ${product.price}</span>
                        {/* <span className="offers-applied">2 offers applied</span> */}
                    </div>
                }
                {/* <div className="delivery-info d-flex gap-3">
                    Delivery by Thu Jun 27 | <span className='text-decoration-line-through'>$ 40</span> <span className="free">Free</span>
                </div> */}
                <div className="product-actions">
                    <div className="quantity-control">
                        <button className="quantity-btn" onClick={() => onDecrease(product.id)}>-</button>
                        <span className="quantity m-0">{product.quantity}</span>
                        <button className="quantity-btn" onClick={() => onIncrease(product.id)}>+</button>
                    </div>
                    <div className='later-remove-buttons'>
                        <button className="save-later">Save to later</button>
                        <button className="remove" onClick={() => onRemove(product.id)}>Remove</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
