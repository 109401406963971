import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { REACT_BASE_PATH } from "../../../api";

const MeasurementParameterDropdown = ({ onParameterChange, index, param, setUnits, attriUnit, Added, UnitAll }) => {
  const [parameters, setParameters] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [AttributeData, setAttributeData] = useState([])

  useEffect(() => {
    setSelectedParameter(attriUnit)
  }, [attriUnit])

  const fetchParameters = async () => {
    try {
      const response = await axios.get(
        `${REACT_BASE_PATH}/admin/attribute/getAllAttributes`
      );
      if (response.data.status === 200) {
        setAttributeData(response.data.data)
        if (UnitAll) {
          const filteredUnits = response.data.data.filter((option) =>
            option._id.includes(attriUnit.value)
          );
          setUnits(filteredUnits[0].units)
        }
        let parameterOptions = [];
        response.data.data.map((parameter) => {
          parameterOptions.push({
            value: parameter._id,
            label: parameter.attribute_name,
          })
        });
        setParameters(parameterOptions);
      }
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  useEffect(() => {
    fetchParameters()
  }, [Added,UnitAll])

  const handleChange = (selectedOption) => {
    const filteredUnits = AttributeData.filter((option) =>
      option._id.includes(selectedOption.value)
    );
    setUnits(filteredUnits[0].units)
    setSelectedParameter(selectedOption);
    onParameterChange(index, param, selectedOption); // Call the parent callback function
  };

  return (
    <div>
      <Select
        options={parameters}
        value={selectedParameter}
        onChange={handleChange}
        placeholder="Search and select a Attribute"
        isSearchable
      />
      {/* {selectedParameter && (
        <div>
          <p>Selected Attribute: {selectedParameter.label}</p>
        </div>
      )} */}
    </div>
  );
};

export default MeasurementParameterDropdown;
