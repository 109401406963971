import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import { formatDateTime } from "../../helper/DateTimeFormat";
import { Switch } from "@material-ui/core";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AllOrders = () => {
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([])
    const [totalItems, setTotalItems] = useState(0);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const totalPages = Math.ceil(totalItems / selectedOption);
    const [offerAddType, setofferAddType] = useState('Add');
    const [dropShow, setdropShow] = useState(false)
    const open = Boolean(dropShow);

    const fetchOffers = useCallback(async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/tax/getAllOffers`, {
                "page": currentPage,
                "pagesize": selectedOption

            });
            if (response.data.success) {
                setdata(response.data.data.reverse());
                setLoading(false)
                setTotalItems(response.data.totalItems)
            } else {
                setdata([])
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }, [currentPage, selectedOption]);

    const handleChange = (event) => {
        const value = parseInt(event.target.value);
        setSelectedOption(value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchOffers()
    }, [fetchOffers])

    const _handleStatus = async (id, status) => {
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/updateOrderStatus`, {
                "orderId": id,
                "status": status
            });
            if (response.data.status === 200) {
                setdropShow(!dropShow)
                fetchOffers()
            } else {
                console.log('error');

            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }

    }

    const dropdownClick = (item, index) => {
        setdropShow(false)
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <section id="admin-main-content" >
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">New User Offer</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
                            <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <label>
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search offers"
                                            aria-controls="DataTables_Table_0"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
                                <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                                    <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                                        <label>
                                            <select
                                                name="DataTables_Table_0_length"
                                                aria-controls="DataTables_Table_0"
                                                className="form-select"
                                                id="entries"
                                                value={selectedOption}
                                                onChange={handleChange}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="70">70</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="mb-6">
                                        {/*<button className="btn btn-theam mx-2" type="button">
                                            <span>Export CSV</span>
                                        </button> */}
                                        <button className="btn btn-theam mx-2" type="button">
                                            <span>Add Offer</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="mlr-10 mb-4">
                                <table className="tableGap">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Country</th>
                                            <th>Min Offer Price</th>
                                            <th>Offer (%)</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 && data.map((items, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>{items?.location}</td>
                                                    <td>{items.min_order_value}</td>
                                                    <td>{items.offer_amount}</td>
                                                    {/* <td>{items.status}</td> */}
                                                    <td><Switch {...label} checked={items.status == 'active' ? true : false} /></td>
                                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                                        <button className="edit-btn">
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button className="trash-icon disabledBtn">
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-6 mt-2">
                            <div className="dataTables_info">
                                Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="dataTables_paginate">
                                <ul className="pagination">
                                    <li
                                        className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : ""}`}
                                    >
                                        <span
                                            className="page-link"
                                            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                        >
                                            <i className="fa fa-chevron-left bx-18px"></i>
                                        </span>
                                    </li>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <li
                                            key={index}
                                            className={`paginate_button page-item ${currentPage === index + 1 ? "active" : ""}`}
                                        >
                                            <span
                                                className="page-link"
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </span>
                                        </li>
                                    ))}
                                    <li
                                        className={`paginate_button page-item next ${currentPage === totalPages ? "disabled" : ""}`}
                                    >
                                        <span
                                            className="page-link"
                                            onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                        >
                                            <i className="fa fa-chevron-right bx-18px"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header border-bottom mb-4">
                        <h5 id="offcanvasRightLabel" className="text-capatlise">{offerAddType} Offer</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div class="mb-3">
                            <label for="attributeExample" class="form-label">Country<span className="required">*</span></label>
                            <CountrySelect
                                value={countryid}
                                onChange={(e) => {
                                    setcountryError(false)
                                    setCountryid(e);
                                }}
                                placeHolder="Select Country"
                            />
                            <span className="error">{countryError && 'Country field is required'}</span>
                        </div>
                        <div className="mb-3">
                            <label for="forCategory" class="form-label">State<span className="required">*</span></label>
                            <StateSelect
                                countryid={countryid.id}
                                onChange={(e) => {
                                    setstateError(false)
                                    setstateid(e);
                                }}
                                placeHolder="Select State"
                            />
                            <span className="error">{stateError && 'State field is required'}</span>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Tax Rate <span className="required">*</span></label>
                            <input type="text" class="form-control" onChange={(e) => { settaxerror(false); settaxRate(e.target.value) }} id="attributeExample" placeholder="tax rate" value={taxRate} />
                            <span className="error">{taxerror && 'Tax field is required'}</span>
                        </div>
                    </div>
                    <div class="offcanvas-footer border-top pt-4">
                        <div className=" mb-4 d-flex justify-content-end mx-4">
                            <button className="btn btn-secondary px-5 mx-2" onClick={addAttribute}
                                data-bs-dismiss={validate && "offcanvas"} type="button">{offerAddType == 'Add' ? 'Add' : 'Update'} Tax</button>
                            <button className="btn btn-theam px-5" type="button" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}

export default AllOrders;