import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import user from "../../img/Userpic.png";
import { FaExchangeAlt, FaHeart, FaPlus, FaRegHeart, FaShoppingCart } from "react-icons/fa";
import Cookies from 'js-cookie'
import { CartContext } from "./cart/CartContext";
import { CheckoutContext } from "./checkout/CheckoutContext";
import AddNewReviewModal from "./AddNewReviewModal";
import { REACT_BASE_PATH, Asset_url } from "../../api";
import { useCookies } from 'react-cookie';
import { toast } from "react-toastify";

const SingleProduct = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState(null);
  const [wishlist, setWishlist] = useState(new Set());
  const [currentProductImage, setCurrentProductImage] = useState('')
  const [productImages, setproductImages] = useState([])
  const [productMeasurements, setProductMeasurements] = useState([]);
  const [selectedMeasurements, setSelectedMeasurements] = useState({});
  const [productStockQuantity, setProductStockQuantity] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [productReviews, setProductReviews] = useState([])
  const [recentlyWatched, setRecentlyWatched] = useState([])
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const [isAddNewReviewModalOpen, setIsAddNewReviewModalOpen] = useState(false);
  

  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get('krizaar-user');

  const [cookies, setCookie] = useCookies(['affilate_code']);  

  const [params] = useSearchParams();
  const affilate_code = params.get('code');
  
  // set affiliate code into cookie
  useEffect(() => {
    if(affilate_code) {
       setCookie('affilate_code', affilate_code);
    }
  },[affilate_code, setCookie])
  
  // console.log("params",params.get('code'))

  const handleAddToWishlist = async () => {
    if (!accessToken || !userId) {
      toast.error("You need to be logged in to add products to your wishlist.");
      navigate("/signin");
      return;
    }

    if (wishlist.has(productId)) {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [productId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          toast.success("Product removed from wishlist successfully!");
          setWishlist((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return new Set(newWishlist);
          });
          return;
        } else {
          toast.error("Failed to remove product from wishlist.");
          return;
        }
      } catch (error) {
        // console.error("Error removing product from wishlist:", error);
        toast.error(error.response?.data?.message || "Some error occured, please try again");
        return;
      }
    }

    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/product/wishlist/add`,
        {
          userId: userId,
          productId: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Product added to wishlist successfully!");
        setWishlist((prevWishlist) => new Set(prevWishlist).add(productId));
      } else {
        toast.error("Failed to add product to wishlist.");
      }
    } catch (error) {
      // console.error("Error adding product to wishlist:", error);
      toast.error(error.response?.data?.message || "Some error occured, please try again");

    }
  };

  const { state: cartState, dispatch: cartDispatch } = useContext(CartContext);
  const { state: checkoutState, dispatch: checkoutDispatch } = useContext(CheckoutContext);

  const handleAddToCart = async (product) => {
    // console.log(Object.keys(selectedMeasurements).length)
    const isSingleAttributeNull = product_measurment_parameter_id.length === 1 && !product_measurment_parameter_id[0].attribute_id;

    if (!isSingleAttributeNull) {
        if (Object.keys(selectedMeasurements).length !== product_measurment_parameter_id.length) {
            toast.warning("Please select all measurements for the product.");
            return;
        }
    }
    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: selectedQuantity,
      productMaxStockQuantity: productStockQuantity,
      measurements: selectedMeasurements,
      isProductFromAliExpress: product.isProductFromAliExpress
    }

    // console.log(productInfo)

    const isItemAlreadyInCart = cartState.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      toast.warning("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      cartDispatch({ type: 'ADD_TO_CART', payload: productInfo });
      toast.success("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
            quantity: selectedQuantity
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          // console.log(response.data)
          cartDispatch({ type: 'ADD_TO_CART', payload: productInfo });
          toast.success("Product added to the cart!");
        } else {
          toast.error("Failed to add product to the cart");
        }
      } catch (error) {
        // console.error("Error adding product to the cart:", error);
        toast.error(error.response?.data?.message || "Some error occured, please try again");
      }
    }
  }


  useEffect(() => {
    const addProductToRecentView = async () => {
      if (!userId || !accessToken) {
        return;
      }
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/recentviewproductsave`,
          {
            userId: userId,
            productId: productId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } catch (error) {
        console.log(error)
      }
    }
    const fetchProductDetails = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/details`,
          { id: productId }
        );
        // console.log(response.data);
        if (response.data.success) {
          // console.log(response.data.productdata)
          setProductData(response.data.productdata);
          setProductMeasurements(response.data.productdata.product_measurment_parameter_id);
          setProductStockQuantity(response.data.productdata.product_total_stock);
          let allImages = [];
          allImages = [...allImages, response.data.productdata.product_banner_image];

          if (response.data.productdata.product_color_image.length > 0) {
            response.data.productdata.product_color_image.forEach(colorImage => {
              allImages = [...allImages, ...colorImage.gallery];
              if (colorImage.gallery.length === 0) {
                allImages.push(colorImage.image)
              }
            });
          } else if(response.data.productdata.product_gallery_image.length > 0) {
            allImages = [...allImages, ...response.data.productdata.product_gallery_image];
          }

          setproductImages(allImages);
          setCurrentProductImage(allImages[0])
        } else {
          setError("Product not found");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
        setError("An error occurred while fetching product data");
      }
    };

    const fetchWishlist = async () => {
      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };

    const fetchRecentlyWatchedProducts = async () => {
      if(!userId){
        return;
      }
      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product//users/${userId}/recently-viewed`,
        );
        // console.log(response.data.recentviewedItemsave)
        setRecentlyWatched(response.data.recentviewedItemsave)
      } catch (e) {
        console.error("Error fetching product reviews:", e);
      }
    }

    const getRecommendedProducts = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/recommendedProducts`,
          {
            productId: productId,
            page: 1,
            pagesize: 100
          }
        );
        // console.log(response.data.data)
        setRecommendedProducts(response.data.data)
      } catch (e) {
        console.error("Error fetching recommended products:", e);
      }
    }

    addProductToRecentView();
    fetchProductDetails();
    fetchWishlist()
    fetchRecentlyWatchedProducts();
    getRecommendedProducts();
  }, [productId]);

  useEffect(() => {
    const fetchProductReviews = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/getproductralleviews`,
          {
            productId: productId,
            page: "1",
            limit: "10"
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // console.log(response.data)
        setProductReviews(response.data.transformedReviews)
      } catch (e) {
        console.error("Error fetching product reviews:", e);
      }
    }
    fetchProductReviews();
  }, [productId, isAddNewReviewModalOpen])

  if (error) {
    return <div>{error}</div>;
  }

  if (!productData) {
    return <div>Loading...</div>;
  }

  const {
    product_name,
    product_short_disc,
    product_detail,
    product_specfication_detail,
    product_banner_image,
    product_gallery_image,
    product_measurment_parameter_id,
    brand_id,
    price,
    offer_price,
  } = productData;

  const handleBuyNow = () => {
    
    if((offer_price ? offer_price : price)<35){
      toast.warning("Sorry, the product is below the minimum order price of $35");
      return;
    }

    if (!accessToken || !userId) {
      toast.warning("You need to be logged in to proceed with the purchase.");
      navigate("/signin");
      return;
    }

    const isSingleAttributeNull = product_measurment_parameter_id.length === 1 && !product_measurment_parameter_id[0].attribute_id;

    if (!isSingleAttributeNull) {
        if (Object.keys(selectedMeasurements).length !== product_measurment_parameter_id.length) {
            toast.warning("Please select all measurements for the product.");
            return;
        }
    }
    

    const productInfo = [{
      id: productData._id,
      image: productData.product_banner_image,
      name: productData.product_name,
      price: productData.price,
      offer_price: productData.offer_price,
      seller: productData.vendor_details,
      quantity: selectedQuantity,
      productMaxStockQuantity: productStockQuantity,
      measurements: selectedMeasurements,
      isProductFromAliExpress: productData.isProductFromAliExpress
    }];

    // console.log(productInfo)

    checkoutDispatch({ type: 'CLEAR_CHECKOUT_ITEMS' });
    checkoutDispatch({ type: 'ADD_TO_CHECKOUT', payload: productInfo });

    navigate("/checkout");
  };


  const handleMeasurementChange = (attributeName, value) => {

    if (attributeName.toLowerCase() === 'color' || attributeName.toLowerCase()==='colour') {
      const selectedColorImage = productData.product_color_image.find(
        colorImage => colorImage.color.toLowerCase() === value.toLowerCase()
      );

      // console.log(selectedColorImage)

      if (selectedColorImage) {
        setProductStockQuantity(selectedColorImage.stock)
        if(selectedColorImage.gallery.length===0){
          setproductImages([selectedColorImage.image])  
          setCurrentProductImage(selectedColorImage.image)
        }else{
          setproductImages(selectedColorImage.gallery);
          setCurrentProductImage(selectedColorImage.gallery[0])
        }
      }
    }

    setSelectedMeasurements(prev => ({ ...prev, [attributeName]: value }));
  };

  const handleIncrease = (id) => {
    if (productStockQuantity && selectedQuantity < productStockQuantity) {
      setSelectedQuantity(prev => prev + 1);
    }
  };

  const handleDecrease = (id) => {
    if (selectedQuantity > 1) {
      setSelectedQuantity(prev => prev - 1);
    }
  };

  const handleAddNewReviewModalOpen = () => {
    setIsAddNewReviewModalOpen(true);
  };

  const handleAddNewReviewModalClose = () => {
    setIsAddNewReviewModalOpen(false);
  };
  return (
    <>
      <section className="my-5">
        <div className="container">
          <div className="row my-3">
            <p>
              <b>Catalogue</b> {">"}{" "}
              <b><NavLink className='text-dark' to={`/category/${productData.category_id_level0?._id}`}>{productData.category_id_level0 ? productData.category_id_level0?.category_name : ''}</NavLink></b> {">"}{" "}
              <b><NavLink className='text-dark' to={`/category/${productData.category_id_level1?._id}`}>{productData.category_id_level1 ? productData.category_id_level1?.category_name : ''}</NavLink></b> {">"}{" "}
              {/* <b>{productData.category_id_level2 ? productData.category_id_level2.category_name + "> " : ''}</b> */}
              {product_name}
            </p>
          </div>
          <div className="row">
            <div>
              <span></span>
              <h4>{product_name}</h4>
              <span></span>
              <p className="description-p">{product_short_disc}</p>
              <span className="p-price d-flex gap-3">
                {offer_price ? <span className="text-muted text-decoration-line-through">${price}</span> : ''}
                {offer_price ? '$' + `${offer_price}` : '$' + price}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <img
                src={productData?.product_banner_image?.startsWith('http') ? currentProductImage : `${REACT_BASE_PATH}/uploads/${currentProductImage}`}
                alt="mainimage"
                className="img-fluid single-prodimg"
              />
              <div className="row mt-3">
                <div className="prod-single-gallery">
                  {productImages
                    ?.filter(image => image !== currentProductImage)
                    .map((image, index) => (
                      <span className="p-gal" key={index} onClick={() => setCurrentProductImage(image)}>
                        <img
                          src={productData?.product_banner_image?.startsWith('http') ? image : `${REACT_BASE_PATH}/uploads/${image}`}
                          alt={`pro-gallery-${index}`}
                          className="img-fluid pgallery-img"
                        />
                      </span>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h4>Description:</h4>
              <p dangerouslySetInnerHTML={{ __html: product_detail }}></p>
              {(productMeasurements.length > 0) && (
                <div className="measurement-selection">
                  {productMeasurements.map((measurement, index) => (
                    <div key={index} className="measurement-group">
                      <label>{measurement.attribute_name}:</label>
                      <div className="measurement-options">
                        {measurement.unit.split(', ').map((unit, i) => (
                          <div
                            key={i}
                            className={`measurement-box ${selectedMeasurements[measurement.attribute_name] === unit ? 'selected' : ''}`}
                            onClick={() => handleMeasurementChange(measurement.attribute_name, unit)}
                          >
                            {unit}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <label>Quantity:</label>
              <div className="single-product-quantity-control">
                <button className="quantity-btn" onClick={() => handleDecrease()}>-</button>
                <span className="quantity m-0">{selectedQuantity}</span>
                <button className="quantity-btn" onClick={() => handleIncrease()}>+</button>
              </div>
              <div className="single-product-buttons d-flex align-items-center gap-3">
                <span>
                  <button className="addc-btn" onClick={handleBuyNow}>
                    Buy Now
                  </button>
                </span>
                <span>
                  <button className="addc-btn d-flex align-items-center gap-2" onClick={() => handleAddToCart(productData)}>
                    Add to Cart
                    {/* <i className="fa fa-shopping-cart" aria-hidden="true"></i> */}
                    <FaShoppingCart />
                  </button>
                </span>
                <span className="m-2">
                  {/* <i className="far fa-heart icon-img1 ms-2 mt-2"></i> */}
                  <div className="icon-img1" onClick={handleAddToWishlist}>
                    {wishlist.has(productId) ? <FaHeart /> : <FaRegHeart />}
                  </div>
                </span>
                <span className="m-2">
                  {/* <i className="fa fa-exchange icon-img1 ms-2 mt-2"></i> */}
                  <div className="icon-img1">
                    <FaExchangeAlt />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="product-details">
                <h4>Details:</h4>
                <div className="details-table">
                  <div className="details-row">
                    <div className="details-label">Brand:</div>
                    <div className="details-value">{brand_id?.brand_name}</div>
                  </div>
                  {product_specfication_detail?.map((spec, index) => (
                    <div className="details-row" key={index}>
                      <div className="details-label">
                        {spec.specfication_heading}:
                      </div>
                      <div
                        className="details-value"
                        dangerouslySetInnerHTML={{
                          __html: spec.specification_detail,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="reviews-container" style={{ width: '100%' }}>
                <h4>Customer Reviews :</h4>
                {/* {(userId && accessToken) && <button className="addc-btn ms-2 my-4" style={{ width: 'fit-content' }} onClick={handleAddNewReviewModalOpen}>
                  <FaPlus /> Add Your Review
                </button>} */}
                <AddNewReviewModal
                  isOpen={isAddNewReviewModalOpen}
                  onClose={handleAddNewReviewModalClose}
                  productId={productId}
                />
                <div className="customer-reviews-div row m-0">
                  {productReviews.length > 0 ?
                    productReviews.map((review) => (
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-5">
                        <div className="review-card ">
                          <div className="review-header">
                            <img src={user} alt="Userpic" className="userpic" />
                            <div className="review-author">
                              <h3>Josephine McAdam</h3>
                              <p>{review.createdAt.split("T")[0]}</p>
                            </div>
                          </div>
                          <div className="review-content">
                            <div className="rating">
                              {Array(review.rating).fill().map((_, i) => (
                                <span key={i}>★</span>
                              ))}
                            </div>
                            <p>
                              {review.comment}
                            </p>
                          </div>
                        </div>
                      </div>
                    )) : <p>No customer reviews on this product</p>
                  }

                </div>
                <div className="review-buttons">
                  {/* <button className="add-review-btn">+ ADD REVIEW</button> */}
                  {/* <button className="load-more-btn">LOAD MORE</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {recommendedProducts.length > 0 && <section>
        <div className="container">
          <div className="row my-5">
            <h4>Recommended Products</h4>
            <p>You may also like:</p>
            <Swiper
              breakpoints={{
                  320: { 
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  576: { 
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: { 
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  992: { 
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1200: { 
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              spaceBetween={20}
              slidesPerView={4}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation
            >
              {
                recommendedProducts.map((item) => (
                  <SwiperSlide>
                    <div className="product-box-1" onClick={() => navigate(`/singleproduct/${item._id}`)}>
                      <img src={item?.product_banner_image?.startsWith('http') ? item.product_banner_image : `${Asset_url}${item.product_banner_image}`} alt={item.product_name} className="prod-img img-fluid" />
                      <p className="product-title">{item.product_name.length>60 ? item.product_name.slice(0,60) : item.product_name}</p>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <p className="rate">{item.offer_price ? item.offer_price : item.price}</p>
                      <div className="icons">
                        <i className="fas fa-shopping-cart cart-icon"></i>
                        <i className="fas fa-heart wishlist-icon"></i>
                        <i className="fa fa-eye eye-icon"></i>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>
      </section>}

      {recentlyWatched.length > 0 && <section>
        <div className="container">
          <div className="row my-5">
            <h4>You Recently Watched</h4>
            <p>Get all the items you want</p>
            <Swiper
              breakpoints={{
                  320: { 
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  576: { 
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: { 
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  992: { 
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1200: { 
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              spaceBetween={20}
              slidesPerView={4}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation
            >
              {
                recentlyWatched.map((item) => (
                  <SwiperSlide>
                    <div className="product-box-1" onClick={() => navigate(`/singleproduct/${item._id}`)}>
                      <img src={item?.product_banner_image?.startsWith('http') ? item.product_banner_image : `${Asset_url}${item.product_banner_image}`} alt={item.product_name} className="prod-img img-fluid" />
                      <p className="product-title">{item.product_name.length>40 ? item.product_name.slice(0,40) + '...' : item.product_name}</p>
                      <span>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </span>
                      <p className="rate">{item.offer_price ? item.offer_price : item.price}</p>
                      <div className="icons">
                        <i className="fas fa-shopping-cart cart-icon"></i>
                        <i className="fas fa-heart wishlist-icon"></i>
                        <i className="fa fa-eye eye-icon"></i>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>
      </section>}
    </>
  );
};

export default SingleProduct;
