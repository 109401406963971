import React, { useState, useEffect } from "react";
import { REACT_BASE_PATH } from "../../../api";
import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Select from "react-select";
import axios from "axios";


const Categories = ({ selectedCategory, selectedSubCategory, selectedChildCategory, category_details_level0, category_details_level1, category_details_level2 }) => {
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [childcategory, setChildCategory] = useState([]);
  const [childcategoryValue, setChildcategoryValue] = useState(category_details_level2 || null);
  const [subcategoryValue, setSubcategoryValue] = useState(category_details_level1 || null);
  const [categoryValue, setcategoryValue] = useState(category_details_level0 || null);
  
  useEffect(() => {
    fetchCategory();
  }, []);

  
  const fetchChildCategory = async (catId) => {
    let payload = {
      parent_id: catId
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        const Options = response.data.data.map((brand) => ({
          value: brand._id,
          label: brand.category_name,
        }));
        setChildCategory(Options);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };
  const fetchSubCategory = async (catId) => {
    let payload = {
      parent_id: catId
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        const Options = response.data.data.map((brand) => ({
          value: brand._id,
          label: brand.category_name,
        }));
        setSubCategory(Options);
        if(response.data.data[0]){
          fetchChildCategory(response.data.data[0]._id)
        }
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const fetchCategory = async () => {
    let payload = {
      parent_id: 0
    }
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/productcategory`, payload);
      if (response.data.status === 200) {
        const Options = response.data.data.map((brand) => ({
          value: brand._id,
          label: brand.category_name,
        }));
        setCategory(Options);
        if (response.data.data[0]) {
          fetchSubCategory(response.data.data[0]._id);
        }
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const categoryChange = (event) => {
    setSubcategoryValue(null)
    setChildcategoryValue(null)
    selectedCategory(event)
    setcategoryValue(event);
    fetchSubCategory(event.value)
  };

  const subcategoryChange = (event) => {
    setChildcategoryValue(null)
    selectedChildCategory(null)
    setSubcategoryValue(event)
    selectedSubCategory(event);
    fetchChildCategory(event.value)

  };

  const childcategoryChange = (event) => {
    setChildcategoryValue(event)
    selectedChildCategory(event);
  }

  return (
    <>
      <div className="">
        <div className="category-container">
          <div className="vendor-top col-md-6">
            <h5 className="title-v">Category<span className="required">*</span></h5>
          </div>
          <div className="">
            <label>Select Category</label>
            <Select
              options={category}
              value={categoryValue}
              onChange={categoryChange}
              placeholder="Select Category"
            />
            <label>Select Sub Category</label>
            <Select
              options={subcategory}
              value={subcategoryValue}
              onChange={subcategoryChange}
              placeholder="Select Sub Category"
            />
            <label>Select Child Category</label>
            <Select
              options={childcategory}
              value={childcategoryValue}
              onChange={childcategoryChange}
              placeholder="Select Sub Category"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
