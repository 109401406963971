import React, { useState } from 'react'
import StripePaymentGateway from './StripePaymentGateway'
import NMIPaymentGateway from './NMIPaymentGateway'
import PayPalPaymentgateway from './PaypalPaymentGateway'
import Spreedly from './Spreedly'


const PaymentModal = ({ paymentStatus,setPaymentStatus, paymentGateway, placedOrderDetails, email, grandTotal, orderStatus, setIsPaymentModalOpen, shippingAddress }) => {
    const [paymentError, setPaymentError] = useState('')
    // console.log(placedOrderDetails)
    const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    return (
        <>
            <div className="checkout-payment-modal-backdrop">
                <div className={`checkout-payment-modal-content ${paymentGateway}`}>
                    
                    {orderStatus === 'processing' && (
                        <div className='checkout-payment-modal-content2'>
                            <img src='/images/processingorder.png' alt='Processing Order' className='img-fluid' />
                            <h3>Processing your order!</h3>
                            <h6>Please Wait while we process your order</h6>
                            <div className="checkout-payment-modal-loader"></div>
                        </div>
                    )}
                    {orderStatus === 'failed' && (
                        <div className='checkout-payment-modal-content2'>
                            <img src='/images/orderfailed.jpg' alt='Order Failed' className='img-fluid' />
                            <h3>Sorry!</h3>
                            <h6>Some server error occured while placing your order</h6>
                        </div>
                    )}
                    {/* {orderStatus === 'success' && (paymentStatus==='processing' || paymentStatus==='notinitiated') && (
                        paymentGateway === 'paypal' ?
                        <PayPalPaymentgateway grandTotal={grandTotal} paypalClientId={PAYPAL_CLIENT_ID} setPaymentStatus={setPaymentStatus} setPaymentError={setPaymentError} setIsPaymentModalOpen={setIsPaymentModalOpen} placedOrderDetails={placedOrderDetails} /> :
                        paymentGateway === 'stripe' ?
                        <StripePaymentGateway grandTotal={grandTotal} setPaymentStatus={setPaymentStatus} setPaymentError={setPaymentError} setIsPaymentModalOpen={setIsPaymentModalOpen} placedOrderDetails={placedOrderDetails} /> :
                        <NMIPaymentGateway grandTotal={grandTotal} setPaymentStatus={setPaymentStatus} setPaymentError={setPaymentError} email={email} deliveryAddress={shippingAddress} placedOrderDetails={placedOrderDetails} setIsPaymentModalOpen={setIsPaymentModalOpen} />
                    )} */}
                    {orderStatus === 'success' && (paymentStatus==='processing' || paymentStatus==='notinitiated') && (
                        <Spreedly grandTotal={grandTotal} setPaymentStatus={setPaymentStatus} setPaymentError={setPaymentError} email={email} deliveryAddress={shippingAddress} placedOrderDetails={placedOrderDetails} setIsPaymentModalOpen={setIsPaymentModalOpen} />
                    )}

                    {paymentStatus === 'processing' && orderStatus==='success' && (
                        <div className='checkout-payment-modal-content2'>
                            <img src='/images/payment-proccessing.png' alt='Payment Processing' className='img-fluid' />
                            <h3>Processing your payment!</h3>
                            <h3>Please Wait</h3>
                            <div className="checkout-payment-modal-loader"></div>
                        </div>
                    )}
                    {paymentStatus === 'failed' && (
                        <div className='checkout-payment-modal-content2'>
                            <img src='/images/paymentfailed.jpg' alt='Payment failed' className='img-fluid' />
                            <h3>Payment Failed!</h3>
                            <h6>Please try again later.</h6>
                            <p>{paymentError}</p>
                            <buton className='checkout__btn' onClick={() => setIsPaymentModalOpen(false)}>Go Back</buton>
                        </div>
                    )}
                    {orderStatus === 'success' && paymentStatus==='success' && (
                        <div className='checkout-payment-modal-content2'>
                            <img src='/images/orderplaced.webp' alt='Order Placed' className='img-fluid' />
                            <h3>Thank you!</h3>
                            <h3>Your order has been placed.</h3>
                            <p>Redirecting to your order details in 5 seconds</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default PaymentModal
