import { Avatar, Box, Chip, FormControl } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { _get_all_history, _get_image_path } from '../../url';
import { getAffilateAccessToken, getAffilateUserId } from '../../utills';
import ImageSeeModal from './component/ImageSeeModal';
import toast from 'react-hot-toast';

function AllHistory() {
    const [labels, setLabels] = useState([]);
    const  [editUser, setEditUser] = useState(null);
    const  [action, setAction] = useState('action');
    const [isFetching, setIsFetching] =  useState(false);
    const [openDelteModal , setOpenDelteModal] = useState(false);
    const [open, setOpen] = React.useState(false);

   useEffect(() => {
    const fetchLabels = async () => {
        setIsFetching(true);
        try {
            const response = await axios.post(_get_all_history, {id : getAffilateUserId()}, {
                headers: {
                    'Authorization': `Bearer ${getAffilateAccessToken()}`
                  }
            });
            const nonNullData = response.data.data?.filter(data => data.taskId !== null)
            setLabels(nonNullData || []); // Adjust based on your API response structure
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching labels:', error); 
            toast.error(error?.response?.data?.message || 'Failed to fetch data.');
        }
    };
    fetchLabels();
}, []);

 const onHandleSeeImage = (img) => {
     setEditUser(img);
     setOpen(true);
 }

  return (
    <>
     <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Task Lable</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel  mb-3">
        <div className="d-flex flex-row justify-content-between">
          <Box className="d-flex align-items-center gap-2 w-25">
            <FormControl fullWidth>
              <input
                // value={codeVal}
                // onChange={(e) => setcode(e.target.value)}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Search Affiliates"
                required
              />
            </FormControl>
          </Box>
          <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
            <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
              <div
                className="dataTables_length mx-n2"
                id="DataTables_Table_0_length"
              >
                <label>
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    className="form-select"
                    id="entries"
                    // value={selectedOption}
                    // onChange={handleChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="70">70</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
              <div className="mb-6">
                <button className="btn btn-theam mx-2" type="button">
                  <span>Export CSV</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mlr-10 mb-4">
          {isFetching ? (
            "Fetching users..."
          ) : (
            <table className="tableGap">
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>Project Name</th>
                  <th>Task URL</th> 
                  <th>Evidence</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                    labels?.length === 0 && <p className="p-2">No task history found</p>
                }
                {
                  labels?.map(lable => (
                    <tr key={lable?._id}>
                    <td>{lable?.taskId?.taskName}</td>
                    <td>{lable?.taskId?.taskTabel?.labelName}</td>
                    <td>{lable?.taskId?.taskUrl}</td>
                    <td>
                     <Avatar alt="Remy Sharp" src={`${_get_image_path}/${lable?.image}`} onClick={() => onHandleSeeImage(`${_get_image_path}/${lable?.image}`)}/>
                    </td>
                    <td>
                        {
                            lable?.isApproved && <Chip label="Approved" color="success" />
                        }
                        {
                            lable?.isApproved === false && <Chip label="Rejected" className="bg-danger" />
                        }
                        {
                            lable?.isApproved === null && <Chip label="Pending" color="warning" />
                        }
                    </td>
                  </tr>
                  ))
                }
                
                {/* {users?.map((data, index) => (
                  <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>{data?.categoryId?.category_name}</td>
                    <td>{data?.name}</td>
                    <td>{data?.affiliatePercentage}</td>
                    <td>
                      <PinkSwitch
                        {...label}
                        checked={data?.isAffiliateUser}
                        onChange={(event) => handleUpdateStatus(event,data?._id)}
                      />
                    </td>
                    <td className="d-flex items-center">
                      <button
                        className="edit-btn"
                        onClick={() => handleClickOpen(data)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button className="trash-icon disabledBtn" onClick={() => handleDeleteUser(data)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          )}
        </div>
        </section>
        <ImageSeeModal 
          open={open}
          onClose={() => setOpen(false)}
          editUser={editUser}
        />
    </>
  )
}

export default AllHistory