import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import RightArrow from "../img/icon/Arrow-right.png";
import LeftArrow from "../img/icon/Arrow-left.png";
import airpods from "../img/product1.jpeg";
import imac from "../img/product5.jpeg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../components/WebPanel/cart/CartContext";
import Cookies from 'js-cookie'
import { REACT_BASE_PATH, Asset_url } from "../api";
import { toast } from "react-toastify";

function Slider() {
  const [products, setProducts] = useState([])

  useEffect(() => {
    const fetchBeautyProducts = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/product/category/product`, {
          category_id: '66ae20afef7af1d34d8c0491',
          level: 0,
          page: 1,
          pagesize: 100
        })
        setProducts(response.data.data)
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    fetchBeautyProducts();
  }, [])

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(new Set())

  const { state, dispatch } = useContext(CartContext);


  const onSingleProduct = (productId) => {
    navigate(`/singleproduct/${productId}`);
  };

  const handleAddToCart = async (product) => {

    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
    }

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      toast.warning("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      dispatch({ type: 'ADD_TO_CART', payload: productInfo });
      toast.success("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch({ type: 'ADD_TO_CART', payload: productInfo });
          toast.success("Product added to the cart!");
        } else {
          toast.error("Failed to add product to the cart");
        }
      } catch (error) {
        // console.error("Error adding product to the cart:", error);
        toast.error(error.response?.data?.message || "Some error occured, please try again");
      }
    }
  }

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }

    if (wishlist.has(productId)) {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [productId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          toast.success("Product removed from wishlist successfully!");
          setWishlist((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return new Set(newWishlist);
          });
          return;
        } else {
          toast.error("Failed to remove product from wishlist.");
          return;
        }
      } catch (error) {
        toast.error(error.response?.data?.message || "Error in removing product from wishlist, please try again");
        return;
      }
    }

    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/product/wishlist/add`,
        {
          userId: userId,
          productId: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Product added to wishlist successfully!");
        setWishlist((prevWishlist) => new Set(prevWishlist).add(productId));
      } else {
        toast.error("Failed to add product to wishlist.");
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error);
      toast.error(error.response?.data?.message || "Some error occured, please try again");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');

      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };
    fetchWishlist();
  }, [])


  const [startIndex, setStartIndex] = useState(0);

  const handleNextClick = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const handlePrevClick = () => {
    setStartIndex(
      (prevIndex) => (prevIndex - 1 + products.length) % products.length
    );
  };

  const getDisplayedProducts = () => {
    const endIndex = (startIndex + 4) % products.length;
    if (startIndex < endIndex) {
      return products.slice(startIndex, endIndex);
    } else {
      return [...products.slice(startIndex), ...products.slice(0, endIndex)];
    }
  };

  const displayedProducts = getDisplayedProducts();

  return (
    <div className="container3 p-4">
      <h2 className="mt-5">Refresh Your Day with Krizaar</h2>
      <h6 className="text-danger mb-4">Discover the Finest Beverages, All in One Place!</h6>
      <div className="carousel slide">
        <div className="carousel-inner d-flex justify-content-center text-center">
          {displayedProducts.map((product) => (
            <div key={product._id} className="card4 border-0">
              <div onClick={() => onSingleProduct(product._id)}>
                <img
                  src={product?.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`}
                  alt={product.product_name}
                  className="card-img-top2 rounded-top"
                />
                <div className="card-body2 rounded-bottom">
                  <h6 className="card-title2">{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</h6>
                  <p className="card-text mb-1 love-star">
                    {"★".repeat(product.rating)}
                    {"☆".repeat(5 - product.rating)}
                  </p>
              <p className="rate">
                {product.offer_price ? `$${product.offer_price}` : product.price ? `$${product.price}` : "Price not available"}
              </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <NavLink
          className="carousel-control-prev"
          role="button"
          onClick={handlePrevClick}
        >
          <span aria-hidden="true">
            <img src={LeftArrow} alt="Previous" className="arrow-left" />
          </span>
          <span className="visually-hidden">Previous</span>
        </NavLink>
        <NavLink
          className="carousel-control-next"
          role="button"
          onClick={handleNextClick}
        >
          <span aria-hidden="true">
            <img src={RightArrow} alt="Next" className="arrow-right" />
          </span>
          <span className="visually-hidden">Next</span>
        </NavLink>
      </div>
    </div>
  );
}

export default Slider;
