import React from 'react'
import { Link } from 'react-router-dom'

function AffilateDashboard() {
  return (
    <div className="mesh-bg">
     <div className="d-flex justify-content-center h-100 align-items-center gap-2">
         <a href="/affiliate/products">
          <div className="card home-card p-3 d-flex justify-content-center gap-2 align-items-center" style={{width : '200px',height : '200px'}}>
           <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ff70ae"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-network"
              >
                <rect x="16" y="16" width="6" height="6" rx="1" />
                <rect x="2" y="16" width="6" height="6" rx="1" />
                <rect x="9" y="2" width="6" height="6" rx="1" />
                <path d="M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3" />
                <path d="M12 12V8" />
              </svg>
            <h4>Affiliate</h4>
         </div>
         </a>
         
         <a href="/airdrop/all_task">
            <div className="card p-3 home-card d-flex justify-content-center gap-2 align-items-center" style={{width : '200px',height : '200px'}}>
               <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="#ff70ae" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
                <h4>Airdrop</h4>
            </div>
         </a>
     </div>  
    </div>
  )
}

export default AffilateDashboard