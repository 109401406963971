import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const InterestBasedAds = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className='terms-of-service my-5'>
            <h1 className="my-3">Interest-Based Ads</h1>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-3 mt-1'>
                <div style={{ textAlign: 'justify' }}>Interest-based ads, also known as personalized or targeted ads, are used by Krizaar to show you products, features, and services that may be of interest to you. We follow the Self-Regulatory Principles for Online Behavioral Advertising, developed by the Digital Advertising Alliance.
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>What Information Do We Use for Interest-Based Ads?</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>
                      <p>To provide you with relevant ads, we use information about your interactions with Krizaar websites, content, or services. However, we do not use personal information like your name or email address for these ads. We rely on cookies, pixels, IP addresses, and other technologies (collectively called "advertising technologies") to track which ads you view or click on, and to ensure you’re shown more useful and relevant ads. For example, cookies or IP addresses help us limit how many times you see the same ad.</p>
                      <p>
                        For more details on how we collect and use your information, refer to our Privacy Notice.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How We Work with Third Parties for Interest-Based Ads</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}>
                      <p>
                        Krizaar partners with third parties such as advertisers, social media networks, and ad networks to serve you more relevant ads. We don’t share personally identifiable information, like your name or email, with advertisers or other sites that show our ads. These third parties may use advertising technologies to deliver ads directly to your browser or device and automatically collect your IP address. They also use these technologies to track the performance of ads, ensuring you receive more relevant content.
                      </p>
                      <p>
                        Some third parties may share anonymized or pseudonymized information (such as demographics or previous ad interactions) to help us show you more useful ads.
                      </p>
                      <p>
                        For more information on controlling or deleting cookies, including third-party cookies, visit our Cookies Notice.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='checkout__step-header' style={{ cursor: 'auto' }} >
              <div className=' d-flex gap-4'>
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Advertising Preferences</h5></div>
                  <div className=' d-flex gap-3 mt-1'>
                    <div style={{ textAlign: 'justify' }}><p>
                      You can opt out of interest-based ads on Krizaar. If you choose to opt out, you will still see ads, but they won’t be tailored to your interests. To adjust your preferences, visit your Advertising Preferences page.
                    </p>
                      <p>
                        You can also opt out of personalized ads from third-party advertisers who are part of the Network Advertising Initiative (NAI) or follow the Digital Advertising Alliance’s guidelines by visiting their respective opt-out pages.
                      </p>
                      <p>
                        For information on advertising practices for services provided by other Krizaar companies, please review their interest-based ad policies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InterestBasedAds;
