import React from "react";
import UpdateSetting from "./UpdateSetting";

function Settings() {
  return (
    <>
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Settings</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
                <UpdateSetting />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Settings;
