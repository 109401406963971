import React from 'react'
import CommonOtherLinkComponent from './krizaar_pay/CommonOtherLinkComponent'

const KrizaarPay = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Your Trusted Payment Processing Partner',
      content: 'Flobux is the core of Krizaar Pay, handling all your payment needs with precision and care. From receiving payments to sending payouts, Flobux ensures your transactions are always fast and secure.',
      image: '/images/pay/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Credit Card Payment Processing',
      content: 'Easily accept payments from all major credit cards. Flobux ensures that transactions are processed quickly and securely, delivering a smooth experience for both you and your customers.',
      image: '/images/pay/pic4.jpg'
    },
    {
      id: 3,
      subtitle: 'Global Payouts',
      content: 'Send and receive payments from anywhere in the world. Flobux supports global payment processing, helping your business operate seamlessly, no matter where your customers or suppliers are located.',
      image: '/images/pay/pic3.jpg'
    },
    {
      id: 4,
      subtitle: 'Comprehensive Payment Solutions',
      content: 'From credit card processing to blockchainpowered payouts, Krizaar Pay covers every aspect of your payment needs.',
      image: '/images/pay/pic5.jpg'
    },
    {
      id: 5,
      subtitle: 'In-House Expertise',
      content: 'Flobux offers personalized and reliable service, ensuring your payment processing is handled with care and precision.',
      image: '/images/pay/pic6.jpg'
    },
    {
      id: 6,
      subtitle: 'Global Capabilities',
      content: 'No matter where your business operates, Krizaar Pay provide the tools to manage payments locally and globally with ease.',
      image: '/images/pay/pic7.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Welcome to Krizaar Pay',
    subtitle: 'Revolutionizing Payment Processing for Global Commerce',
    content2: 'At Krizaar Pay, we believe that secure, fast, and seamless payment processing is the key to success in global commerce. That’s why we’ve built a comprehensive payment solution for sellers, suppliers, and vendors worldwide. Powered by Flobux, our in-house payment processing division, we combine traditional payment methods with cutting-edge blockchain technology, ensuring that every transaction is reliable, secure, and efficient.',
    image: '/images/pay/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Blockchain-Powered Payouts",
    subtitle: "Speed, Security, and Global Reach",
    description: 'At Krizaar Pay, we leverage the power of blockchain technology to take payment processing to the next level, offering several key benefits',
    steps: [
      {
        title: `Speed and Efficiency`,
        description: `Blockchain allows us to process payments and payouts faster than traditional methods, reducing wait times and ensuring funds reach their destination quickly.`,
      },
      {
        title: `Security and Transparency`,
        description: `With blockchain’s decentralized structure, every transaction is fully secure and transparent. Each payment is recorded on an immutable ledger, giving you full visibility and peace of mind.`,
      },
      {
        title: `Global Reach`,
        description: `Whether you’re a seller, supplier, or vendor, our blockchain-powered payouts enable you to receive payments in multiple currencies, without the barriers of traditional banking systems.`,
      },
    ],
  };


  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Krizaar Pay?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default KrizaarPay
