import React, { useContext, useEffect, useMemo, useState } from 'react';
import Header from '../../componenth/header/Header'
import Footer from '../../componenth/header/Footer'
import { CheckoutContext } from './checkout/CheckoutContext';
import Cookies from 'js-cookie'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { REACT_BASE_PATH, Asset_url } from "../../api";
import { toast } from 'react-toastify';

const ReturnOrder = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [selectedAddressOption, setSelectedAddressOption] = useState('new');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
    name: '',
    phone: '',
    zip: '',
    locality: '',
    address: '',
    city: '',
    state: '',
    landmark: '',
    alternatePhone: '',
    addressType: ''
  });

  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobno: '',
    image: ''
  })
  const [existingAddresses, setExistingAddresses] = useState([]);
  const { state, dispatch } = useContext(CheckoutContext);
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get('krizaar-user');

  useEffect(() => {

    if (!userId || !accessToken) {
      return;
    }

    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/auth/user/profile/detail`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            }
          }
        );

        if (response.data && response.data.userdata) {
          setUserDetails({
            first_name: response.data.userdata.first_name,
            last_name: response.data.userdata.last_name,
            email: response.data.userdata.email,
            mobno: response.data.userdata.mobno,
            image: response.data.userdata.image ? response.data.userdata.image : null,
          })
        }
      } catch (e) {
        console.log(e, "Failed to fetch user details due to error: ", e.message)
      }
    }

    const fetchExistingAddresses = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/auth/user/addresses`, {
          address: {
            user_id: userId
          }
        }, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        // console.log(response.data.userAddress)
        if (response.data.userAddress.length > 0) {
          setSelectedAddressOption('existing');
          setSelectedAddress(response.data.userAddress[0])
        }
        setExistingAddresses(response.data.userAddress);
      } catch (err) {
        console.log("Error in fetching address of the user");
      }
    };
    fetchUserDetails();
    fetchExistingAddresses();
  }, [accessToken, navigate, state.checkoutItems, userId]);

  // console.log(selectedAddress)

  const addNewAddress = async () => {
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/auth/user/create/address`, {
        address: {
          user_id: userId,
          full_name: newAddress.name,
          pin_code: newAddress.zip,
          locality: newAddress.locality,
          address_detail: newAddress.address,
          country: 'USA',
          state: newAddress.state,
          city: newAddress.city,
          landmark: newAddress.landmark || '',
          alternate_phone_no: newAddress.alternatePhone || '',
          address_type: newAddress.addressType
        }
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      // console.log(response)
      setExistingAddresses([...existingAddresses, response.data.addressdata]);
      setSelectedAddress(response.data.addressdata);
    } catch (err) {
      console.log("Error in adding new address");
    }
  };

  const handleNextStep = async () => {
    // if(currentStep === 1){
    //   if(selectedReason==='Other'){
    //     if(otherReason.trim.length===0){
    //       alert('Please give a specific reason')
    //       return
    //     }
    //   }
    // }else if (currentStep === 2) {
    //   if (selectedAddressOption === 'new') {
    //     const requiredFields = ['name', 'phone', 'zip', 'locality', 'address', 'city', 'state', 'addressType'];
    //     const allFieldsFilled = requiredFields.every(field => newAddress[field].trim() !== '');

    //     if (!allFieldsFilled) {
    //       alert('Please fill out all required fields in the new address form.');
    //       return;
    //     }

    //     await addNewAddress();

    //     setSelectedAddressOption('existing');
    //     // setCurrentStep(prevStep => prevStep + 1);
    //   } else if (selectedAddress) {
    //     setCurrentStep(prevStep => prevStep + 1);
    //   } else {
    //     alert('Please select an address to proceed.');
    //   }
    // } else {
      setCurrentStep(prevStep => prevStep + 1);
    // }
  };


  const handleAddressOptionChange = (event) => {
    if (!selectedAddress) {
      toast.error('There is not any existing address, Please add new address')
      return;
    }
    setSelectedAddressOption(event.target.value);
    // setSelectedAddress(null);
  };

  const handleNewAddressChange = (event) => {
    const { name, value } = event.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleConfirmReturn = () => {
    
  }

  return (
    <>
      <div className='checkout-section'>
        <div className='container'>
          <div className="checkout-section-inseide row m-0">
            <div className="checkout col-lg-8 col-md-8 col-sm-6 col-12">
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 1 ? 'checkout__completed' : ''}`}>
                  <div className="d-flex gap-4">
                    <div className="checkout__step-number">1</div>
                    <div className="d-flex flex-column justify-content-center align-items-start">
                      <div className="checkout__step-name d-flex justify-content-center align-items-center">
                        <h5 className="m-0">Reason for Return</h5>
                      </div>
                      {currentStep > 1 && selectedReason && (
                        <div className="d-flex gap-3 mt-1">
                          <span>
                            {
                              selectedReason==='Other' ?
                              otherReason.slice(0.30) : selectedReason
                            }
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 1 && (
                    <button className="checkout__btn" onClick={() => setCurrentStep(1)}>CHANGE</button>
                  )}
                </div>

                {currentStep === 1 && (
                  <div className="checkout__login-info">
                    <div className="checkout-user-details w-100">
                      <p className="m-1"><b>Please select a reason for return</b></p>
                      <div className="return-reasons">
                        <label className=' d-flex gap-2'>
                          <input
                            type="radio"
                            value="Item was missing from the package"
                            checked={selectedReason === "Item was missing from the package"}
                            onChange={(e) => setSelectedReason(e.target.value)}
                          />
                          Item was missing from the package
                        </label>
                        <label className=' d-flex gap-2'>
                          <input
                            type="radio"
                            value="Received wrong item"
                            checked={selectedReason === "Received wrong item"}
                            onChange={(e) => setSelectedReason(e.target.value)}
                          />
                          Received wrong item
                        </label>
                        <label className=' d-flex gap-2'>
                          <input
                            type="radio"
                            value="Received broken/damaged item"
                            checked={selectedReason === "Received broken/damaged item"}
                            onChange={(e) => setSelectedReason(e.target.value)}
                          />
                          Received broken/damaged item
                        </label>
                        <label className=' d-flex gap-2'>
                          <input
                            type="radio"
                            value="Product quantity/volumne is different"
                            checked={selectedReason === "Product quantity/volumne is different"}
                            onChange={(e) => setSelectedReason(e.target.value)}
                          />
                          Product quantity/volumne is different
                        </label>
                        <label className='d-flex gap-2'>
                          <input
                            type="radio"
                            value="Other"
                            checked={selectedReason === "Other"}
                            onChange={(e) => {
                              setSelectedReason(e.target.value);
                              setOtherReason('');
                            }}
                          />
                          Other
                        </label>
                        {selectedReason === "Other" && (
                          <textarea
                            className="form-control mt-2"
                            placeholder="Please specify the reason"
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                            rows="3"
                          />
                        )}

                      </div>
                    </div>
                    <div className="buttons d-flex gap-3">
                      <button className="checkout__btn" onClick={handleNextStep}>
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* Step 2: Pickup Address */}
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 2 ? 'checkout__completed' : ''}`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">2</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Pickup Address</h5></div>
                      {currentStep > 2 && (
                        <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                          <div className="user-details d-flex gap-3">
                            <p className="m-0">{selectedAddress?.full_name}</p>
                            <p className="m-0">{selectedAddress?.alternate_phone_no}</p>
                          </div>
                          <div className="address">
                            {selectedAddress?.address_detail}, {selectedAddress?.locality}, {selectedAddress?.city}, {selectedAddress?.state}, {selectedAddress?.zip}, {selectedAddress?.country}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 2 && <button className="checkout__btn" onClick={() => setCurrentStep(2)}>CHANGE</button>}
                </div>
                {currentStep === 2 && (
                  <div className="checkout__address-section">
                    <div className="checkout__address-selection">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="addressOption"
                          value="new"
                          checked={selectedAddressOption === 'new'}
                          onChange={handleAddressOptionChange}
                        />
                        <span className="radio-button">Add New Address</span>
                      </label>
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="addressOption"
                          value="existing"
                          checked={selectedAddressOption === 'existing'}
                          onChange={handleAddressOptionChange}
                        />
                        <span className="radio-button">Select Existing Address</span>
                      </label>
                    </div>
                    {selectedAddressOption === 'existing' ? (
                      <div className="checkout__existing-address">
                        {existingAddresses.length > 0 ? (
                          existingAddresses.map((address) => (
                            <label
                              key={address._id}
                              className={`checkout__address-item ${selectedAddress._id === address._id ? 'selected' : ''}`}
                              onClick={() => setSelectedAddress(address)}
                            >
                              <input
                                type="radio"
                                name="address"
                                value={address._id}
                                checked={selectedAddress._id === address._id}
                                onChange={() => setSelectedAddress(address)}
                              />
                              <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                                <div className="user-details d-flex gap-3">
                                  <p className="m-0">{address.full_name}</p>
                                  <span>{address.address_type}</span>
                                  <p className="m-0">{address.alternate_phone_no}</p>
                                </div>
                                <div className="address">
                                  {address.address_detail}, {address.locality}, {address.city}, {address.state}, {address.zip}, {address.country}
                                </div>
                                {selectedAddress._id === address._id && (
                                  <button
                                    type="button"
                                    className="checkout__btn checkout__save-btn"
                                    onClick={handleNextStep}
                                  >
                                    Pickup from this Address
                                  </button>
                                )}
                              </div>
                            </label>
                          ))
                        ) : (
                          <p>No existing addresses found.</p>
                        )}
                      </div>
                    ) : (
                      <form className='new-address-form row m-0'>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Your Name</label>
                          <input type="text" name="name" placeholder="Enter your name" value={newAddress.name} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Phone Number</label>
                          <input type="tel" name="phone" placeholder="Enter your phone number" value={newAddress.phone} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Zip Code</label>
                          <input type="text" name="zip" placeholder="Enter your zip code" value={newAddress.zip} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Locality</label>
                          <input type="text" name="locality" placeholder="Enter your locality" value={newAddress.locality} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-12 col-md-12 col-sm-12 col-12">
                          <label>Address</label>
                          <input type="text" name="address" placeholder="Enter your address" value={newAddress.address} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>City</label>
                          <input type="text" name="city" placeholder="Enter your city" value={newAddress.city} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>State</label>
                          <input type="text" name="state" placeholder="Enter your state" value={newAddress.state} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Landmark (Optional)</label>
                          <input type="text" name="landmark" placeholder="Enter a landmark" value={newAddress.landmark} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Alternate Phone (Optional)</label>
                          <input type="tel" name="alternatePhone" placeholder="Enter an alternate phone number" value={newAddress.alternatePhone} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-12">
                          <label>Address Type</label>
                          <div className="checkout__radio-group">
                            <label>
                              <input
                                type="radio"
                                name="addressType"
                                value="home"
                                checked={newAddress.addressType === 'home'}
                                onChange={handleNewAddressChange}
                              />
                              Home (All day delivery)
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="addressType"
                                value="work"
                                checked={newAddress.addressType === 'work'}
                                onChange={handleNewAddressChange}
                              />
                              Office (delivery between 10AM to 5PM)
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <button
                            type="button"
                            className="checkout__btn checkout__save-btn"
                            onClick={handleNextStep}
                          >
                            Save Address
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>

              {/* Step 3: Confirm Return */}
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 3 ? 'checkout__completed' : ''}`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">3</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Confirm Return</h5></div>
                    </div>
                  </div>
                </div>
                {currentStep === 3 && (
                  <div className="checkout__order-summary mt-0">
                    <div className="">
                      <button
                        type="button"
                        className="checkout__btn"
                        onClick={handleConfirmReturn}
                      >
                        Confirm Your Return
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cart-summary col-lg-4 col-md-4 col-sm-6 col-12">

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CheckoutProductItem = ({ product, onRemove, onIncrease, onDecrease }) => {
  return (
    <div className="cart-item">
      <img src={`${Asset_url}${product.image}`} alt={product.name} className="product-image" />
      <div className="cart-product-details">
        <h4>{product.name}</h4>
        {product.seller && product.seller.first_name && (
          <span>
            Seller: {product.seller.first_name || ''} {product.seller.last_name || ''}
          </span>
        )}
        <div className="product-price">
          <span className="cart-current-price">MRP:</span>
          <span className="cart-mrp">$ {product.price}</span>
          <span className="cart-current-price">$ {product.offer_price}</span>
          <span className="offers-applied">2 offers applied</span>
        </div>
        <div className="delivery-info d-flex gap-3">
          Delivery by Thu Jun 27 | <span className='text-decoration-line-through'>$ 40</span> <span className="free">Free</span>
        </div>
        <div className="product-actions">
          <div className="quantity-control">
            <button className="quantity-btn" onClick={() => onDecrease(product.id)}>-</button>
            <span className="quantity m-0">{product.quantity}</span>
            <button className="quantity-btn" onClick={() => onIncrease(product.id)}>+</button>
          </div>
          <button className="remove" onClick={() => onRemove(product.id)}>Remove</button>
        </div>
      </div>
    </div>
  );
};

export default ReturnOrder;
