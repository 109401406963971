import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
// import { toast } from 'rea'; // Make sure you have react-toastify installed
import { _deleteUser, _deleteUserStatusURL } from '../../../../url';
import { getAdminId } from '../../../../utills';
import toast from 'react-hot-toast';

const DeleteConfirmationDialog = ({ open, onClose, editUser, setRemount ,title }) => {
    const [isPending, setIsPending] = useState(false);
    
    const handleDeleteUser = async () => {
      console.log("editUser",editUser)
      if(title === "Category Item") {
        setIsPending(true);
        try {
          const response = await axios.post(_deleteUserStatusURL, {
            id: getAdminId(),
            categoryId: editUser?.categoryId._id,
          });
          if (response.data.success) {
            toast.success("Category deleted successfully");
            setRemount((prev) => !prev); // Reset state
            onClose(); // Close the dialog
          //   setIsPending(false);
          }
        } catch (error) {
          toast.error("Failed to deleted user");
          console.error("There was an error!", error);
        } finally {
          setIsPending(false);
        }
      } 
       
      if(title === "Affiliate User") {
        setIsPending(true);
        try {
          const response = await axios.post(_deleteUser, {
            id: getAdminId(),
            userId: editUser?._id,
          });
          if (response.data.success) {
            toast.success("User deleted successfully");
            setRemount((prev) => !prev); // Reset state
            onClose(); // Close the dialog
          }
        } catch (error) {
          toast.error("Failed to deleted user");
          console.error("There was an error!", error);
        } finally {
          setIsPending(false);
        }
      } 

    };
  
    return (
      <Dialog open={open} onClose={onClose}>
          {isPending && <LinearProgress color="secondary" /> }
        <DialogTitle>Delete {title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {title}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteUser()} color="primary" disabled={isPending}>
            {isPending ? 'Deleting...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    );
};
  
  export default DeleteConfirmationDialog;