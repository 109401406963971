
import { alpha } from '@material-ui/core';
import { Box, Button, CircularProgress, FormControl, LinearProgress, styled, Switch } from '@mui/material';
import { pink, purple } from '@mui/material/colors';
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getAdminId, getAffilateAccessToken, getAffilateUserId } from '../../utills';
import { _get_task, _get_task_affilate, _get_task_collection } from '../../url';
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadModal from './component/FileUploadModal';
import Pagination from '../AdminPanel/component/affilateDashboard/Pagination';
import { useNavigate } from 'react-router-dom';


function AllTask({ userInfo }) {
  const [open, setOpen] = React.useState(false);
  const [isPending, setisPending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);


  // pagination state
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [remount, setRemout] = useState(false);
  const [tasks, setLabels] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [action, setAction] = useState('action');
  const [projectName, setProjectName] = useState();

  const [collection, setCollections] = useState([]);
  const [collectionLoading, setCollectionLoading] = useState(false);

  const [openUploadModal, setopen] = useState(false);
  const [selectedProject, setselectedProject] = useState(null);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchLabels = async () => {
      try {
        setCollectionLoading(true);
        const response = await axios({
          url: _get_task_collection,
          method: 'post',
          headers: {
            authorization: `Bearer ${getAffilateAccessToken()}`,
          },
          data: { id: getAffilateUserId() }
        });
        setCollections(response.data.data);
      } catch (error) {
        console.error('Error fetching labels:', error.response.data.message);
        toast.error(error?.response?.data?.message || 'Failed to fetch collections.');
      } finally {
        setCollectionLoading(false);
      }
    };
    fetchLabels();
  }, [remount]);




  // handle change page data size
  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    // setCurrentPage(1);
  };

  const handleClickOpen = (data) => {
    setAction('Edit');
    setEditUser(data);
    setOpen(true);
  }

  const handleOpenFileUploadModal = (data) => {
    setEditUser(data);
    setopen(true);
  }

  const getData = async (data) => {
    navigate(`/airdrop/all_tasks_of_a_project/${data?._id}`)
  }


  const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <>
      {isPending && <LinearProgress color="secondary" />}

      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <h5 className="title-v">All Projects</h5>
          {
            collectionLoading && <CircularProgress color="secondary" />
          }
          <div className="row gap-2 mt-4" style={{ height: '300px', overflowY: 'auto' }}>

            {
              collectionLoading === false && collection?.map(data => (
                <div onClick={() => {
                  setselectedProject(data)
                  setLabels([])
                }} className="project-card col-sm-10 col-md-6 col-lg-3 card d-flex justify-content-center align-items-center " style={{ cursor: 'pointer', height: '150px', width: '150px' }}>
                  <h6 className="text-center w-75 text-wrap">{data?.labelName}</h6>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      {selectedProject && <section className="custom-cart-panel  mb-3">
        <h3 className='mb-5'>Project Details of "{selectedProject?.labelName}"</h3>
        <div className="mlr-10 mb-4">
          <table className="tableGap">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Total Project Cost</th>
                <th>Project Description</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                <tr key={selectedProject?._id}>

                  <td>{selectedProject?.labelName}</td>
                  <td>${selectedProject?.projectAmount}</td>
                  <td>{selectedProject?.labelDescription}</td>
                  <td>{new Date(selectedProject?.createdAt).toLocaleTimeString()}-{new Date(selectedProject?.createdAt).toLocaleDateString()}</td>
                  <td className="d-flex items-center">
                    <button
                      className="edit-btn"
                      onClick={() => getData(selectedProject)}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </section>}
    </>
  )
}

export default AllTask