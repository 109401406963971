import React from 'react';
import masterCard from '../../img/master-card.png'
import visa from '../../img/visa.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';


const UserAccount = () => {
    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Account Details</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='custom-cart-panel mb-3'>
                        <div className="d-flex mb-3">
                            <img src={masterCard} width={60} height={40} />
                            <div className='mx-3 flex-grow-1'>
                                <h5>Mater Card <span className='badge'>Primary</span></h5>
                                <h6>Expires Aug 2027</h6>
                            </div>
                        </div>
                        <h5><span className='spanSpace'>Name:-</span>Ranjeet Singh</h5>
                        <h6><span className='spanSpace'>Card Number:-</span>5610 6652 1220 9878</h6>
                        <h6><span className='spanSpace'>Expired on:-</span>08/2029</h6>
                        <h6><span className='spanSpace'>Type:-</span>Visa Credit Card</h6>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='custom-cart-panel mb-3'>
                        <div className="d-flex mb-3">
                            <img src={visa} width={60} height={40} />
                            <div className='mx-3 flex-grow-1'>
                                <h5>Visa Card </h5>
                                <h6>Expires Aug 2027</h6>
                            </div>
                        </div>
                        <h5><span className='spanSpace'>Name:-</span>Ranjeet Singh</h5>
                        <h6><span className='spanSpace'>Card Number:-</span>5610 6652 1220 9878</h6>
                        <h6><span className='spanSpace'>Expired on:-</span>08/2029</h6>
                        <h6><span className='spanSpace'>Type:-</span>Visa Credit Card</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAccount;