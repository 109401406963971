import React from "react";
import { NavLink } from "react-router-dom";

const Trend = () => {
  return (
    <>
      <section className="my-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="mix-img">
                <div className="bann-content">
                  <h5>Cook Up a win</h5>
                  <NavLink className='text-dark' to='/category/66c5ea6b3e180352a9d015b2'>Shop Now</NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="shoes">
                <div className="bann-content">
                  <h5>Beautiful shoes for girls</h5>
                  <NavLink className='text-dark' to='/subcategory/66c5f26f3e180352a9d01715'>Shop Now</NavLink>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">
                  <div className="cen-img">
                    <div className="bann-content">
                      <h5>On Trend & Gorgeous</h5>
                      <NavLink className='text-white' to='/subcategory/66c625743e180352a9d0189b'>Shop Now</NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="cen-img1">
                    <div className="bann-content">
                      <h5>Headphone</h5>
                      <NavLink className='text-dark' to='/category/66ae32f0ef7af1d34d8c05ec'>Shop Now</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="cream-img">
                <div className="bann-content">
                  <p>Relax & refresh</p>
                  <h5>Time for me time</h5>
                  <NavLink className='text-dark' to='/subcategory/66c6606e3e180352a9d02049'>Shop Now</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trend;
