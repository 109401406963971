import { isJwtExpired } from 'jwt-check-expiration';
 
// console.log('isExpired is:', isJwtExpired('your-token-here'));

export const getAdminId = () => {
  return JSON.parse(localStorage.getItem('vendor'))?.vendorid;
}
export const getAffilateUserId = () => {
  return JSON.parse(localStorage.getItem('affiliate'))?.userid;
}
export const getAffilateAccessToken = () => {
  return JSON.parse(localStorage.getItem('affiliate'))?.accessToken;
}

export const getAffialteToken = () => {
  return JSON.parse(localStorage.getItem('vendor'))?.accessToken;
}