import React, { useEffect, useState } from "react";
import Profile from "../../componenth/profile/Profile";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'
import { REACT_BASE_PATH, Asset_url } from "../../api";

const ManageAddress = () => {
  const [existingAddresses, setExistingAddresses] = useState([]);

  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get('krizaar-user');

  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }
    const fetchExistingAddresses = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/auth/user/addresses`, {
          address: {
            user_id: userId
          }
        }, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        // console.log(response.data.userAddress)
        if (response.data.userAddress.length > 0) {
          // setSelectedAddress(response.data.userAddress[0])
        }
        setExistingAddresses(response.data.userAddress);
      } catch (err) {
        console.log("Error in fetching address of the user");
      }
    };
    fetchExistingAddresses();
  }, [userId, accessToken])
  return (
    <>
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-3">
              <Profile />
            </div>
            <div className="col-lg-9">
              <p>Account {">"}Manage Addresses</p>
              <button className="addr-btn d-flex gap-2 align-items-center" onClick={() => navigate(`/add-edit-address/${0}`)}>
                <i className="fa fa-plus"></i>Add New
              </button>
              <div className="checkout__existing-address">
                {existingAddresses.length > 0 ? (
                  existingAddresses.map((address) => (
                    <label
                      key={address._id}
                      className='checkout__address-item selected d-flex justify-content-between align-items-center'
                      style={{ cursor: 'default' }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                        <div className="user-details d-flex gap-3">
                          <p className="m-0">{address.full_name}</p>
                          <span>{address.address_type}</span>
                          <p className="m-0">{address.phone_no || address.alternate_phone_no}</p>
                        </div>
                        <div className="address">
                          {address.locality}, {address.address_detail}, {address.city}, {address.state}, {address.zip}, {address.country}
                        </div>
                      </div>
                      <div className="addr-btn d-flex gap-2 align-items-center" style={{ cursor: 'pointer' }} onClick={() => navigate(`/add-edit-address/${address._id}`)}>
                        <i className="fa fa-pencil"></i>Edit
                      </div>
                    </label>
                  ))
                ) : (
                  <p className=" mt-5">No existing addresses found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageAddress;
