import axios from 'axios';
import React, { useState } from 'react';
import { REACT_BASE_PATH, Asset_url } from "../../api";
import { toast } from 'react-toastify';

const CancelReturnModal = ({ isOpen, onClose, items, modalAction, setSelectedItems, orderId }) => {
    const [selectedReasons, setSelectedReasons] = useState(
        items.map(item => ({ productId: item.productId, quantity: item.quantity, reason: '', otherReason: '' }))
    );
    const [completeOrderReason, setCompleteOrderReason] = useState("")

    const handleReasonChange = (index, reason) => {
        const updatedReasons = [...selectedReasons];
        updatedReasons[index].reason = reason;
        updatedReasons[index].otherReason = '';
        setSelectedReasons(updatedReasons);
    };

    const handleOtherReasonChange = (index, otherReason) => {
        const updatedReasons = [...selectedReasons];
        updatedReasons[index].otherReason = otherReason;
        setSelectedReasons(updatedReasons);
    };

    const validateReasons = () => {
        for (let i = 0; i < selectedReasons.length; i++) {
            if (!selectedReasons[i].reason || (selectedReasons[i].reason === 'Other' && !selectedReasons[i].otherReason)) {
                toast.warning(`Please select reasons for return for all items`);
                return false;
            }
        }
        return true;
    };

    const handleCancelSelectedItems = async () => {
        if (!validateReasons()) return;

        const confirmCancel = window.confirm(`Are you sure you want to cancel ${items.length} item(s)?`);
        if (!confirmCancel) return;

        const itemsToCancel = selectedReasons.map((item) => (
            {
                productId: item.productId,
                quantity: item.quantity,
                reason: selectedReasons[0].otherReason ? selectedReasons[0].otherReason : selectedReasons[0].reason
            }
        ))

        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/cancelOrder`, {
                orderId: orderId,
                itemsToCancel: itemsToCancel,
                cancelReason: "Customer requested cancellation for all items"
            });
            console.log(response)
            if(response){
                toast.success("Items cancelled successfully")
                onClose()
            }
        } catch (err) {
            // console.error('Error cancelling selected items:', err);
            toast.error('Failed to cancel selected items. Please try again.');
            toast.error(err.response?.data?.message || "Some error occured, please try again");
        }
    };

    const handleReturnSelectedItems = async () => {
        if (!validateReasons()) return;

        const confirmReturn = window.confirm(`Are you sure you want to return ${items.length} item(s)?`);
        if (!confirmReturn) return;

        // Call the API for return
    };

    const handleCancelCompleteOrder = async () => {
        const confirmCancel = window.confirm(`Are you sure you want to cancel all items in the order ?`);
        if (!confirmCancel) return;

        if(!completeOrderReason){
            toast.warning("Please add a reason to return the order")
        }

        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/cancelOrder`, {
                orderId: orderId,
                cancelReason: completeOrderReason
            });
            if(response){
                toast.success("Order cancelled successfully")
                onClose()
            }
        } catch (err) {
            // console.error('Error cancelling selected items:', err);
            toast.error('Failed to cancel selected items. Please try again.');
            toast.error(err.response?.data?.message || "Some error occured, please try again");
        }
    };


    const handleReturnCompleteOrder = async () => {
        const confirmCancel = window.confirm(`Are you sure you want to cancel all items in the order ?`);
        if (!confirmCancel) return;

        if(!completeOrderReason){
            toast.warning("Please add a reason to return the order")
        }

        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/returnOrder`, {
                orderId: orderId,
                returnReason: completeOrderReason
            });
            if(response){
                toast.success("Order returned successfully")
                onClose()
            }
        } catch (err) {
            // console.error('Error cancelling selected items:', err);
            toast.error('Failed to return selected items. Please try again.');
            toast.error(err.response?.data?.message || "Some error occured, please try again");
        }
    };
    const handleRemoveItem = (index) => {
        const updatedReasons = selectedReasons.filter((_, i) => i !== index);
        const updatedItems = items.filter((_, i) => i !== index);

        setSelectedReasons(updatedReasons);
        setSelectedItems(updatedItems);
    };

    return (
        <div className={`ordermodal ${isOpen ? 'ordermodal-open' : ''}`}>
            <div className="ordermodal-content">
                <h2>{modalAction.action === 'return' ? 'Return Items' : 'Cancel Items'}</h2>
                <div className="ordermodal-item-list">
                    {!modalAction.isAllItems ? items.map((item, index) => (
                        <div key={item._id} className="ordermodal-item d-flex justify-content-between" style={{
                            borderTop: index === 0 ? '1px solid #e0e0e0' : 'none'
                        }}>
                            <div className='d-flex gap-3'>
                                <img src={`${Asset_url}${item.image}`} alt={item.name} className="ordermodal-item-image" />
                                <div className=' d-flex flex-column gap-2 align-items-start'>
                                    <div className="ordermodal-item-name">{item.name}</div>
                                    <select
                                        value={selectedReasons[index].reason}
                                        onChange={(e) => handleReasonChange(index, e.target.value)}
                                        className="ordermodal-reason-dropdown"
                                    >
                                        <option value="">Select Reason</option>
                                        <option value="Product was defective">Product was defective</option>
                                        <option value="Changed my mind">Changed my mind</option>
                                        <option value="Better price available">Better price available</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {selectedReasons[index].reason === 'Other' && (
                                        <input
                                            type="text"
                                            value={selectedReasons[index].otherReason}
                                            onChange={(e) => handleOtherReasonChange(index, e.target.value)}
                                            placeholder="Enter other reason"
                                            className="ordermodal-other-reason-input"
                                        />
                                    )}
                                </div>
                            </div>
                            <button onClick={() => handleRemoveItem(index)} className='cancel-order-button'> Remove </button>
                        </div>
                    )) :
                        <div className="ordermodal-item d-flex justify-content-between" style={{
                            borderTop:'1px solid #e0e0e0'
                        }}>
                            <div className='d-flex gap-3'>
                                <img src={`${Asset_url}${items[0].image}`} alt={items[0].name} className="ordermodal-item-image" />
                                <div className=' d-flex flex-column gap-2 align-items-start' style={{minWidth: '20rem'}}>
                                    {items.length > 1 ? (
                                        <p className="ordermodal-item-name w-100 mb-0">
                                            {items[0]?.name} <span className='linear-gradient-text'> and {items.length - 1} more</span>
                                        </p>
                                    ) : (
                                        <p className="ordermodal-item-name mb-0">{items[0]?.name}</p>
                                    )}
                                    <select
                                        value={completeOrderReason}
                                        onChange={(e) => setCompleteOrderReason(e.target.value)}
                                        className="ordermodal-reason-dropdown"
                                    >
                                        <option value="">Select Reason</option>
                                        <option value="Product was defective">Product was defective</option>
                                        <option value="Changed my mind">Changed my mind</option>
                                        <option value="Better price available">Better price available</option>
                                    </select>
                                    Reason:
                                    <input
                                        type="text"
                                        value={completeOrderReason}
                                        onChange={(e) => setCompleteOrderReason(e.target.value)}
                                        placeholder="Enter other reason"
                                        className="ordermodal-other-reason-input"
                                    />
                                </div>
                            </div>
                        </div>}
                </div>
                <div className=' d-flex justify-content-center mb-4'>
                    <button onClick={modalAction.action === 'return' ? (modalAction.isAllItems ? handleReturnCompleteOrder : handleReturnSelectedItems) : (modalAction.isAllItems ? handleCancelCompleteOrder : handleCancelSelectedItems)} className="ordermodal-button ordermodal-continue-button">
                        Continue
                    </button>
                    <button onClick={onClose} className="ordermodal-button ordermodal-cancel-button">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CancelReturnModal;
