import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { REACT_BASE_PATH } from "../../../api";

const BrandDropdown = ({ onBrandChange, editBrandDetail, brandAdd }) => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(editBrandDetail || null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${REACT_BASE_PATH}/product/brands`);
        if (response.data.status === 200) {
          const brandOptions = response.data.data.map((brand) => ({
            value: brand._id,
            label: brand.brand_name,
          }));
          setBrands(brandOptions);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, [brandAdd]);

  const handleChange = (selectedOption) => {
    setSelectedBrand(selectedOption);
    onBrandChange(selectedOption);
  };

  return (
    <div>
      <Select
        options={brands}
        value={selectedBrand}
        onChange={handleChange}
        placeholder="Search and select a brand"
      />
    </div>
  );
};

export default BrandDropdown;
