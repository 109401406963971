import React, { useState, useCallback, useEffect } from 'react';
import Pagination from "../Pagination";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { REACT_BASE_PATH } from '../../../../api';
import { formatDateTime } from "../../helper/DateTimeFormat";
import axios from 'axios';

const VendorOrders = ({ id }) => {
    const [selectedOption, setSelectedOption] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownList, setdropdownList] = useState(['confirmed', 'dispatched', 'out for delivery', 'delivered', 'canceled'])
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const [NoData, setNoData] = useState('')
    const [loading, setLoading] = useState(false);

    const fetchOrderList = useCallback(async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/listOrders`, {
                "vendorId": id,
                "page": currentPage,
                "pagesize": selectedOption
            });
            if (response.data.success) {
                setData(response.data.data);
                setNoData('')
                setLoading(false)
                setTotalItems(response.data.totalItems)
            } else {
                setLoading(false)
                setData([])
                setNoData(response.data.message)
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }, [currentPage, selectedOption]);

    useEffect(() => {
        fetchOrderList()
    }, [fetchOrderList])

    const totalPages = Math.ceil(totalItems / selectedOption);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const navigateToOrderDetail = (order) => {
        navigate('../OrderDetails/', { state: order })
    }

    const _handleStatus = async (id, status) => {
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/updateOrderStatus`, {
                "orderId": id,
                "status": status
            });
            if (response.data.status === 200) {
                fetchOrderList()
            } else {
                console.log('error');

            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }

    }

    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Order List</h5>
                </div>
            </div>
            <div className='tableGap'>
                <table>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>User Name</th>
                            <th>Order Date</th>
                            <th>Payment Status</th>
                            <th>Total Price</th>
                            <th>Order Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {items?._id}
                                    </td>
                                    <td>{items?.userId?.first_name} {items?.userId?.last_name}</td>
                                    <td>{formatDateTime(items.orderDate)}</td>
                                    <td>{items.paymentStatus}</td>
                                    <td>
                                        {items.totalPrice}
                                    </td>
                                    <td style={{ textTransform: 'capitalize' }}>
                                        {items.status}
                                    </td>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        <button className="edit-btn" onClick={() => navigateToOrderDetail(items._id)}>
                                            <i className="fa fa-eye"></i>
                                        </button>
                                        {items.status == 'delivered' ?
                                            <button className="trash-icon disabledBtn">
                                                <MoreVertIcon />
                                            </button> :
                                            <div class="btn-group dropend">
                                                <button className="trash-icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <MoreVertIcon />
                                                </button>
                                                <ul class="dropdown-menu">
                                                    {dropdownList.map((option) => (
                                                        <li className="text-capitalize" key={option} onClick={() => _handleStatus(items._id, option)}>
                                                            <a className="dropdown-link" href='javascript:void(0)'>{option}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>}
                                    </td>
                                </tr>
                            )
                        })}
                        
                    </tbody>
                </table>
                {NoData && <p>{NoData}</p>}
            </div>
            <div className='row'>
                <div className="col-sm-12 col-md-6">
                    {data.length > 0 && <div className="dataTables_paginate">
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>}
                </div>
            </div>
        </>
    )
}

export default VendorOrders;