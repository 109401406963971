import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Switch } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { formatDate } from "../../helper/DateTimeFormat";
import Pagination from "../Pagination";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllUsers = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([])
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / selectedOption);
  const [dropIndex, setdropndex] = useState(0);
  const [dropShow, setdropShow] = useState(false)
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [dropdownListVendor, setdropdownListVendor] = useState(['Profile', 'Accounts', 'Orders'])
  const [dropdownList, setdropdownList] = useState(['Approve', 'Not Approve'])



  const fetchOrderList = useCallback(async () => {
    setLoading(true)
    const vendor = JSON.parse(localStorage.getItem('vendor'))
    const { vendorid, role } = vendor;
    try {
      let path = role === 'admin' ? '/auth/user/getUsers' : '/vendor/vendors/UsersOfVendor'
      const response = await axios.post(`${REACT_BASE_PATH}` + path, {
        "vendorId": role === 'admin' ? '0' : vendorid,
        "page": currentPage,
        "limit": selectedOption
      });
      if (response.data.success) {
        setdata(response.data.data);
        setTotalItems(response.data.totalUsers);
        setLoading(false)
      } else {
        setLoading(false)
        setdata([])
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching category:", error);
    }
  }, [currentPage, selectedOption]);

  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOption(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchOrderList();
  }, [fetchOrderList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const navigateVendorDetails = (data, id) => {
    navigate('../userDetails', { state: { tab: data, id: id } })
  }

  const _handleStatus = async (id, status) => {
    try {
      const response = await axios.post(`${REACT_BASE_PATH}/product/updateOrderStatus`, {
        "orderId": id,
        "status": status
      });
      if (response.data.status === 200) {
        setdropShow(!dropShow)
        fetchOrderList()
      } else {
        console.log('error');

      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }

  }

  const handleModalOpen = (option) => {
    if (option !== 'Approve') {
      setOpenModal(true)
    }
  }

  const handleModal = () => {
    setOpenModal(false)
  }


  return (
    <>
      <section id="admin-main-content" >
        <div className="container-fuild">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">All Users</h5>
            </div>
          </div>
          <div className="row">
            <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
              <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search Vendors"
                      aria-controls="DataTables_Table_0"
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
                <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                  <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                    <label>
                      <select
                        name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        className="form-select"
                        id="entries"
                        value={selectedOption}
                        onChange={handleChange}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="70">70</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                  <div className="mb-6">
                    <button className="btn btn-theam mx-2" type="button">
                      <span>Export CSV</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="mlr-10 mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Order Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    {data?.length > 0 && data?.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{items?._id}</td>
                          <td>{items?.first_name || ''} {items?.last_name || ''}</td>
                          <td>{items?.email}</td>
                          <td>{formatDate(items?.createdAt)}</td>
                          <td><Switch {...label} /></td>
                          <td>
                            <div class="btn-group dropleft">
                              <button className="edit-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-eye"></i>
                              </button>
                              <ul class="dropdown-menu">
                                {dropdownListVendor.map((option, index) => (
                                  <li className="text-capitalize" key={option} onClick={() => navigateVendorDetails(index + 1, items?._id)}>
                                    <a className="dropdown-link" href='javascript:void(0)'>{option}</a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div class="btn-group dropleft">
                              <button className="trash-icon" data-bs-toggle="dropdown" aria-expanded="false">
                                <MoreVertIcon />
                              </button>
                              <ul class="dropdown-menu">
                                {dropdownList.map((option, index) => (
                                  <li className="text-capitalize" key={option} onClick={() => handleModalOpen(option)} >
                                    <a className="dropdown-link" href='javascript:void(0)'>{option}</a>
                                  </li>
                                ))}
                              </ul>
                            </div></td>
                        </tr>
                      )

                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="row mb-4">
            <div className="col-sm-12 col-md-6 mt-2">
              <div className="dataTables_info">
                Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="dataTables_paginate">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={'xs'}
          onClose={handleModal}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Vendor Approvel Cancel"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please enter a reason to cancel this approvel...
            </DialogContentText>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Reason for cancel </label>
              <textarea rows={3} type="text" class="form-control" id="categoryNameId" placeholder="Enter reason for cancel" />
            </div>
          </DialogContent>
          <DialogActions>
            <button class="trash-icon px-3 py-2" onClick={handleModal}>Cancel</button>
            <button class="edit-btn px-3 py-2" onClick={handleModal}>Continue</button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  )
}

export default AllUsers;