import React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
  } from "@mui/material";
function ImageSeeModal({open,onClose,editUser}) {
  return (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Uploaded Image</DialogTitle>
        <DialogContent>
            <Box style={{maxWidth : '50%', maxHeight : '50%'}} className="m-auto">
             <img src={editUser} alt="upaloded image" width="100%"/>
           </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default ImageSeeModal