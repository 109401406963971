import React, { useState, useEffect } from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const [visiblePages, setVisiblePages] = useState([]);
    const getVisiblePages = (current) => {
        let start = Math.max(current - 2, 1);
        let end = Math.min(current + 2, totalPages);
        let pages = [];

        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        return pages;
    };

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
            setVisiblePages(getVisiblePages(page));
        }
    };

    useEffect(() => {
        setVisiblePages(getVisiblePages(currentPage));
    }, [currentPage, totalPages]);

    return (
        <ul className="pagination">
            <li
                className="btn btn-theam"
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <i className="fa fa-chevron-left bx-18px"></i>
            </li>
            {currentPage > 3 && <li className="paginate_button page-item" onClick={() => goToPage(1)}><span
                className="page-link"
            >1</span></li>}
            {currentPage > 3 && <span>...</span>}
            {visiblePages.map((page, index) => (
                <li
                    key={index}
                    className={`paginate_button page-item ${currentPage === page ? "active" : ""}`}
                >
                    <span
                        className="page-link"
                        onClick={() => goToPage(page)}
                    >
                        {page}
                    </span>
                </li>
            ))}
            {currentPage < totalPages - 2 && <span>...</span>}
            {currentPage < totalPages - 2 && (
                <li className="paginate_button page-item" onClick={() => goToPage(totalPages)}><span
                    className="page-link"
                >{totalPages}</span></li>
            )}

            <li
                className="btn btn-theam"
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <i className="fa fa-chevron-right bx-18px"></i>
            </li>
        </ul>
    );
}

export default Pagination;