import React, { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import profileIcon from '../../img/woman.png'
import { useLocation } from 'react-router-dom';
import { REACT_BASE_PATH } from '../../../../api';
import axios from 'axios';
import AffiliateProfile from './AffiliateProfile'
import AffiliateProducts from './AffiliateProducts'
import AffiliateAccount from './AffiliateAccount'
import AffilateKYC from './AffilateKYC'
import { _getProfile, _update_kyc } from '../../../../url';
import { getAdminId, getAffialteToken, getAffilateUserId } from '../../../../utills';
import { Slide } from '@mui/material';
import AffilateEarning from './AffilateEarning';

import toast, { Toaster } from 'react-hot-toast';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AffiliateDetails() {
    const location = useLocation();
    const { activeTab, id } = location.state || {};
    const [value, setValue] = useState(activeTab?.toString() || "1");
    const [openModal, setOpenModal] = useState(false);
    const [Vendordata, setVendordata] = useState([])
    const [Updatetype, setUpdatetype] = useState('');
    const [ladger, setLadger] = useState([])
    const [remark, setremark] = useState('')
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getVendorDetails();
    }, [])

    const handleModal = (type) => {
        setUpdatetype(type)
        setOpenModal(!openModal)
    }

    const getVendorDetails = async () => {
        try {
            let payload = {
                "id": id
            }
            const response = await axios.post(_getProfile, payload,{
                headers: {
                  'Authorization': `Bearer ${getAffialteToken()}`
                }
            });
            // console.log("response.data?.ladger",response.data)
            if (response.data.data) {
                setVendordata(response.data?.data);
                setLadger(response.data?.ledger);
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }

    

    const _handleKYCUpdate = async (updateType, type) => {
        setOpenModal(false)
        try {
            const status = type === 'approved' ? true : false;
            let payload = {
                "id": getAdminId(),
                userId : id,
                kyc_status : type,
                "is_kyc_approved": status,  // or "rejected", "approved" or "pending"
                "remark": remark  // Required if status is "rejected"
            }
            const vendorToken = JSON.parse(localStorage.getItem('vendor'))
            const response = await axios.post(_update_kyc, payload, {
                headers: {
                    "Authorization": "Bearer " + vendorToken?.accessToken
                },
            });
            if (response.data.success) {
                toast.success("User kyc status updated");
                getVendorDetails()
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }

    return (
        <>
            <section className="custom-cart-panel mb-3">
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">Affiliate Details</h5>
                        </div>
                    </div>
                </div>
                <div className="card-header border-top px-0 pt-4 py-0">
                    <div className="vendorProfile">
                        <div className=''>
                            <img src={profileIcon} alt='' />
                        </div>
                        <div className='flex-grow-1'>
                            <h5 className='mb-3'>{Vendordata?.name} {Vendordata?.last_name} <span className='badge'>Active</span> </h5>
                            <h5><span className='spanSpace'>ID:-</span>{Vendordata?._id}</h5>
                            <h6><span className='spanSpace'>Email ID:- </span>{Vendordata?.email}</h6>
                            <h6><span className='spanSpace'>Mobile No:-</span>{Vendordata?.phone_number}</h6>
                            <h6><span className='spanSpace'>Role:-</span>Affiliate</h6>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Profile" value="1" />
                            <Tab label="Accounts" value="2" />
                            <Tab label="Products" value="3" />
                            <Tab label="Earning" value="4" />
                            <Tab label="KYC" value="5" />
                            {Vendordata?.role == 'seller' && <Tab label="KYB" value="6" />}
                        </Tabs>
                    </Box>
                </TabContext>
            </div>
            <TabContext value={value}>
                <TabPanel value="1">
                  <AffiliateProfile Vendordata={Vendordata}/>
                </TabPanel>
                <TabPanel value="2">
                  <AffiliateAccount />
                </TabPanel>
                <TabPanel value="3">
                  <AffiliateProducts Vendordata={Vendordata}/>
                </TabPanel>
                <TabPanel value="4">
                  <AffilateEarning Vendordata={Vendordata} ladger={ladger}/>
                </TabPanel>
                <TabPanel value="5">
                  <AffilateKYC Vendordata={Vendordata} _handleKYCUpdate={_handleKYCUpdate} handleModal={handleModal}/>
                </TabPanel>
            </TabContext>
            <Dialog
                open={openModal}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xs'}
                onClose={handleModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Vendor Approvel Cancel"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Please enter a reason to cancel this approvel
                    </DialogContentText>
                    <div class="mb-3">
                        {/* <label for="exampleFormControlInput1" class="form-label">Reason for cancel </label> */}
                        <textarea rows={3} value={remark} onChange={e => setremark(e.target.value)} type="text" class="form-control" id="categoryNameId" placeholder="Enter reason for cancel" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button class="trash-icon px-3 py-2" onClick={handleModal}>Cancel</button>
                    <button class="edit-btn px-3 py-2" onClick={() => _handleKYCUpdate(Updatetype, 'rejected')}>Continue</button>
                </DialogActions>
            </Dialog>

            <Toaster />
            </>
        )
}

export default AffiliateDetails