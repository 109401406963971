import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the necessary components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChartComponent = () => {
    // Define the data for the chart
    const data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // x-axis labels
        datasets: [
            {
                label: 'Revenue',
                data: [10, 15, 7, 20, 25, 18, 30], // y-axis values
                fill: false,
                backgroundColor: 'red',
                borderColor: 'orange',
                tension: 0.4, // smooth line
            },
            {
                label: 'Revenue',
                data: [12, 17, 9, 22, 27, 20, 32], // y-axis values
                fill: false,
                backgroundColor: 'blue',
                borderColor: 'skyblue',
                tension: 0.4, // smooth line
            },
        ],
    };

    // Define chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: 'Numbers by Day of the Week',
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Week Days',
                },
                grid: {
                    display: false, // Disable the x-axis grid
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Numbers',
                },
                beginAtZero: true,
                // grid: {
                //     display: false, // Disable the x-axis grid
                // },
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default LineChartComponent;
