import React, { useEffect, useState } from "react";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import { formatDateTime } from "../../helper/DateTimeFormat";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination";

const ConfirmedOrders = () => {
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([])
    const [totalItems, setTotalItems] = useState(0);
    const totalPages = Math.ceil(totalItems / selectedOption);

    useEffect(() => {
        fetchOrderList()
    }, [])

    const fetchOrderList = async () => {
        const vendor = JSON.parse(localStorage.getItem('vendor'))
        const { vendorid, role } = vendor;
        setLoading(true)
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/listOrders`, {
               "vendorId": role === 'admin' ? "0" : vendorid,
               "page": currentPage,
                "pagesize": selectedOption
            });
            if (response.data.status === 200) {
                let filterdata = response.data.data.filter((orders) => orders.status == 'delivered')
                setdata(filterdata);
                setTotalItems(response.data.totalItems)
                setLoading(false)
            } else {
                setdata([])
                setLoading(false)
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    };

    const handleChange = (event) => {
        const value = parseInt(event.target.value);
        setSelectedOption(value);
        setCurrentPage(1);
    };
    const navigateToOrderDetail = () => {
        navigate('../OrderDetails/', { state: 1 })
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <section id="admin-main-content" >
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">Complete Orders</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
                            <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <label>
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search Orders"
                                            aria-controls="DataTables_Table_0"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
                                <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                                    <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                                        <label>
                                            <select
                                                name="DataTables_Table_0_length"
                                                aria-controls="DataTables_Table_0"
                                                className="form-select"
                                                id="entries"
                                                value={selectedOption}
                                                onChange={handleChange}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="70">70</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="mb-6">
                                        <button className="btn btn-theam mx-2" type="button">
                                            <span>Export CSV</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="mlr-10 mb-4">
                                <table className="tableGap">
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>User Name</th>
                                            <th>Order Date</th>
                                            <th>Payment Status</th>
                                            <th>Total Price</th>
                                            <th>Order Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 && data.map((items, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {items?._id}
                                                    </td>
                                                    <td>{items?.userId?.first_name} {items?.userId?.last_name}</td>
                                                    <td>{formatDateTime(items.orderDate)}</td>
                                                    <td>{items.paymentStatus}</td>
                                                    <td>
                                                        {items.totalPrice}
                                                    </td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {items.status}
                                                    </td>
                                                    <td>
                                                        <button className="edit-btn" onClick={navigateToOrderDetail}>
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-6 mt-2">
                            <div className="dataTables_info">
                                Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="dataTables_paginate">
                            <Pagination
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ConfirmedOrders;