import React from "react";
import { NavLink } from "react-router-dom";

function Sidebar({ collapsed, userInfo }) {
  const sideLinks = [
    {
      path : "/airdrop/dashboard",
      title : 'Dashboard',
      icon : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-layout-dashboard"><rect width="7" height="9" x="3" y="3" rx="1"/><rect width="7" height="5" x="14" y="3" rx="1"/><rect width="7" height="9" x="14" y="12" rx="1"/><rect width="7" height="5" x="3" y="16" rx="1"/></svg>
    },
    {
      path: "/airdrop/all_task",
      title: "All Projects",
      icon: <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-calendar-check-2"
    >
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <path d="M21 14V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8" />
      <path d="M3 10h18" />
      <path d="m16 20 2 2 4-4" />
    </svg>,
    },
    {
      path: "/airdrop/all_history",
      title: "History",
      icon: <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-history"
    >
      <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
      <path d="M3 3v5h5" />
      <path d="M12 7v5l4 2" />
    </svg>,
    },
    {
      path: "/affiliate/products",
      title: "Affiliate",
      icon: <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-network"
    >
      <rect x="16" y="16" width="6" height="6" rx="1" />
      <rect x="2" y="16" width="6" height="6" rx="1" />
      <rect x="9" y="2" width="6" height="6" rx="1" />
      <path d="M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3" />
      <path d="M12 12V8" />
    </svg>,
    },
    {
      path: "#",
      title: `Wallet - ${userInfo?.walletAmount}`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-wallet"
        >
          <path d="M21 7H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2Z" />
          <path d="M7 7V5c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v2" />
          <path d="M16 12h2" />
        </svg>
      ),
    },    
  ];
  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      {!collapsed && (
        <>  
        {
          sideLinks?.map(({ path, title, icon }) => (
            <div className="nav-section d-flex justify-content-start align-items-center py-1">
            <NavLink
              to={path}
              className={({ isActive, isPending }) => isActive ? "activeClass sidebarList" : "sidebarList"}
              style={{ color: "#fff", textDecoration: "none" }}
              
            >
              {icon}{" "}
              <span className="slidebarspan" style={{ fontSize: "1.2rem" }}>
                {title}
              </span>
            </NavLink>
            </div>  
          ))
        }
        </>
      )}
    </div>
  );
}

export default Sidebar;
