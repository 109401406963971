import React, { useCallback, useEffect, useState } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import LineChartComponent from './LineChart';
import iconx from '../../../img/Iconsax.png';
import arrowdown from '../../../img/Arrow-down.png'
import arrowup from '../../../img/Arrow-up.png';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Asset_url, REACT_BASE_PATH } from "../../../api";
import axios from "axios";

const Dashboard = () => {
  const [layout, setLayout] = React.useState('vertical');
  const [radius, setRadius] = React.useState(10);
  const [itemNb, setItemNb] = React.useState(5);
  const [time, setTime] = React.useState('today');
  const [periodData, setperiodData] = React.useState('today');
  const [ActiveVendors, setActiveVendors] = useState(0)
  const [ActiveUsers, setActiveUsers] = useState(0);
  const [ActiveOrderCount, setActiveOrderCount] = useState(0);
  const [customerList, setcustomerList] = useState([])
  const [productList, setproductList] = useState([])

  const getActiveUserVendor = useCallback(async () => {
    try {
      let payload = {
        "period": periodData
      }
      const response = await axios.post(`${REACT_BASE_PATH}/auth/dashboard/userandvendorscount`, payload);
      if (response.data.success) {
        setActiveUsers(response.data?.data?.totalActiveUsers)
        setActiveVendors(response.data?.data?.totalActiveVendors)
      }

      const responseOrder = await axios.post(`${REACT_BASE_PATH}/auth/dashboard/orderCounts`, payload);
      if (responseOrder.data.success) {
        setActiveOrderCount(responseOrder.data?.data?.orderCount)
      }

      const responseCustomer = await axios.post(`${REACT_BASE_PATH}/product/topCustomers`, payload);
      if (responseCustomer.data.success) {
        setcustomerList(responseCustomer.data?.data)
      }

      const responseProduct = await axios.post(`${REACT_BASE_PATH}/product/topProducts`, payload);
      if (responseProduct.data.success) {
        setproductList(responseProduct.data?.data)
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }, [periodData]);



  useEffect(() => {
    getActiveUserVendor();
  }, [getActiveUserVendor]);

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const handleChangePeriod = (event) => {
    setperiodData(event.target.value)
  }
  const dataset = [
    [10, -13, 'Jan'],
    [17, -19, 'Feb'],
    [10, -20, 'Mar'],
    [16, -11, 'Apr'],
    [12, -7, 'May'],
    [7, -10, 'Jun'],
    [16, 20, 'Jul'],
    [-19, -12, 'Aug'],
    [10, -13, 'Spe'],
    [17, -19, 'Oct'],
    [10, -20, 'Nov'],
    [16, -11, 'Dec'],
  ].map(([high, low, order]) => ({
    high,
    low,
    order,
  }));
  const chartSettingsH = {
    dataset,
    height: 420,
    yAxis: [{ scaleType: 'band', dataKey: 'order' }],
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
    slotProps: {
      legend: {
        direction: 'row',
        position: { vertical: 'bottom', horizontal: 'middle' },
        padding: -5,
      },
    },
  };
  const chartSettingsV = {
    ...chartSettingsH,
    xAxis: [{ scaleType: 'band', dataKey: 'order' }],
    yAxis: undefined,
  };

  const desktopOS = [
    {
      label: 'Feshion',
      value: 72.72,
    },
    {
      label: 'Decor',
      value: 16.38,
    },
    {
      label: 'Electronics',
      value: 3.83,
    },
    {
      label: 'Sports',
      value: 2.42,
    },
  ];

  const mobileOS = [
    {
      label: 'Feshion',
      value: 72.72,
    },
    {
      label: 'Decor',
      value: 16.38,
    },
    {
      label: 'Electronics',
      value: 3.83,
    },
    {
      label: 'Sports',
      value: 2.42,
    },
  ];

  const platforms = [
    {
      label: 'Feshion',
      value: 72.72,
    },
    {
      label: 'Decor',
      value: 16.38,
    },
    {
      label: 'Electronics',
      value: 3.83,
    },
    {
      label: 'Sports',
      value: 2.42,
    },
  ];

  const normalize = (v, v2) => Number.parseFloat(((v * v2) / 100).toFixed(2));

  const mobileAndDesktopOS = [
    ...mobileOS.map((v) => ({
      ...v,
      label: v.label === 'Other' ? 'Other (Mobile)' : v.label,
      value: normalize(v.value, platforms[0].value),
    })),
    ...desktopOS.map((v) => ({
      ...v,
      label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
      value: normalize(v.value, platforms[1].value),
    })),
  ];
  const valueFormatter = (item) => `${item.value}%`;

  return (
    <>
      <section id="admin-main-content" className="mb-3" >
        <div className="container-fuild">
          <div className="row">
            <div className="col-md-10">
              <div className="vendor-top px-0">
                <h5 className="title-v">Dashboard</h5>
              </div>
            </div>
            <div className="col-md-2">
              <Select
                className="w-100"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={periodData}
                onChange={handleChangePeriod}
              // label="Today"
              >
                <MenuItem value={'today'}>Today</MenuItem>
                <MenuItem value={'lastWeek'}>This Week</MenuItem>
                <MenuItem value={'lastMonth'}>This Month</MenuItem>
                <MenuItem value={'lastYear'}>This Year</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-md-25">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="">
              <div className="row">
                <div className="p-0 col-9">
                  <h5 className="title-v mb-0">Revenue</h5>
                </div>
                <div className="p-0 col-3">
                  <img src={iconx} alt='' />
                </div>
              </div>
              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="p-0 col-5">
                    <h3 className="mb-0">$25K</h3>
                  </div>
                  <div className="p-0 col-7 text-end">
                    <span style={{ color: '#219653' }}>+11.55% </span>
                    <span><img src={arrowup} alt='' /></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-25">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="">
              <div className="row">
                <div className="p-0 col-9">
                  <h5 className="title-v mb-0">Order</h5>
                </div>
                <div className="p-0 col-3">
                  <img src={iconx} alt='' />
                </div>
              </div>
              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="p-0 col-5">
                    <h3 className="mb-0">{ActiveOrderCount}</h3>
                  </div>
                  <div className="p-0 col-7 text-end">
                    <span style={{ color: '#FF4747' }}>+11.55% </span>
                    <span><img src={arrowdown} alt='' /></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-25">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="">
              <div className="row">
                <div className="p-0 col-9">
                  <h5 className="title-v mb-0">Visitor</h5>
                </div>
                <div className="p-0 col-3">
                  <img src={iconx} alt='' />
                </div>
              </div>
              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="p-0 col-5">
                    <h3 className="mb-0">525K</h3>
                  </div>
                  <div className="p-0 col-7 text-end">
                    <span style={{ color: '#219653' }}>-11.55% </span>
                    <span><img src={arrowup} alt='' /></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-25">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="">
              <div className="row">
                <div className="p-0 col-9">
                  <h5 className="title-v mb-0">Active Users</h5>
                </div>
                <div className="p-0 col-3">
                  <img src={iconx} alt='' />
                </div>
              </div>
              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="p-0 col-5">
                    <h3 className="mb-0">{ActiveUsers}</h3>
                  </div>
                  <div className="p-0 col-7 text-end">
                    <span style={{ color: '#219653' }}>+11.55% </span>
                    <span><img src={arrowup} alt='' /></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-25">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="">
              <div className="row">
                <div className="p-0 col-9">
                  <h5 className="title-v mb-0">Total Seller</h5>
                </div>
                <div className="p-0 col-3">
                  <img src={iconx} alt='' />
                </div>
              </div>
              <div className="mt-3">
                <div className="row align-items-center">
                  <div className="p-0 col-5">
                    <h3 className="mb-0">{ActiveVendors}</h3>
                  </div>
                  <div className="p-0 col-7 text-end">
                    <span style={{ color: '#FF4747' }}>-5.22% </span>
                    <span><img src={arrowdown} alt='' /></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-8">
            <section className="custom-cart-panel mb-3">
              <div className="container-fuild">
                <div className="row">
                  <div className="vendor-top col-9">
                    <h5 className="title-v">Total Revenue</h5>
                  </div>
                  <div className="col-md-3">
                    <Select
                      className="w-100"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={time}
                      onChange={handleChange}
                    // label="Today"
                    >
                      <MenuItem value={'today'}>Today</MenuItem>
                      <MenuItem value={'week'}>This Week</MenuItem>
                      <MenuItem value={'month'}>This Month</MenuItem>
                      <MenuItem value={'year'}>This Year</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <LineChartComponent />
              {/* <BarChart
                series={[
                  { dataKey: 'high', label: '2024', layout, stack: 'stack', color: '#3b1a61' },
                  { dataKey: 'low', label: '2023', layout, stack: 'stack', color: '#822457' },
                ]}
                {...(layout === 'vertical' ? chartSettingsV : chartSettingsH)}
                borderRadius={radius}
              /> */}

            </section>
          </div>
          <div className="col-md-4">
            <section className="custom-cart-panel mb-3">
              <PieChart
                height={200}
                slotProps={{
                  legend: { labelStyle: { fontSize: 11 }, itemMarkHeight: 10, itemMarkWidth: 10 },
                }}
                series={[
                  {
                    data: mobileAndDesktopOS.slice(0, itemNb),
                    innerRadius: 65,
                    arcLabel: () => '',
                    arcLabelMinAngle: 20,
                    valueFormatter,
                  },
                ]}
              />
            </section>
            <section className="custom-cart-panel mb-3">
              <PieChart
                height={200}
                slotProps={{
                  legend: { labelStyle: { fontSize: 11 }, itemMarkHeight: 10, itemMarkWidth: 10 },
                }}
                series={[
                  {
                    data: mobileAndDesktopOS.slice(0, itemNb),
                    innerRadius: 65,
                    arcLabel: () => '',
                    arcLabelMinAngle: 20,
                    valueFormatter,
                  },
                ]}
              />
            </section>
          </div>
          {/* <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top px-0 col-12">
                <h5 className="title-v">All Product</h5>
              </div>
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                  {
                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                    area: true,
                    color: '#822457', // Line color
                    areaColor: '#3b1a61',
                  },
                ]}
                width={500}
                height={300}
              />
            </div>

          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top px-0 col-12">
                <h5 className="title-v">All Orders</h5>
              </div>
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                  {
                    data: [3, 7.5, 4, 9.5, 2.5, 9],
                    area: true,
                    color: '#822457', // Line color
                    areaColor: '#3b1a61',
                  },
                ]}
                width={500}
                height={300}
              />
            </div>

          </div> */}
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top px-0 col-12">
                <h5 className="title-v">Top Customers</h5>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Total Order</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerList && customerList.length > 0 &&
                      customerList.map((customerdata, index) => (
                        <tr key={'keyCustomer_' + index}>
                          <td>{index + 1}.</td>
                          <td><img style={{ width: 30, height: 30, objectFit: 'cover', borderRadius: 50 }} src={customerdata?.profile_image?.startsWith('http')
                            ? customerdata?.profile_image
                            : `${Asset_url}${customerdata?.profile_image}`} alt={''} /></td>
                          <td>
                            {customerdata?.name}
                          </td>
                          <td>{customerdata?.email}</td>
                          <td>{customerdata?.totalOrders}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top px-0 col-12">
                <h5 className="title-v">Top Product</h5>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Total Order</th>
                      <th>Total Sale</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList && productList.length > 0 &&
                      productList.map((productdata, index) => (
                        <tr key={'keyProduct_' + index}>
                          <td>{index + 1}.</td>
                          <td><img style={{ width: 30, height: 30, objectFit: 'cover' }} src={productdata?.banner_image?.startsWith('http')
                            ? productdata?.banner_image
                            : `${Asset_url}${productdata?.banner_image}`} alt={''} /></td>
                          <td>
                            <p style={{
                              width: 200,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'inline-block',
                              marginBottom: 0
                            }}>{productdata?.productName}</p>
                          </td>
                          <td>{productdata?.totalOrders}</td>
                          <td>{(productdata?.totalRevenue).toFixed(0)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  );
};

export default Dashboard;
